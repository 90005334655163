import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";

export default class Header extends Component {
  render() {
    const href = window.location.href;
    let checkHash = false;
    if (href.substring(href.lastIndexOf("/") + 1).split("#")[1]) {
      checkHash = "#" + href.substring(href.lastIndexOf("/") + 1).split("#")[1];
    }
    return (
      <>
        <header id="header" className="fixed-top ">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div
                className="col-xl-9 col-sm-12 d-flex align-items-center justify-content-between"
                style={{ minWidth: "92%" }}
              >
                <nav className="nav-menu d-lg-block anrsb">
                  <div
                    className="showInMobile"
                    style={{ padding: "0px 0px 20px 0px", borderBottom: "0px" }}
                  >
                    <img
                      src="assets/img/Logo.png"
                      alt=""
                      className="header-logo img-fluid"
                      style={{ borderBottom: "0px" }}
                    />
                    <button
                      type="button"
                      className="mobile-nav-toggle2 d-lg-none anrsb-nav-act"
                      style={{ top: "25px" }}
                    >
                      <i
                        className="icofont-close"
                        style={{ color: "#40a9f2" }}
                      ></i>
                    </button>
                  </div>
                  <ul>
                    <li className="" style={{ marginLeft: "0px" }}>
                      <NavLink
                        className="mob-l"
                        exact
                        activeclassName={checkHash ? "" : "active"}
                        to={"/"}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink
                        className="mob-l"
                        exact
                        activeclassName={checkHash ? "" : "active"}
                        to={"integration"}
                      >
                        Integration
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink
                        className="mob-l"
                        exact
                        activeclassName={checkHash ? "" : "servicenow"}
                        to={"servicenow"}
                      >
                        SERVICENOW
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink
                        className="mob-l"
                        exact
                        activeclassName={checkHash ? "" : "salesforce"}
                        to={"salesforce"}
                      >
                        SALESFORCE
                      </NavLink>
                    </li>
                    <li className="">
                      {/* <NavLink className="mob-l" exact activeclassName={(checkHash)?"":"active"} to={"retail"}>Retail</NavLink> */}
                      <a
                        href="https://cyntra.ai"
                        className={checkHash === "#contact" ? "active" : ""}
                      >
                        Retail
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown"
                      >
                        <button className="dropbtn">INDUSTRIES</button>
                        <div className="dropdown-content">
                          <a href="/food" style={{color:"black"}}>Food & Beverage</a>
                          <a href="/fashion" style={{color:"black"}}>Fashion & Merchandise</a>
                          {/* <a href="/entertainment">Entertainment</a>
                          <a href="/hospitality">Hospitality</a>
                          <a href="/healthcare">Healthcare</a>
                          <a href="/fitness">Fitness</a> */}
                          <a href="/banking" style={{color:"black"}}>Banking & Finance</a>
                        </div>
                      </a>
                    </li>

                    {/* <li className="">
                                            <NavLink className="mob-l" exact activeclassName={(checkHash)?"":"sap-ariba"} to={"sap-ariba"}>SAP ARIBA</NavLink>
                                        </li> */}

                    {/* <li className="navclose_mob2 ">
                                            <NavLink className="mob-l" exact activeclassName={(checkHash)?"":"active"} to={"demo"}>Demo</NavLink>
                                        </li>
                                        <li className="">
                                            <NavLink className="mob-l" exact activeclassName={(checkHash)?"":"active"} to={"kiosks"}>KIOSKS</NavLink>
                                        </li>
                                        <li className="navclose_mob2">                                          
                                            <a  href="#Partners">Partners</a>
                                        </li>
                                        <li className="">
                                            <NavLink className="mob-l" exact activeclassName={(checkHash)?"":"active"} to={"about"}>About Us</NavLink>
                                        </li>
                                        <li className="navclose_mob2">
                                            <NavLink className="mob-l" exact activeclassName={(checkHash)?"":"active"} to={"blogs"}>Blog</NavLink>
                                        </li> */}
                    <li className="navclose_mob2">
                      <a
                        href="https://cyntra.ai/contactus"
                        className={checkHash === "#contact" ? "active" : ""}
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </nav>
                <NavLink
                  exact
                  className="aclahl"
                  activeclassName={checkHash ? "" : "active"}
                  to={"/"}
                >
                  <img
                    src="assets/img/Logo.png"
                    alt=""
                    className="header-logo img-fluid"
                  />
                </NavLink>
                <button
                  type="button"
                  className="mobile-nav-toggle d-lg-none showInMobile anrsb-nav-act"
                >
                  <i className="icofont-navigation-menu"></i>
                </button>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
