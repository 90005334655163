import React from "react";
import {
  Card,
  Grid,
  List,
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormGroup,
} from "@material-ui/core";
import { Input, Button } from "@mui/material";
import pic1 from "../assets/FoodPage/store-removebg-preview.png";
import pic2 from "../assets/FashionPage/p1fashion-removebg-preview.png";
import pic3 from "../assets/FashionPage/p2fashion-removebg-preview.png";
import pic4 from "../assets/FashionPage/Screenshot_2024-01-22_234146-removebg-preview.png";
import pic5 from "../assets/FashionPage/p4fashion-removebg-preview.png";
import pic6 from "../assets/FashionPage/image (1).png";
import pic7 from "../assets/FashionPage/image (4).png";
import pic8 from "../assets/FashionPage/MicrosoftTeams-image (22) (1).png"
import pic9 from "../assets/FoodPage/MicrosoftTeams-image (51) (1).png"
import pic10 from "../assets/FoodPage/image (1).png"
import pic11 from "../assets/FoodPage/Group 1286 1.png"

import pic12 from "../assets/FoodPage/credit_card_FILL0_wght200_GRAD0_opsz24.svg";
import pic13 from "../assets/FoodPage/inventory_2_FILL1_wght200_GRAD0_opsz24.svg";
import pic14 from "../assets/FoodPage/attach_money_FILL1_wght300_GRAD0_opsz24.svg";
import pic15 from "../assets/FoodPage/restaurant_FILL1_wght300_GRAD0_opsz24.svg";
import pic16 from "../assets/FoodPage/shopping_bag_FILL1_wght300_GRAD0_opsz24.svg";
import pic17 from "../assets/FoodPage/monitoring_FILL1_wght300_GRAD0_opsz24.svg";


import icon8 from "../assets/FoodPage/Icons/MicrosoftTeams-image (45) (1).png";
import icon9 from "../assets/FoodPage/Icons/MicrosoftTeams-image (46) (1).png";
import icon10 from "../assets/FoodPage/Icons/MicrosoftTeams-image (47) (1).png";
import icon11 from "../assets/FoodPage/Icons/MicrosoftTeams-image (48) (1).png";
import icon12 from "../assets/FoodPage/Icons/MicrosoftTeams-image (49) (1).png";
import icon13 from "../assets/FoodPage/Icons/MicrosoftTeams-image (50) (1).png";

import breez from "../assets/FoodPage/breez Explore (1).png"
import astra from "../assets/FoodPage/astra explore (2).png"
import leap from "../assets/FoodPage/MicrosoftTeams-image (44) (1).png"
import swyft from "../assets/FoodPage/MicrosoftTeams-image (43) (1).png"
import digital from "../assets/FoodPage/digital signage explore (1).png"

const useStyles = makeStyles((theme) => ({
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "2.25rem",
    paddingBottom: "2.25rem",
  },
  inputbtn: {
    borderRadius: "0.5rem",
    backgroundColor: "#F0F4F3",
    height: "3rem",
    padding: "0.3rem",
    paddingLeft: "1rem",
  },
}));

export default function Fashion() {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" xs={12} sm={12} md={12} lg={12} style={{backgroundColor:"#F9FAFB"}}>
      <Grid
        container
        justifyContent="left"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ backgroundColor: "#0A0F25" }}
      >
        <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>

        <Grid item xs={10} sm={10} md={6} lg={6} style={{ marginTop: "10rem" }}>
          <Typography align="left" style={{ fontWeight: "bold",  color: "white"}}>
          FASHION MERCHANDISE
          </Typography>
          <Typography
            align="left"
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "40px",
              lineHeight: "2.8rem",
              paddingBottom: "1.5rem",
            }}
          >
            Drive Fashion Merchandising Forward With the Power of AI
          </Typography>{" "}
          <Typography
            align="left"
            style={{
              color: "white",
              fontSize: "15px",
            }}
          >Experience AI’s game-changing impact on fashion merchandising through Cyntra. Boost your company's profits while improving operational efficiency with a range of Cyntra retail technologies including our innovative AI voice-activated self-service kiosk. With our cutting-edge tools, you're not just adopting technology. You are embracing a smarter, more efficient, and profitable future for your fashion enterprise.  
          </Typography>{" "}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "1rem", paddingBottom: "3rem" }}
          >
            <Grid
              item
              align="left"
              xs={10}
              sm={10}
              md={4}
              lg={4}
              style={{ paddingTop: "2rem" }}
            >
              <Button
                href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                style={{
                  color: "white",
                  backgroundColor: "#3F9AF3",
                  fontWeight: "bold",
                  borderRadius: "10rem",
                  padding: "0.7rem",
                }}
              >
                Schedule a call
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          justifyContent="center"
          xs={10}
          sm={10}
          md={4}
          lg={4}
          style={{ marginTop: "6rem", paddingBottom: "2rem" }}
        >
          <img src={pic8} style={{ width: "60%" }} />
        </Grid>
        <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>

      </Grid>
      <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>
      <Grid item justifyContent="center" xs={10} sm={10} md={10} lg={10}>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop:"1rem"
              }}
            >
              The Right Fit for Your Store
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            ><Grid item xs={12} sm={12} md={12} lg={12}>
            <img src={pic1} style={{width:"100%"}}></img>
          </Grid>

            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" xs={12} sm={12} md={12} lg={12} style={{backgroundColor:"#0A0F25", padding:"2rem", borderRadius:"2rem"}}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Accelerate Your Business Growth
            </Typography>{" "}
            <Typography style={{
                color: "white",

              }}>
              Our innovative technology solutions are tailored to meet the
              unique needs of businesses of all sizes.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              href="https://cyntra.ai/contactus"
              style={{
                marginTop:"1.5rem",
                color: "white",
                backgroundColor: "#3F9AF3",
                fontWeight: "bold",
                borderRadius: "10rem",
                padding: "0.7rem",
              }}
            >
              Contact us
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic2} style={{ width: "50%" }}></img>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "30px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              Faster Checkouts With RFID Tags
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Garments and other merchandise equipped with RFID tags can be quickly scanned, allowing for rapid, accurate 30-second checkouts. This technology eliminates the need for individual barcode scanning, significantly reducing wait times at the checkout counter. It also provides real-time inventory updates, ensuring stock levels are always accurate and up-to-date.
            </Typography>{" "}
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "30px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",

              }}
            >
             Gamified User Interface
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
             Gamified interface enhances the shopping experience, making it engaging and enjoyable. Customers earn points for actions like exploring new clothing collections or trying outfits, redeemable for rewards. Personalized challenges based on shopping habits incentivize interaction with products, boosting customer satisfaction and loyalty.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic3} style={{ width: "50%" }}></img>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic4} style={{ width: "50%" }}></img>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "30px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",

              }}
            >
              Personalized Experience
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
             Based on customer purchase history and preferences, personalized product recommendations are offered. This tailored approach not only enhances the shopping experience but also increases the likelihood of purchases, as customers are presented with items that align with their personal style and preferences.
            </Typography>{" "}
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "30px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",

              }}
            >
              Amazing Reward System
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
             Through our self-service kiosk, you can employ a sophisticated reward system that incentivizes customer loyalty and repeat purchases. Special discounts, personalized promotions, and exclusive deals can be tailored to each customer's shopping habits, making them feel valued and understood. These resonating offers are created by the device through purchase history and preference analysis. 
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic5} style={{ width: "50%" }}></img>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              Elevate Your Restaurant's Potential
            </Typography>{" "}
            <Typography
              align="center"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              Transform restaurant operations with a comprehensive suite of
              solutions, including advanced POS, purchasing controls, inventory
              management, and accounting essentials.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="left"
              style={{ margin: "1rem" }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid
                item
                align="center"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={pic12} style={{ width: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Purchasing
                </text>
                <Typography>
                  Spend less time on paperwork and more on growing your business
                </Typography>
              </Grid>
              <Grid
                item
                align="center"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={pic13} style={{ width: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Inventory
                </text>
                <Typography>
                  Ensure right amount of stock at the right time, and maximize
                  profits
                </Typography>
              </Grid>
              <Grid
                item
                align="center"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={pic14} style={{ width: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Accounting
                </text>
                <Typography>
                  Manage finances, and maintain compliance with tax laws and
                  regulations
                </Typography>
              </Grid>
              <Grid
                item
                align="center"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={pic15} style={{ width: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Menu Engineering
                </text>
                <Typography>
                  Create a menu that balances cost, profits and customer
                  satisfaction{" "}
                </Typography>
              </Grid>
              <Grid
                item
                align="center"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={pic16} style={{ width: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Loyalty Program
                </text>
                <Typography>
                  Reward your customers for repeat business and build a loyal
                  customer base
                </Typography>
              </Grid>
              <Grid
                item
                align="center"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={pic17} style={{ width: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Analytics & Reporting
                </text>
                <Typography>
                  Get real-time insights into your restaurant's performance{" "}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={4} lg={4}>
              <img src={pic10} style={{width:"100%"}} />

              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Help Your Pop-Up Store Dominate Flash Retailing With Cyntra’s
              Cutting-Edge Products
            </Typography>{" "}
            <Typography
              align="center"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              There are many roadblocks to running a successful pop-up store.
              The biggest of them all is the lack of a business-friendly
              infrastructure, leading to missed sales opportunities. Your pop-up
              shops can avoid the hurdles of operational challenges, business
              barriers, and limited marketing time with the following advantages
              bequeathed by Cyntra.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid
                item
                align="left"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={icon8} style={{ height: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {" "}
                  Swift Set-up
                </text>
                <Typography>
                  For pop-up stores, time is limited . Cyntra lets you set up
                  shop quickly with their simple plug & play installations and
                  minimal product configurations. This maximizes your business
                  uptime and minimizes the time-taking set-up phase.{" "}
                </Typography>
              </Grid>
              <Grid
                item
                align="left"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={icon9} style={{ height: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {" "}
                  Cutting-Edge Retail Products
                </text>
                <Typography>
                Cyntra offers a range of products powered by the latest hardware and equipped with smart software. We guarantee a standout pop-up shop experience to your customers that’ll set you apart from the competition. 
                </Typography>
              </Grid>
              <Grid
                item
                align="left"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={icon10} style={{ height: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {" "}
                  Speedy Checkouts
                </text>
                <Typography>
                We have revolutionized the checkout process with 30-second checkouts, offered by Breez, our flagship self-service kiosk. Increased sales and reduced abandoned purchases within the limited time is made possible through Breez.   
                </Typography>
              </Grid>
              <Grid
                item
                align="left"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={icon11} style={{ height: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {" "}
                  RFID-Enhanced Inventory Management{" "}
                </text>
                <Typography>
                Pop-up shops need accurate stock updates. With our RFID-integrated products, get precise inventory tracking and instant checkouts. Ensure your store is equipped with high-demand items and make informed restocking decisions. 
                </Typography>
              </Grid>
              <Grid
                item
                align="left"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={icon12} style={{ height: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {" "}
                  Gamified Customer Journey
                </text>
                <Typography>
                Pop-up stores should captivate shoppers. Cyntra's gamified platform features achievements, tracks progress, and offers rewards. This guarantees an interactive shopping journey, keeping customers engaged even after leaving your pop-up shop. 
                </Typography>
              </Grid>
              <Grid
                item
                align="left"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={icon13} style={{ height: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {" "}
                  Robust Backend System{" "}
                </text>
                <Typography>
                Managing multiple pop-up locations is easy with Cyntra. Our robust backend allows you to synchronize operations across different venues, ensuring uniformity in pricing and promotions. This centralized management gives businesses a competitive edge .
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12} style={{ paddingTop:"1rem"}}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
               
              }}
            >
              Experience a Seamless Retail Ecosystem
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Cyntra's unified retail ecosystem can mean the difference between
              merely surviving in the competitive market and thriving with
              unparalleled growth. Reach out to us today.
            </Typography>{" "}
            <Grid
              item
              align="left"
              xs={10}
              sm={10}
              md={4}
              lg={4}
              style={{ paddingTop: "2rem" }}
            >
              <Button
                href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                style={{
                  color: "white",
                  backgroundColor: "#3F9AF3",
                  fontWeight: "bold",
                  borderRadius: "10rem",
                  padding: "0.7rem",
                }}
              >
                Schedule a call
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic9} style={{ width: "70%" }}></img>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "1rem", paddingBottom: "3rem" }}
          >

          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",paddingBottom:"1rem"
              }}
            >
              Streamline Your Business With Our Complete Suite of Applications
            </Typography>{" "}
            <Typography
              align="center"
              style={{
                color: "black",
                fontSize: "15px",
                paddingBottom:"2rem"
              }}
            >
              Elevate the experience of your customers with our comprehensive
              suite of cutting-edge features. Seamlessly integrate efficient
              checkouts, streamlined ordering processes, and robust inventory
              and operations tools to unlock unprecedented productivity.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{paddingBottom:"1rem"}}
            >
              <Grid item xs={12} sm={12} md={2} lg={2} style={{height:"20%"}}>
              <img src={breez} style={{height:"10rem" }}/>
                <Typography style={{fontWeight:"bold", paddingTop:"1rem"}}>Breez Kiosk</Typography>
                <Typography>Revamp shopping experience with 30-second checkouts</Typography>
                {/* <a>Read more</a> */}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
              <img src={leap} style={{height:"10rem"}} />
              <Typography  style={{fontWeight:"bold", paddingTop:"1rem"}}>Leap POS</Typography>
              <Typography>Streamline retail operations, no training or infrastructure needed</Typography>
              {/* <a>Read more</a> */}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
              <img src={swyft} style={{height:"10rem"}} />
              <Typography  style={{fontWeight:"bold", paddingTop:"1rem"}}>Swyft mPos</Typography>
              <Typography>Process payments and oversee sales anytime, anywhere </Typography>
              {/* <a>Read more</a> */}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
              <img src={astra} style={{height:"10rem"}} />
              <Typography  style={{fontWeight:"bold", paddingTop:"1rem"}}>Astra Analytics</Typography>
              <Typography>Get real-time insights on sales inventory, and key metrics ​</Typography>
              {/* <a>Read more</a> */}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
              <img src={digital} style={{height:"10rem",width:"100%" }} />
              <Typography  style={{fontWeight:"bold", paddingTop:"1rem"}}>Digital Signage</Typography>
              <Typography>Enhance brand marketing with in-sync displays</Typography>
              {/* <a>Read more</a> */}

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>
    </Grid>
  );
}
