import React from "react";
import {
  Card,
  Grid,
  List,
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormGroup,
} from "@material-ui/core";
import { Input, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "2.25rem",
    paddingBottom: "2.25rem",
  },
  inputbtn: {
    borderRadius: "0.5rem",
    backgroundColor: "#F0F4F3",
    height: "3rem",
    padding: "0.3rem",
    paddingLeft: "1rem",
  },
}));

export default function Fitness() {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" xs={12} sm={12} md={12} lg={12}>
      <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>
      <Grid item justifyContent="center" xs={10} sm={10} md={10} lg={10}>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{ marginTop: "10rem" }}
          >
            <Typography align="left" style={{ fontWeight: "bold" }}>
              FITNESS
            </Typography>
            <Typography
              align="left"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Craft an Engaging and Streamlined Fitness Experience with AI{" "}
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Boost your fitness center's operations with quick member
              registrations and smooth entry management. From organizing classes
              to instant feedback analysis, our solutions, including AI-driven
              Breez, deliver efficient services. These innovations fine-tune
              your operational tasks. Leveraging Cyntra’s state-of-the-art
              technologies, you can make smart data-driven decisions, fostering
              individualized fitness journeys and smart staff management.
            </Typography>{" "}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: "1rem", paddingBottom: "3rem" }}
            >
              <Grid
                item
                align="left"
                xs={10}
                sm={10}
                md={4}
                lg={4}
                style={{ paddingTop: "2rem" }}
              >
                <Button
                  href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                  style={{
                    color: "white",
                    backgroundColor: "#3F9AF3",
                    fontWeight: "bold",
                    borderRadius: "10rem",
                    padding: "0.7rem",
                  }}
                >
                  Schedule a call
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              The Right Fit for Your Fitness Centers
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                s
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Typography
              align="center"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Accelerate Your Business Growth
            </Typography>{" "}
            <Typography>
              Our innovative technology solutions are tailored to meet the
              unique needs of businesses of all sizes.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Button
              href="https://cyntra.ai/contactus"
              style={{
                color: "white",
                backgroundColor: "#3F9AF3",
                fontWeight: "bold",
                borderRadius: "10rem",
                padding: "0.7rem",
              }}
            >
              Contact us
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            ss
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Effortless Equipment Checkout
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Voice-activated commands and smart face scans can be used to
              facilitate effortless equipment checkout. This reduces wait times
              and enhances the member experience, leading to increased member
              satisfaction and loyalty. Streamlined equipment checkout ensures
              quicker access to gear, minimizes manual errors, and improves
              overall gym efficiency, allowing members to focus more on their
              workout and less on administrative hassles.
            </Typography>{" "}
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Easy Access to Class Registration
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Breez can streamline the class registration process in fitness
              facilities. Members can easily browse through available classes,
              view detailed descriptions, and register for their preferred
              sessions, all through an intuitive interface. The system can also
              suggest classes based on members' past attendance, fitness goals,
              and preferences, providing a personalized experience.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            ss
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            ss
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Trampoline Disclaimer
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              In fitness centers offering trampoline-based activities, ensuring
              safety and compliance with regulations is important. Breez can
              implement a clear, concise trampoline disclaimer to members. This
              feature can display the necessary safety guidelines and legal
              disclaimers, requiring members to acknowledge and accept them
              before participating in trampoline activities.
            </Typography>{" "}
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Customized Gym Experience
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Breez can craft personalized gym programs for each member using
              their profile data, suggesting workout plans and classes tailored
              to their history, goals, and preferences. These can be adjusted
              later with respect to their fitness progress and experience
              feedback.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            ss
          </Grid>
        </Grid>

        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              More Than Just a Gym Registration System
            </Typography>{" "}
            <Typography
              align="center"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Elevate your fitness services with our cutting-edge retail
              technology solutions. Experience seamless session booking,
              efficient class management, and comprehensive member management.
              Stay ahead of the competition and enhance member experiences with
              our innovative advancements.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                s
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Experience a Seamless Retail Ecosystem
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Cyntra's unified retail ecosystem can mean the difference between
              merely surviving in the competitive market and thriving with
              unparalleled growth. Reach out to us today.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            ss
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "1rem", paddingBottom: "3rem" }}
          >
            <Grid
              item
              align="left"
              xs={10}
              sm={10}
              md={4}
              lg={4}
              style={{ paddingTop: "2rem" }}
            >
              <Button
                href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                style={{
                  color: "white",
                  backgroundColor: "#3F9AF3",
                  fontWeight: "bold",
                  borderRadius: "10rem",
                  padding: "0.7rem",
                }}
              >
                Schedule a call
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Streamline Your Business With Our Complete Suite of Applications
            </Typography>{" "}
            <Typography
              align="center"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Elevate the experience of your customers with our comprehensive
              suite of cutting-edge features. Seamlessly integrate efficient
              checkouts, streamlined ordering processes, and robust inventory
              and operations tools to unlock unprecedented productivity.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                s
              </Grid>
              <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                s
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>
    </Grid>
  );
}
