import React, { Component } from 'react';
// import Carousel from "react-elastic-carousel";
// import { ReactVideo, YoutubePlayer } from 'reactjs-media';
// const breakPoints = [
//     { width: 1, itemsToShow: 1 },
//     { width: 550, itemsToShow: 2 },
//     { width: 768, itemsToShow: 3 },
//     { width: 1200, itemsToShow: 3 },
// ];
// const links = [
//     { 
//         "poster": "./gallery/Media/img/videos-img/1.jpeg",
//         "src": "./gallery/Media/videos/wendys-KIOSKFULLVIDEO.mp4"
//     },
//     { 
//         "poster": "./gallery/Media/img/videos-img/2.jpeg",
//         "src": "./gallery/Media/videos/Cravrr-POS.mp4"
//     },
//     { 
//         "poster": "./gallery/Media/img/videos-img/3.jpeg",
//         "src": "./gallery/Media/videos/Dark Ketchen.mp4"
//     },
//     { 
//         "poster": "./gallery/Media/img/videos-img/4.jpg",
//         "src": "./gallery/Media/videos/ralphloren.mp4"
//     },
//     { 
//         "poster": "./gallery/Media/img/videos-img/5.jpeg",
//         "src": "./gallery/Media/videos/w.mp4"
//     },
//     { 
//         "poster": "./gallery/Media/img/videos-img/6.jpeg",
//         "src": "./gallery/Media/videos/Cravrr.mp4"
//     },
//     { 
//         "poster": "./gallery/Media/img/videos-img/7.jpg",
//         "src": "./gallery/Media/videos/poswithassistant1.mp4"
//     },
//     { 
//         "poster": "./gallery/Media/img/videos-img/8.jpg",
//         "src": "./gallery/Media/videos/poswithassistant2.mp4"
//     },
//     { 
//         "poster": "./gallery/Media/img/videos-img/9.jpg",
//         "src": "./gallery/Media/videos/contactless_solution.mp4"
//     }
// ]
export default class Demo extends Component {
    render() {
        return (
           <>
           <main id="main">
                <section className="aboutheader_main3">
                    <div className="container">
                        <h2 className="about_head1">DEMO</h2>
                        <p className="heading_para1">Delivering Innovation and Excellence beyond Expectation </p>
                    </div>
                </section>

                <section id="demo" className="mydiv portfolio  animate__animated animate__fadeInUp" style={{padding: '0px'}}>
                    <div id="mydiv" className="row">
                        {/* <Carousel breakPoints={breakPoints}>
                            {links.map((item,index) => (
                                <ReactVideo
                                    src={item.src}
                                    poster={item.poster}
                                    height={350} />
                            ))}
                        </Carousel> */}
                        <iframe src="gallery/index.html" width="100%" height="420" frameBorder="0">Browser not compatible.</iframe>
                    </div>
                    <p className="showdemo1" id="our-demo"></p>
                </section>
            </main>
           </>
        )
    }
}