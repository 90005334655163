import React, { useEffect } from 'react'



export default function KioskRedirect() {
    useEffect(() => {
        window.location.href = "https://cyntra.ai/self-service-kiosk"
    }, [])
  return (
    <div></div>
  )
}
