import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Helmet from 'react-helmet';
import ModalP from "../Model";
import { Grid } from "@material-ui/core";


export default class Blog extends Component {
  render() {
    return (
      <>
       <Grid>  
      {/* <ModalP></ModalP> */}
      </Grid>
       <head>
         <div class="wrapper">
         <Helmet>
          
         <title>Blog</title>
         <meta property="og:description" content="The Future of Retail "/>
            <meta id="og-title" property="og:title" content="CyntraLabs | Blogs" />  
            <meta  name="keywords" content="The Future of Retail" />  
            <meta property="og:image" content="https://cyntra-images.s3.ap-south-1.amazonaws.com/email-templete-img/cyntraLabs.jpeg"/>
            </Helmet>
        </div>
        </head>
        <main id="main">
          <section className="leastblog_div9">
            <div className="leatest_bloghead32">
              <div className="container">
                <h2 className="about_head13" style={{color:"white" }}>
                Latest industry trends, integration, in-store customer experience, faster check ins and outs, and retail insights from our team of retail experts.
                </h2>
                {/* <p className="heading_para1">
                  Contactless has become a buzz word worldwide, more importantly
                  in the retail industry. We are witnessing a global shift
                  towards a contactless economy due to the social distancing
                  practices that sparked as a result of COVID-19. Despite no
                  vaccines, no cure,{" "}
                </p> */}
                {/* <NavLink
                  className="lblog_readmore21"
                  to={"blog-contactless-ordering-payments"}
                >
                  Read More{" "}
                </NavLink> */}
              </div>
            </div>
          </section>
          <section className="ourteam_section1">
            <div className="row ourteam_div1">

            <div className="col-sm-4 ourtem_mem5">
                <div className="blog_section21">
                  <img
                    className="blogimg32"
                    src="./assets/img/cover.png"
                  />
                  <div className="blog_div21">
                    <h3 className="blog_para1">
                      The Future of Retail: How CyntraLabs is Leading the Way{" "}
                    </h3>
                    <p className="blog_para2">
                      The retail industry is constantly evolving, with new
                      technologies and trends emerging regularly. From online
                      shopping to mobile checkout, retailers are constantly
                      seeking ways to improve the customer experience and
                      increase operational efficiency.
                      <br></br>
                    </p>
                  </div>
                  <NavLink className="blog_a21" to={"blog-future-of-retail"}>
                    READ MORE
                  </NavLink>
                </div>
              </div>
              <div className="col-sm-4 ourtem_mem5">
                <div className="blog_section21">
                  <img className="blogimg32" src="./assets/img/blog1.jpg" />
                  <div className="blog_div21">
                    <h3 className="blog_para1">
                      Timely Customer Connect with Right Analytics
                    </h3>
                    <p className="blog_para2">
                      Customer journeys are more varied and distinct than ever
                      with the rise of new digital technologies &amp; mobile and
                      online shopping. Today’s consumers expect brands to
                      anticipate their needs. How as a brand are you growing?
                      Are you there with useful
                    </p>
                  </div>
                  <NavLink
                    className="blog_a21"
                    to={"blog-connect-with-right-analytics"}
                  >
                    READ MORE
                  </NavLink>
                </div>
              </div>
              <div className="col-sm-4 ourtem_mem5">
                <div className="blog_section21">
                  <img
                    className="blogimg32"
                    src="./assets/img/gost-kitchen.jpg"
                  />
                  <div className="blog_div21">
                    <h3 className="blog_para1">
                      Ghost Kitchens: The New Normal
                    </h3>
                    <p className="blog_para2">
                      Over the past few years, online food delivery was anyway
                      on an upswing. But, this global pandemic, COVID-19 has
                      turned out to be a trend setter for Ghost Kitchens. Ghost
                      Kitchen has caused a drift in the food industry, as the
                      dine-in option now takes
                      <br></br>
                    </p>
                  </div>
                  <NavLink className="blog_a21" to={"blog-ghost-kitchens"}>
                    READ MORE
                  </NavLink>
                </div>
              </div>
              <div className="col-sm-4 ourtem_mem5">
                <div className="blog_section21">
                  <img
                    className="blogimg32"
                    src="./assets/img/blog_breeze3.jpg"
                  />
                  <div className="blog_div21">
                    <h3 className="blog_para1">
                      Self-Service Kiosk – It’s all about Customer Convenience
                    </h3>
                    <p className="blog_para2">
                      Technology plays an important role in today’s time in
                      almost everyone’s life in some way or the other. It
                      affects the way we all communicate, think, operate and
                      learn. It has truly become an inevitable part of today’s
                      time
                      <br></br>
                    </p>
                  </div>
                  <NavLink className="blog_a21" to={"blog-breeze"}>
                    READ MORE
                  </NavLink>
                </div>
              </div>
            
            </div>
          </section>
        </main>
      </>
    );
  }
}
