import React from "react";
import {
  Card,
  Grid,
  List,
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormGroup,
} from "@material-ui/core";
import { Input, Button } from "@mui/material";
import pic1 from "../assets/FoodPage/store-removebg-preview.png";
import pic2 from "../assets/BankingPage/image (1).png";
import pic3 from "../assets/BankingPage/image (2).png";
import pic4 from "../assets/BankingPage/image (3).png";
import pic5 from "../assets/FashionPage/p4fashion-removebg-preview.png";
import pic6 from "../assets/BankingPage/banking.png";
import pic7 from "../assets/FashionPage/p6fashion-removebg-preview.png";
import pic8 from "../assets/BankingPage/Mask group.png";
import pic9 from "../assets/FoodPage/MicrosoftTeams-image (51) (1).png";
import pic10 from "../assets/FoodPage/image (1).png";
import pic11 from "../assets/FoodPage/Group 1286 1.png";

import icon8 from "../assets/BankingPage/finance_mode_FILL0_wght400_GRAD0_opsz24.svg";
import icon9 from "../assets/BankingPage/request_quote_FILL0_wght400_GRAD0_opsz24.svg";
import icon10 from "../assets/BankingPage/candlestick_chart_FILL0_wght300_GRAD0_opsz24.svg";
import icon11 from "../assets/BankingPage/mitre_FILL0_wght300_GRAD0_opsz24.svg";
import icon12 from "../assets/BankingPage/credit_score_FILL0_wght300_GRAD0_opsz24.svg";
import icon13 from "../assets/BankingPage/card_membership_FILL0_wght300_GRAD0_opsz24.svg";

import icons1 from "../assets/BankingPage/analytics_FILL1_wght300_GRAD0_opsz24.svg";
import icons2 from "../assets/BankingPage/database_FILL1_wght300_GRAD0_opsz24.svg";
import icons3 from "../assets/BankingPage/attach_money_FILL0_wght300_GRAD0_opsz24.svg";
import icons4 from "../assets/BankingPage/groups_3_FILL1_wght300_GRAD0_opsz24.svg";
import icons5 from "../assets/BankingPage/multiline_chart_FILL1_wght300_GRAD0_opsz24.svg";
import icons6 from "../assets/BankingPage/precision_manufacturing_FILL1_wght300_GRAD0_opsz24.svg";
import icons7 from "../assets/BankingPage/tenancy_FILL1_wght300_GRAD0_opsz24.svg";
import icons8 from "../assets/BankingPage/topic_FILL1_wght300_GRAD0_opsz24.svg";

import breez from "../assets/FoodPage/breez Explore (1).png";
import astra from "../assets/FoodPage/astra explore (2).png";
import leap from "../assets/FoodPage/MicrosoftTeams-image (44) (1).png";
import swyft from "../assets/FoodPage/MicrosoftTeams-image (43) (1).png";
import digital from "../assets/FoodPage/digital signage explore (1).png";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "2.25rem",
    paddingBottom: "2.25rem",
  },
  inputbtn: {
    borderRadius: "0.5rem",
    backgroundColor: "#F0F4F3",
    height: "3rem",
    padding: "0.3rem",
    paddingLeft: "1rem",
  },
}));

export default function Banking() {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{ backgroundColor: "#F9FAFB" }}
    >
      <Grid
        container
        justifyContent="left"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ backgroundColor: "#0A0F25" }}
      >
        <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>

        <Grid item xs={10} sm={10} md={6} lg={6} style={{ marginTop: "10rem" }}>
          <Typography
            align="left"
            style={{ fontWeight: "bold", color: "white" }}
          >
            BANKING & FINANCE <b />
          </Typography>
          <Typography
            align="left"
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "40px",
              lineHeight: "2.8rem",
              paddingBottom: "1.5rem",
            }}
          >
            Upgrade Your Finance Operations with Custom Integration Solutions
          </Typography>{" "}
          <Typography
            align="left"
            style={{
              color: "white",
              fontSize: "15px",
            }}
          >
            Simplify banking and finance with CyntraConnect, your top choice for
            tailor-made data integration solutions. We specialize in merging
            complex data systems, making operations smoother and boosting
            data-driven decisions in the finance industry.{" "}
          </Typography>{" "}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "1rem", paddingBottom: "3rem" }}
          >
            <Grid
              item
              align="left"
              xs={10}
              sm={10}
              md={4}
              lg={4}
              style={{ paddingTop: "2rem" }}
            >
              <Button
                href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                style={{
                  color: "white",
                  backgroundColor: "#3F9AF3",
                  fontWeight: "bold",
                  borderRadius: "10rem",
                  padding: "0.7rem",
                }}
              >
                Schedule a call
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid> */}
        <Grid
          item
          justifyContent="center"
          xs={10}
          sm={10}
          md={4}
          lg={4}
          style={{ marginTop: "6rem", paddingBottom: "2rem" }}
        >
          <img src={pic8} style={{ width: "60%" }} />
        </Grid>

        <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>
      </Grid>
      <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>
      <Grid item justifyContent="center" xs={10} sm={10} md={10} lg={10}>
        <Grid
          container
          justifyContent="left"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ paddingBottom: "5rem" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              Innovatively Facilitating Fluid Data Dynamics
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{
                  paddingTop: "4rem",
                  paddingBottom: "4rem",

                  height: "15rem",
                  paddingBottom: "5rem",
                }}
              >
                <img src={icons2} style={{ height: "100%" }} />{" "}
                <Typography style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  Harmonious Data Fusion{" "}
                </Typography>
                <Typography>
                  We expertly and effortlessly blend diverse data for informed
                  financial strategies, from legacy banking systems to modern
                  cloud-based platforms.{" "}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{
                  paddingTop: "4rem",
                  paddingBottom: "4rem",
                  height: "15rem",
                  paddingBottom: "5rem",
                }}
              >
                <img src={icons1} style={{ height: "100%" }} />{" "}
                <Typography style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  Intelligent Automation{" "}
                </Typography>
                <Typography>
                  We automate critical financial data processes, delivering key
                  insights swiftly and efficiently for data-backed decisions.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{
                  paddingTop: "4rem",
                  paddingBottom: "4rem",                  height: "15rem",
                  paddingBottom: "5rem",
                }}
              >
                <img src={icons6} style={{ height: "100%" }} />{" "}
                <Typography style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  Operational Refinement{" "}
                </Typography>
                <Typography>
                  Enhance precision and productivity by eliminating errors in
                  your sensitive business operations with our smart solutions.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{
                  paddingTop: "4rem",
                  paddingBottom: "4rem",                  height: "15rem",
                  paddingBottom: "5rem",
                }}
              >
                <img src={icons8} style={{ height: "100%" }} />{" "}
                <Typography style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  Strategic Decision-Making{" "}
                </Typography>
                <Typography>
                  Get a comprehensive view of your financial operations with our
                  unified data approach for better financial strategies.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{
                  paddingTop: "4rem",
                  paddingBottom: "4rem",                  height: "15rem",
                  paddingBottom: "5rem",
                }}
              >
                <img src={icons5} style={{ height: "100%" }} />{" "}
                <Typography style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  Scalable Solution
                </Typography>
                <Typography>
                  Our scalable solutions ensure youre consistently at the
                  forefront, maximizing the value of your data while ensuring
                  compliance with industry regulations.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{
                  paddingTop: "4rem",
                  paddingBottom: "4rem",                  height: "15rem",
                  paddingBottom: "5rem",
                }}
              >
                <img
                  src={icons3}
                  style={{ height: "100%", paddingTop: "1.2rem" }}
                />{" "}
                <Typography style={{ fontWeight: "bold", fontSize: "1rem" }}>
                  Accounting Essentials{" "}
                </Typography>
                <Typography>
                  Manage finances, make informed decision, and maintain
                  compliance
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <img src={pic6} style={{ width: "100%" }} />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            backgroundColor: "#0A0F25",
            padding: "2rem",
            borderRadius: "2rem",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Accelerate Your Business Growth
            </Typography>{" "}
            <Typography
              style={{
                color: "white",
              }}
            >
              Our innovative technology solutions are tailored to meet the
              unique needs of businesses of all sizes.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              href="https://cyntra.ai/contactus"
              style={{
                marginTop: "1.5rem",
                color: "white",
                backgroundColor: "#3F9AF3",
                fontWeight: "bold",
                borderRadius: "10rem",
                padding: "0.7rem",
              }}
            >
              Contact us
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              Why Partner with CyntraLabs?
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} style={{padding:"1rem"}}>
                <Card
                  style={{
                    height: "20rem",
                    padding: "2rem",
                    backgroundColor: "#F9FAFB",
                    borderRadius: "1rem",
                    boxShadow:
                      "#F9FAFB 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 3px",
                  }}
                >
                  <Grid alignContent="center">
                    <img src={pic2}></img>
                  </Grid>
                  Your goals guide our joint efforts towards shared success.
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} style={{padding:"1rem"}}>
                <Card
                  style={{
                    height: "20rem",
                    padding: "2rem",
                    backgroundColor: "#F9FAFB",
                    borderRadius: "1rem",
                    boxShadow:
                      "#F9FAFB 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 3px",
                  }}
                >
                  <Grid alignContent="center">
                    <img src={pic3}></img>
                  </Grid>
                  Beyond linking data, we enable strategies for financial
                  strength and innovation.
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} style={{padding:"1rem"}}>
                <Card
                  style={{
                    height: "20rem",
                    padding: "2rem",
                    backgroundColor: "#F9FAFB",
                    borderRadius: "1rem",
                    boxShadow:
                      "#F9FAFB 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 3px",
                  }}
                >
                  <Grid alignContent="center">
                    <img src={pic4}></img>
                  </Grid>
                  Our dynamic solutions evolve with finance trends, ensuring
                  agility and potency.
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="left"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ paddingTop: "1rem" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "2.5rem",
                color: "#3F9AF3",
              }}
            >
              Upgrade Your Finance Operations Innovatively
            </Typography>
            <Typography
              style={{ fontSize: "1rem", color: "black", padding: "1rem" }}
              >
              Simplify banking and finance with our tailor-made innovative
              solutions. We specialize in merging complex data systems, making
              operations smoother and boosting data-driven decisions in the
              finance industry.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{ padding: "1rem", paddingTop: "4rem",
            paddingBottom: "4rem", }}
          >
            <img src={icon9} style={{ height: "25%" }} />
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: "#3F9AF3",
              }}
            >
              Rapid Deployment of Financial Platforms{" "}
            </Typography>
            <Typography
              style={{ fontSize: "1rem", color: "black", padding: "1rem" }}
              >
              In the fast-paced financial sector, we understand that time is of
              the essence. That’s why we at Cyntra are committed to enabling
              rapid deployment of core banking systems. Our turnkey solutions
              are designed to simplify complex integrations and minimize
              operational downtime, so we can accelerate your journey to
              launching new financial products.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{ padding: "1rem", paddingTop: "4rem",
            paddingBottom: "4rem", }}
          >
            <img src={icon8} style={{ height: "25%" }} />

            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: "#3F9AF3",
              }}
            >
              Innovative FinTech Solutions{" "}
            </Typography>
            <Typography
              style={{ fontSize: "1rem", color: "black", padding: "1rem" }}
              >
              At Cyntra, we’re at the forefront of financial technology. We
              equip banks with the latest algorithms and machine learning
              models, providing superior risk assessment tools and algorithmic
              trading advantages. We're dedicated to giving your bank a
              competitive edge in both retail and investment banking.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{ paddingTop: "4rem",
            paddingBottom: "4rem", }}
          >
            <img src={icon12} style={{ height: "25%" }} />
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: "#3F9AF3",
              }}
            >
              High-Speed Transaction Processing
            </Typography>
            <Typography
              style={{ fontSize: "1rem", color: "black", padding: "1rem" }}
              >
              We’re revolutionizing transaction processing with Cyntra’s
              high-frequency trading infrastructure. We ensure sub-second order
              execution to reduce slippage and enhance liquidity management.
              We’re here to boost your customer's trust and satisfaction with
              our reliable transaction processing
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{ paddingTop: "4rem",
            paddingBottom: "4rem", }}
          >
            <img src={icon10} style={{ height: "25%" }} />{" "}

            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: "#3F9AF3",
              }}
            >
              Asset and Liability Management Optimization
            </Typography>
            <Typography
              style={{ fontSize: "1rem", color: "black", padding: "1rem" }}
              >
              We provide RFID-enabled real-time tracking for comprehensive asset
              and liability management. At Cyntra, we’re focused on offering
              precise balance sheet management and optimizing interest rate and
              liquidity risk management, empowering you to make informed
              strategic financial decisions.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{paddingTop: "4rem",
            paddingBottom: "4rem",  }}
          >
            <img src={icon13} style={{ height: "25%" }} />

            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: "#3F9AF3",
              }}
            >
              Customized Banking Experience
            </Typography>
            <Typography
              style={{ fontSize: "1rem", color: "black", padding: "1rem" }}
              >
              We leverage advanced analytics at Cyntra to deliver a banking
              journey that’s tailored just for you. Our data-driven insights are
              crucial in providing personalized investment advice, optimizing
              your wealth management strategies, and creating bespoke financial
              products that cater to your high-net-worth clientele.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{paddingTop: "4rem",
            paddingBottom: "4rem", }}
          >
            <img src={icon11} style={{ height: "25%" }} />{" "}
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: "#3F9AF3",
              }}
            >
              Integrated Risk Management Framework{" "}
            </Typography>
            <Typography
              style={{ fontSize: "1rem", color: "black", padding: "1rem" }}
            >
              We’ve built a robust backend system at Cyntra to provide you with
              an all-encompassing risk management framework. We ensure
              regulatory compliance, cyber resilience, and consistent risk
              mitigation across all fronts, fortifying your financial
              institution's integrity and giving you peace of mind.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="left"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ paddingTop: "3rem" }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Experience a Seamless Retail Ecosystem
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Cyntra's unified retail ecosystem can mean the difference between
              merely surviving in the competitive market and thriving with
              unparalleled growth. Reach out to us today.
            </Typography>{" "}
            <Grid
              item
              align="left"
              xs={10}
              sm={10}
              md={4}
              lg={4}
              style={{ paddingTop: "2rem" }}
            >
              <Button
                href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                style={{
                  color: "white",
                  backgroundColor: "#3F9AF3",
                  fontWeight: "bold",
                  borderRadius: "10rem",
                  padding: "0.7rem",
                }}
              >
                Schedule a call
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic9} style={{ width: "70%" }}></img>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "1rem", paddingBottom: "3rem" }}
          ></Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>

        </Grid>
      </Grid>
      <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>
    </Grid>
  );
}
