import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Helmet from 'react-helmet';
import ModalP from "./Model";
import { Grid } from "@material-ui/core";
export default class Home extends Component {
  render() {
    return (
      <>
  <Grid>  
      {/* <ModalP></ModalP> */}
      </Grid>
<div class="wrapper">
<Helmet>
<title>CyntraLabs</title>
{/* <meta name="description" content="Improving customer experience in retail with 30 seconds checkout, RFID scanning, and plug & play solution. CyntraLabs is streamlining system integration with innovative Solutions." />
<meta property="og:image" content="https://cyntra-images.s3.ap-south-1.amazonaws.com/email-templete-img/cyntraLabs.jpeg"/>
<meta content=" Retail , Integration" name="keywords"></meta> */}

<meta property="og:title" content="The Future of Retail: How CyntraLabs is Paving the Way"/>
<meta property="og:type" content="Website"/>
<meta property="og:description"  content="Explore the latest advancements in retail technology and see how CyntraLabs is revolutionizing the industry with its innovative solutions. Learn about the future of retail and how it will impact the shopping experience."/>
<meta property="og:url" content="https://cyntralabs.com/blog-future-of-retail"/>
<meta property="og:image" content="https://cyntralabs.com/assets/img/cover.png"/> 
</Helmet>
</div>


        <section id="hero">
          <div className="darkbgblue"></div>
          {/* <div className="clfhome1">
                        <video id="wall_video" loop="true" autoplay="autoplay" preload="none" playsinline muted>
                            <source src="assets/videos/home.mp4" type="video/mp4" />
                        </video>
                    </div> */}
          <div className="container animate__animated animate__fadeInDown">
            <div className="row justify-content-center">
              <div className="col-xl-8 ">
                <h1>
                  <img
                    src="assets/img/Logo.png"
                    className="homelogo img-fluid"
                    alt=""
                  />
                </h1>
                <h2>
                  <NavLink
                    exact
                    activeClassName="active"
                    to={"integration"}
                    className="heading_link"
                  >
                    INTEGRATION
                  </NavLink>{" "}
                  |{" "}
                  <a
                    // exact
                    // activeClassName="active"
                    href="https://cyntra.ai"
                    style={{color: "white"}}
                    // className="heading_link"
                  >
                    RETAIL
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </section>

        <main id="main">
          <section id="about" className="about mobsection clfhome2">
            <div className="container  animate__animated animate__fadeInLeft cs1p1">
              <div className="section-title">
                <div className="ts1">
                  <span className="cs2 ts2">Explore Solutions:</span>
                </div>
              </div>
              <div className="container  animate__animated animate__fadeInLeft cs1 ts3">
                <div className="row content clfhome3">
                  <div className="col-lg-12 pb-4 pt-lg-0">
                    <p className="cs41">
                      CyntraLabs is focused on providing organizations unified
                      solutions to maximize the business value by utilizing the
                      capabilities offered by emerging technologies. We take
                      pride in providing state-of-the-art solutions in
                      integration and retail that guarantee success in business
                      evolution. Businesses must transform to stay relevant,
                      hence we provide our existing and new partners with
                      foresight to:
                    </p>
                  </div>
                </div>
                <div className="row content cs5">
                  <div className="col-lg-12 clfhome4">
                    <ul className="clfhome5">
                      <li className="cs6 clfhome7">
                        <i className="ri-check-line cs8"></i>Become agile
                      </li>
                      <li className="cs6 clfhome7">
                        <i className="ri-check-line cs8"></i>Realize new growth
                        opportunities
                      </li>
                      <li className="cs6 clfhome7">
                        <i className="ri-check-line cs8"></i>Adapt to new
                        technologies
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row content clfhome4">
                  <div className="col-lg-12 pt-4 pt-lg-0">
                    <p className="cs41">
                      Our next-generation and ever evolving solutions combined
                      with deep industry expertise help organizations with quick
                      and cost-effective end-to-end implementation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="container  animate__animated animate__fadeInLeft cs1">
                <div className="row content clfhome4">
                  <div className="col-lg-6 ts4">
                    <div className="col-lg-12 ts5">
                      <img
                        src="assets/img/RETAIL.png"
                        className="img-fluid clfhome6"
                        alt=""
                      />
                      <p className="cs411">
                        CyntraLab’s reliable and proven solutions give retailers
                        a competitive edge. Our industry-leading retail products
                        and solutions have enabled top global companies to
                        improve execution, enhance sales, and even strengthen
                        growth and profitability. Make retail simpler than ever.
                      </p>
                      <div className="details-box clfhome7">
                        <NavLink
                          exact
                          activeClassName="active"
                          to={"retail"}
                          className="portfolio-info cs9"
                        >
                          <span className="details-link"> Learn more </span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ts6">
                    <div className="col-lg-12 ts5">
                      <img
                        src="assets/img/INTEGRATION.png"
                        className="img-fluid clfhome6"
                        alt=""
                      />
                      <p className="cs4112">
                        Synchronize data between systems, fully automate and
                        optimize your business processes, build a future-proof
                        architecture in the cloud, on-premises, or in a hybrid
                        environment. Always innovating simple solution for
                        complex data.
                        <br></br><br></br>
                         
                      </p>
                      
                      <div className="details-box clfhome7">
                        <NavLink
                          exact
                          activeClassName="active"
                          to={"integration"}
                          className="portfolio-info cs9"
                        >
                          <span className="details-link"> Learn more </span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="portfolio" className="portfolio pp1">
            <div className="cls23">
              <div className="section-title">
                <h2 className="cs2a1">Here’s what’s buzzing:</h2>
                <p className="cs4a1 clfhome7">
                  Introducing CyntraLab’s 30-second checkout kiosks solution.
                </p>
                <div className="details-box clfhome7">
                  <NavLink
                    exact
                    activeClassName="active"
                    to={"kiosks"}
                    className="portfolio-info cs9"
                  >
                    <span className="details-link"> Learn more </span>
                  </NavLink>
                </div>
                <div className="cc_mob">
                  <img
                    src="assets/img/kiosk-retail2-removebg-preview.png"
                    className="img-fluid clfhome8"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <img
              src="assets/img/kiosk-retail2.png"
              className="img-fluid cls23img"
              alt=""
            />
          </section>
        </main>
      </>
    );
  }
}
