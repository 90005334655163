import React from "react";
import {
  Card,
  Grid,
  List,
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormGroup,
} from "@material-ui/core";

import { Input, Button } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Footer from "../Include/Footer";
// import sap_ariba from "./assets/SAP/SAP Banner.png"
import sap_ariba1 from "./assets/SAP/unified.png";
import sap_ariba2 from "./assets/SAP/collaboration.png";
import sap_ariba3 from "./assets/SAP/insights.png";
import sap_ariba4 from "./assets/SAP/cloud.png";
import sap_ariba5 from "./assets/SAP/cost eff.png";
import sap_ariba6 from "./assets/SAP/data_driven.png";
import sap_ariba7 from "./assets/SAP/supplier.png";
import sap_ariba9 from "./assets/SN_icon (8).png";
import sap_ariba10 from "./assets/SN_icon (3).png";
import sap_ariba11 from "./assets/SN_icon (7).png";
import sap_ariba12 from "./assets/SN_icon (1).png";
import sap_ariba13 from "./assets/SalesForce Page/SF_icon (4).png";
import sap_ariba_logo from "./assets/SAP/SAP logo.png";
// import sap_banner from "./assets/SAP/SAP Banner.png";
import sap_banner from "./assets/SAP/SAP_Banner.png";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "2.25rem",
    paddingBottom: "2.25rem",
  },
  inputbtn: {
    borderRadius: "0.5rem",
    backgroundColor: "#F0F4F3",
    height: "3rem",
    padding: "0.3rem",
    paddingLeft: "1rem",
  },
  listtxt: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  cardHeight: {
    height: "13rem",
  },
}));

export default function SAP() {
  const classes = useStyles();
  //   const myStyle = {
  //     backgroundImage:
  //       `url(${service_now})`,
  //     // height: "100vh",
  //     // fontSize: "50px",
  //     backgroundSize: "100%",
  //     // backgroundHeight: "60%",
  //     // backgroundRepeat: "no-repeat",
  //   };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1500px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1500px)" });

  return (
    <div>
      <>
        <Grid container justifyContent="center" xs={12} sm={12} md={12} lg={12}>
          {isDesktopOrLaptop && (
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundImage: `url(${sap_banner})`,
                backgroundSize: "100%",
                // backgroundColor: "#011844",
                backgroundRepeat: "no-repeat",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

              <Grid item xs={10} sm={10} md={10} lg={10}>
                <Grid
                  container
                  justifyContent="left"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    style={{ marginTop: "13rem", paddingBottom: "3rem" }}
                  >
                    <Typography
                      align="left"
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "40px",
                        lineHeight: "2.8rem",
                        paddingBottom: "1.5rem",
                      }}
                    >
                      Revitalize Your Retail Business with Our SAP Ariba
                      Deployment
                    </Typography>{" "}
                    <Typography
                      align="left"
                      style={{
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      Our SAP Ariba implementation is designed to transform your
                      retail supply chain operations by leveraging the power of
                      a unified procurement platform. With SAP Ariba, you can
                      streamline your procurement processes, enhance supplier
                      collaboration, and gain real-time insights into your
                      supply chain. <br /> <br /> This ensures a seamless and
                      efficient operation, reducing costs and fostering
                      innovation. By integrating SAP Ariba's robust solutions,
                      you can achieve a competitive edge in today's dynamic
                      market. Choose Cyntra's expertise for a seamless SAP Ariba
                      implementation and redefine your retail procurement
                      journey.
                    </Typography>{" "}
                    <Grid
                      item
                      align="left"
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={{ padding: "2rem" }}
                    >
                      <Button
                        href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                        style={{
                          color: "white",
                          backgroundColor: "#3F9AF3",
                          fontWeight: "bold",
                          borderRadius: "10rem",
                          padding: "0.7rem",
                        }}
                      >
                        Schedule a call
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            </Grid>
          )}

          {isTabletOrMobile && (
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#011844",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

              <Grid item xs={10} sm={10} md={10} lg={10}>
                <Grid container xs={12} sm={12} md={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} lg={12} align="right">
                    <img
                      src={sap_ariba_logo}
                      style={{ width: "30%", paddingTop: "5rem" }}
                      align="right"
                    ></img>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid
                      container
                      justifyContent="left"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginTop: "1rem", paddingBottom: "3rem" }}
                      >
                        <Typography
                          align="left"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "40px",
                            lineHeight: "2.8rem",
                            paddingBottom: "1.5rem",
                          }}
                        >
                          Revitalize Your Retail Business with Our SAP Ariba
                          Deployment
                        </Typography>{" "}
                        <Typography
                          align="left"
                          style={{
                            color: "white",
                            fontSize: "15px",
                          }}
                        >
                          Our SAP Ariba implementation is designed to transform
                          your retail supply chain operations by leveraging the
                          power of a unified procurement platform. With SAP
                          Ariba, you can streamline your procurement processes,
                          enhance supplier collaboration, and gain real-time
                          insights into your supply chain. <br /> <br /> This
                          ensures a seamless and efficient operation, reducing
                          costs and fostering innovation. By integrating SAP
                          Ariba's robust solutions, you can achieve a
                          competitive edge in today's dynamic market. Choose
                          Cyntra's expertise for a seamless SAP Ariba
                          implementation and redefine your retail procurement
                          journey.
                        </Typography>{" "}
                        <Grid
                          item
                          align="left"
                          xs={10}
                          sm={10}
                          md={6}
                          lg={6}
                          style={{ padding: "2rem" }}
                        >
                          <Button
                            href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                            style={{
                              color: "white",
                              backgroundColor: "#3F9AF3",
                              fontWeight: "bold",
                              borderRadius: "10rem",
                              padding: "0.7rem",
                            }}
                          >
                            Schedule a call
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            </Grid>
          )}

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingTop: "4rem" }}
          >
            <Grid
              item
              justifyContent="center"
              xs={1}
              sm={1}
              md={1}
              lg={1}
            ></Grid>

            <Grid item justifyContent="center" xs={10} sm={10} md={10} lg={10}>
              <Typography
                style={{
                  paddingBottom: "2rem",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                }}
              >
                Discover the Benefits of Our SAP Ariba Implementation{" "}
              </Typography>
            </Grid>
            <Grid
              item
              justifyContent="center"
              xs={1}
              sm={1}
              md={1}
              lg={1}
            ></Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingBottom: "5rem" }}
          >
            <Grid
              item
              justifyContent="center"
              xs={12}
              sm={12}
              md={1}
              lg={1}
            ></Grid>
            <Grid item justifyContent="center" xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid
                  item
                  justifyContent="center"
                  style={{ padding: "1rem" }}
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={sap_ariba1} align="left"></img>
                    <br />
                    <br />
                    <Typography
                      align="left"
                      style={{
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      Unified Procurement Platform{" "}
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.8rem",
                      }}
                    >
                      SAP Ariba offers a comprehensive procurement solution,
                      centralizing sourcing, procurement, and contract
                      management, ensuring a seamless flow of information and
                      goods.
                    </Typography>{" "}
                  </>
                </Grid>
                <Grid
                  item
                  style={{ padding: "1rem" }}
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={sap_ariba2} align="left"></img> <br /> <br />
                    <Typography
                      align="left"
                      style={{
                        // paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      Supplier Collaboration
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.8rem",
                      }}
                    >
                      Enhance supplier relationships and collaboration with SAP
                      Ariba's supplier management solutions, ensuring timely
                      deliveries and quality assurance.
                    </Typography>{" "}
                  </>
                </Grid>
                <Grid
                  item
                  style={{ padding: "1rem" }}
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={sap_ariba3} align="left"></img> <br /> <br />
                    <Typography
                      align="left"
                      style={{
                        // paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      Real-time Insights
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.8rem",
                      }}
                    >
                      Harness the power of SAP Ariba's analytics to gain
                      insights into procurement trends, supplier performance,
                      and spend analysis.
                    </Typography>{" "}
                  </>
                </Grid>
                <Grid
                  item
                  style={{ padding: "1rem" }}
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={sap_ariba4} align="left"></img> <br /> <br />
                    <Typography
                      align="left"
                      style={{
                        // paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      Cloud-Based Solutions
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.8rem",
                      }}
                    >
                      With SAP Ariba's cloud-based solutions, retailers can
                      access the platform anytime, anywhere, ensuring agility
                      and scalability in their operations.
                    </Typography>{" "}
                  </>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              justifyContent="center"
              xs={12}
              sm={12}
              md={1}
              lg={1}
            ></Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingTop: "3rem", backgroundColor: "#EBF5FF" }}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="left"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    align="left"
                    style={{
                      paddingTop: "2rem",
                      paddingBottom: "2rem",
                      color: "#3F9AF3",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    COST EFFICIENCY
                  </Typography>

                  <Typography
                    align="left"
                    style={{
                      paddingBottom: "5rem",
                      color: "black",
                      fontSize: "1rem",
                    }}
                  >
                    With our SAP Ariba implementation, you’ll be able to
                    streamline procurements, cut costs and reduce redundancies.
                    Automated processes will cut down manual tasks, speeding up
                    your decision-making and minimizing mistakes. Its cloud
                    foundation significantly lowers IT expenses and offers
                    scalability to match business growth.
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={10} md={6} lg={6}>
                  <img
                    style={{ padding: "4rem", width: "100%" }}
                    src={sap_ariba5}
                  ></img>
                </Grid>{" "}
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="left"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid item xs={12} sm={12} md={7} lg={6}>
                  <img
                    style={{ padding: "4rem", width: "100%" }}
                    src={sap_ariba6}
                  ></img>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={6}>
                  <Typography
                    align="left"
                    style={{
                      paddingTop: "3.5rem",
                      paddingBottom: "2rem",
                      color: "#3F9AF3",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    DATA-DRIVEN PROCUREMENT{" "}
                  </Typography>

                  <Typography
                    align="left"
                    style={{
                      paddingBottom: "3.5rem",
                      color: "black",
                      fontSize: "1rem",
                    }}
                  >
                    SAP Ariba empowers retailers with data-driven insights,
                    enabling them to make informed procurement decisions. From
                    supplier performance to expenditure analysis, SAP Ariba's
                    analytics provide actionable intelligence, guiding retailers
                    in optimizing their procurement strategies and ensuring
                    maximum return on investments(ROIs).
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingBottom: "2rem", backgroundColor: "#EBF5FF" }}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="left"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    align="left"
                    style={{
                      paddingTop: "3.5rem",
                      paddingBottom: "2rem",
                      color: "#3F9AF3",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    {" "}
                    SEAMLESS SUPPLIER COLLABORATION
                  </Typography>

                  <Typography
                    align="left"
                    style={{
                      paddingBottom: "3.5rem",
                      color: "black",
                      fontSize: "1rem",
                    }}
                  >
                    SAP Ariba's supplier collaboration tools ensure that
                    retailers can seamlessly communicate with their suppliers,
                    promising timely deliveries, quality checks, and efficient
                    dispute resolution. This not only reorganizes operations for
                    good, but also fosters strong supplier relationships, which
                    is crucial for long-term success.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <img
                    style={{ padding: "4rem", width: "100%" }}
                    src={sap_ariba7}
                  ></img>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>

          <Grid
            container
            justifyContent="left"
            style={{
              backgroundColor: "#3F9AF3",
              paddingTop: "2.5rem",
              paddingBottom: "3rem",
            }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            <Grid item xs={10} sm={10} md={7} lg={7}>
              <Typography
                align="left"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "2rem",
                }}
              >
                Transform Your Procurement Journey
              </Typography>

              <Typography
                align="left"
                style={{
                  color: "white",
                  fontSize: "1rem",
                }}
              >
                Our SAP Ariba implementation is tailored to meet the unique
                needs of retailers, ensuring efficiency, cost savings, and
                innovation.
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={3}
              lg={3}
              style={{ paddingTop: "1rem" }}
            >
              <Button
                href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                style={{
                  color: "#3F9AF3",
                  backgroundColor: "white",
                  borderRadius: "10rem",
                  fontWeight: "bold",
                  padding: "0.5rem",
                }}
              >
                Schedule a call
              </Button>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="left"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    align="center"
                    style={{
                      paddingTop: "4rem",
                      paddingBottom: "2rem",
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    Why Cyntra for SAP Ariba Implementation?
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ paddingBottom: "4rem" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    style={{ padding: "1rem" }}
                  >
                    <>
                      <img src={sap_ariba10} align="left"></img>
                      <br />
                      <br />
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        Cost-Efficient{" "}
                      </Typography>
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontSize: "0.8rem",
                        }}
                      >
                        With Cyntra, you can ensure a swift and error-free SAP
                        Ariba implementation, leading to reduced overheads and a
                        faster ROIs.
                      </Typography>{" "}
                    </>
                  </Grid>
                  <Grid
                    item
                    justifyContent="center"
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    style={{ padding: "1rem" }}
                  >
                    <>
                      <img src={sap_ariba11} align="left"></img>
                      <br />
                      <br />
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        Expertise & Experience{" "}
                      </Typography>
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontSize: "0.8rem",
                        }}
                      >
                        Cyntra's team of SAP Ariba professionals brings years of
                        hands-on experience, ensuring a seamless implementation
                        tailored to your needs.
                      </Typography>{" "}
                    </>
                  </Grid>
                  <Grid
                    item
                    justifyContent="center"
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    style={{ padding: "1rem" }}
                  >
                    <>
                      <img src={sap_ariba13} align="left"></img>
                      <br />
                      <br />
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        Seamless Integration{" "}
                      </Typography>
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontSize: "0.8rem",
                        }}
                      >
                        Integrate SAP Ariba effortlessly with your existing
                        systems with Cyntra, safeguarding smooth data flow and
                        enhanced procurement operations.
                      </Typography>{" "}
                    </>
                  </Grid>
                  <Grid
                    item
                    justifyContent="center"
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    style={{ padding: "1rem" }}
                  >
                    <>
                      <img src={sap_ariba12} align="left"></img>
                      <br />
                      <br />
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        Continuous Support{" "}
                      </Typography>
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontSize: "0.8rem",
                        }}
                      >
                        Our commitment extends beyond just implementation. We
                        offer ongoing support, training, and updates to ensure
                        your SAP Ariba platform remains at the forefront of
                        procurement innovation.
                      </Typography>{" "}
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>
        </Grid>
      </>
    </div>
  );
}
