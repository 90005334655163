import React from "react";
import {
  Grid,
  Card,
  Modal,
  makeStyles,
  Typography,
  Button,
  Input,
} from "@material-ui/core";
import emailjs from "@emailjs/browser";
import { useMediaQuery } from "react-responsive";
import DraggableCore from "react-draggable";
import file from "../Component/assets/CyntraBooklet.pdf";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "30%",
    left: "5%",
    right: "5%",
    transform: "translate(0%, -20%)",

    justifyContent: "center",
    alignItems: "center",
  },
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "10rem",
  },
  inputbtn: {
    padding: "0.5rem",
    border: "1px solid black",
    paddingLeft: "1.5rem",
    borderRadius: "2pc",
  },
}));

export default function MobileSpecificModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [emailID, setEmailId] = React.useState("");
  const [validMail, setvalidMail] = React.useState("true");

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  React.useEffect(() => {
   setOpen(true)
  }, []);

  const EmailContactForm = () => {
    console.log(emailID);
    const validEmail = emailRegex.test(emailID);

    console.log(validEmail);
    if (validEmail) {
      setvalidMail(() => true);
      const templateParams = {
        from: emailID,
      };
      emailjs
        .send(
          "service_0vop8mg",
          "template_ozmz287",
          templateParams,
          "qpzmVRhJ-4BJfAZKr"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
        // window.open(
        //   "https://cyntralabs.com/thankyou?utm_source=Likedin+ad_thankyou&utm_medium=social+media&utm_campaign=Linkedin+1st+ad+",
        //   "_blank"
        // );

        fetch(file).then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
      
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = "Cyntra_Booklet.pdf";
      
            alink.click();
          });
        });


        setOpen(()=>false)
    } else {
      setvalidMail(() => false);
    }
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const tablet = !isTabletOrMobile ? true : false;

  const emailValueHandler = (e) => {
    setEmailId(() => e.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const  onStart = () => {
    console.log('here');
  }
  return (
    <DraggableCore onDrag={onStart} >
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card className={classes.modal}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                color: "white",
              }}
            >
              <Grid
                container
                lg={12}
                xs={12}
                sm={12}
                md={12}
      
              >
                
                <Grid item lg={12} xs={12} sm={12} md={12}>
                  <Button
                    onClick={handleClose}
                    style={{
                      background: "transparent",
                      border: "transparent",
                float:'right',
                      color: "black",
                      
                    }}
                  >
                  x
                  </Button>
                </Grid>
              </Grid>
              {/* <Grid item lg={12} xs={12} sm={12} md={12}>
                <img src="assets/img/Logo.png" className={classes.center} style={{marginTop:"-2rem"}} />
              </Grid> */}

            
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                style={{
                  color: "#AFB1B9",
                  backgroundColor: "white",
                padding:"1rem",
                }}
              >
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    margin: "1pc",
             
                  
                  }}
                >
                  <Typography style={{ textAlign: "center", fontSize: "2.5rem" ,fontFamily:"poppins",fontWeight:"bold",color:"black"}}>
                  Hey there, we've relocated!
                  </Typography>
              
                  <Typography style={{ textAlign: "center", fontSize: "1rem"  ,color:"black"}}>
                  Visit us at our new online home, <a href="https://cyntra.ai/">https://cyntra.ai</a> 
                  </Typography>
                  <Typography style={{ textAlign: "center", fontSize: "1rem"  ,color:"black"}}>      
                  Update your bookmarks and join us on the new site!
                  </Typography>
                  
                </Grid>

                <Grid
                  container
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                     padding:"1rem",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                   
                    {/* <Grid
                      item
                      lg={7}
                      xs={12}
                      sm={12}
                      md={7}
                      style={{
                        paddingBottom: "2rem",
                        textAlign: "center",
                      }}
                    >
                      <input
                        fullWidth
                        size={40}
                        type="email"
                        style={{
                            padding: "1rem",
                          border: "1px solid black",
                          fontSize: "0.8rem",
                          paddingLeft: "1.5rem",
                        }}
                        onChange={(e) => emailValueHandler(e)}
                        placeholder="Enter your email here…"
                        className={classes.inputbtn}
                      />
                      {!validMail && (
                        <text style={{ fontSize: "0.7rem", color: "red" }}>
                          Please enter a valid email address.
                        </text>
                      )}
                    </Grid> */}
                    <Grid item lg={6} xs={6} sm={6} md={6}  >
                      <Button
                        fullWidth
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href='https://cyntra.ai/';
                            }}
                        style={{
                            padding: "1rem",
                          // border: "1px solid black",
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                          textAlign:"center",
                      textTransform:"none",
                          color: "white",
                          fontFamily:"Poppins",
                            backgroundColor: "#3AA1F4",
                          alignContent:"center",
                          borderRadius: "2rem",
                        }}
                      >
                        Take me there
                      </Button>
                    </Grid>
                 
                
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Modal>
    </DraggableCore>
  );
}
