import React from "react";
import {
  Card,
  Grid,
  List,
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormGroup,
} from "@material-ui/core";

import { Input, Button } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Footer from "../Include/Footer";
import salesforce1 from "./assets/SalesForce Page/cost.png";
import salesforce2 from "./assets/SalesForce Page/data.png";
import salesforce3 from "./assets/SalesForce Page/realtime.png";
import salesforce from "./assets/SalesForce Page/SF_banner.png";
import salesforce4 from "./assets/SalesForce Page/SF_icon (2).png";
import salesforce5 from "./assets/SalesForce Page/SF_icon (8).png";
import salesforce6 from "./assets/SalesForce Page/SF_icon (6).png";
import salesforce7 from "./assets/SalesForce Page/SF_icon (5).png";
import salesforce8 from "./assets/SalesForce Page/SF_icon (3).png";
import salesforce9 from "./assets/SalesForce Page/SF_icon (7).png";
import salesforce10 from "./assets/SalesForce Page/SF_icon (4).png";
import salesforce11 from "./assets/SalesForce Page/SF_icon (1).png";
import salesforce_logo from "./assets/salesforce_logo.png"

const useStyles = makeStyles((theme) => ({
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "2.25rem",
    paddingBottom: "2.25rem",
  },
  inputbtn: {
    borderRadius: "0.5rem",
    backgroundColor: "#F0F4F3",
    height: "3rem",
    padding: "0.3rem",
    paddingLeft: "1rem",
  },
  listtxt: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  cardHeight: {
    height: "13rem",
  },
}));

export default function Salesforce() {
  const classes = useStyles();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1500px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1500px)" });

  return (
    <div>
      <>
        <Grid container justifyContent="center" xs={12} sm={12} md={12} lg={12}>
          {/* {isDesktopOrLaptop && (
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundImage: `url(${salesforce})`,
                backgroundColor: "#011844",
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

              <Grid item xs={10} sm={10} md={10} lg={10}>
                <Grid
                  container
                  justifyContent="left"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    style={{ marginTop: "13rem", paddingBottom: "3rem" }}
                  >
                    <Typography
                      align="left"
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "40px",
                        lineHeight: "2.8rem",
                        paddingBottom: "1.5rem",
                      }}
                    >
                      Empower Your Retail Business With Our Salesforce
                      Implementation
                    </Typography>{" "}
                    <Typography
                      align="left"
                      style={{
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      Our Salesforce implementation will transform your retail
                      operations by focusing on the modern consumer. Through the
                      integration of AI, Data, and CRM, it delivers a consistent
                      and personalized experience across all retail channels.
                      Retailers will get the advantage of a unified platform
                      that bolsters customer loyalty while reducing operational
                      costs. <br /> <br /> Additionally, Salesforce helps manage
                      media networks, enhancing advertising revenue and
                      providing valuable insights from first-party data. So, opt
                      for Cyntra's Salesforce implementation now and elevate
                      your retail game.
                    </Typography>{" "}
                    <Grid
                      item
                      align="left"
                      xs={10}
                      sm={10}
                      md={6}
                      lg={6}
                      style={{ padding: "2rem" }}
                    >
                      <Button
                        href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                        style={{
                          color: "white",
                          backgroundColor: "#3F9AF3",
                          fontWeight: "bold",
                          borderRadius: "10rem",
                          padding: "0.7rem",
                        }}
                      >
                        Schedule a call
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            </Grid>
          )} */}
          {/* {isTabletOrMobile && ( */}
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#011844",
                paddingTop:"10rem"
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
              
              <Grid item xs={10} sm={10} md={10} lg={10}>
              <Grid container xs={12} sm={12} md={12} lg={12}>

              <Grid item xs={12} sm={12} md={8} lg={8}>

<Grid
  container
  justifyContent="left"
  xs={12}
  sm={12}
  md={12}
  lg={12}
>
  <Grid
    item
    xs={12}
    sm={12}
    md={12}
    lg={12}
    style={{ marginTop: "1rem", paddingBottom: "3rem" }}
  >
    <Typography
      align="left"
      style={{
        color: "white",
        fontWeight: "bold",
        fontSize: "40px",
        lineHeight: "2.8rem",
        paddingBottom: "1.5rem",
      }}
    >
      Empower Your Retail Business With Our Salesforce
      Implementation
    </Typography>{" "}
    <Typography
      align="left"
      style={{
        color: "white",
        fontSize: "15px",
      }}
    >
      Our Salesforce implementation will transform your retail
      operations by focusing on the modern consumer. Through the
      integration of AI, Data, and CRM, it delivers a consistent
      and personalized experience across all retail channels.
      Retailers will get the advantage of a unified platform
      that bolsters customer loyalty while reducing operational
      costs. <br /> <br /> Additionally, Salesforce helps manage
      media networks, enhancing advertising revenue and
      providing valuable insights from first-party data. So, opt
      for Cyntra's Salesforce implementation now and elevate
      your retail game.
    </Typography>{" "}
    <Grid
      item
      align="left"
      xs={10}
      sm={10}
      md={6}
      lg={6}
      style={{ paddingTop: "2rem" }}
    >
      <Button
        href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
        style={{
          color: "white",
          backgroundColor: "#3F9AF3",
          fontWeight: "bold",
          borderRadius: "10rem",
          padding: "0.7rem",
        }}
      >
        Schedule a call
      </Button>
    </Grid>
  </Grid>
</Grid>
</Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} align="right">
              <img src={salesforce_logo} style={{width:"80%", marginTop:"3rem",marginBottom:"2rem"}} align="right"></img>
              </Grid>

            


              </Grid>

              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            </Grid>
          {/* )} */}

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingTop: "4rem" }}
          >
            <Grid
              item
              justifyContent="center"
              xs={1}
              sm={1}
              md={1}
              lg={1}
            ></Grid>

            <Grid item justifyContent="center" xs={10} sm={10} md={10} lg={10}>
              <Typography
                style={{
                  paddingBottom: "2rem",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                }}
              >
                The Many Perks of Our Salesforce Implementation
              </Typography>
            </Grid>
            <Grid
              item
              justifyContent="center"
              xs={1}
              sm={1}
              md={1}
              lg={1}
            ></Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingBottom: "5rem" }}
          >
            <Grid
              item
              justifyContent="center"
              xs={12}
              sm={12}
              md={1}
              lg={1}
            ></Grid>
            <Grid item justifyContent="center" xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid
                  item
                  justifyContent="center"
                  style={{ padding: "1rem" }}
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={salesforce4} align="left"></img>
                    <br />
                    <br />
                    <Typography
                      align="left"
                      style={{
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      Retail Media Network Management
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.8rem",
                      }}
                    >
                      Salesforce provides a connected platform for retailers to
                      manage their media networks, aiming to boost advertising
                      revenue, scale media operations, and enhance campaign
                      performance.
                    </Typography>{" "}
                  </>
                </Grid>
                <Grid
                  item
                  style={{ padding: "1rem" }}
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={salesforce5} align="left"></img> <br /> <br />
                    <Typography
                      align="left"
                      style={{
                        // paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      Centralized Customer Experience
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.8rem",
                      }}
                    >
                      Our Salesforce implementation integrates AI, Data, and CRM
                      to place shoppers at the core of every experience,
                      ensuring personalized interactions throughout the shopping
                      journey.
                    </Typography>{" "}
                  </>
                </Grid>
                <Grid
                  item
                  style={{ padding: "1rem" }}
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={salesforce6} align="left"></img> <br /> <br />
                    <Typography
                      align="left"
                      style={{
                        // paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      Enhanced Customer Loyalty
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.8rem",
                      }}
                    >
                      Salesforce's solutions are designed to foster and increase
                      customer loyalty, ensuring repeat business and long-term
                      customer relationships.
                    </Typography>{" "}
                  </>
                </Grid>
                <Grid
                  item
                  style={{ padding: "1rem" }}
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={salesforce7} align="left"></img> <br /> <br />
                    <Typography
                      align="left"
                      style={{
                        // paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                    >
                      Unified Engagement Platform
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.8rem",
                      }}
                    >
                      Salesforce offers a single platform that transforms
                      engagement dynamics with shoppers, stores, and employees,
                      ensuring consistent and enhanced interactions.
                    </Typography>{" "}
                  </>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              justifyContent="center"
              xs={12}
              sm={12}
              md={1}
              lg={1}
            ></Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingTop: "3rem", backgroundColor: "#EBF5FF" }}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="left"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    align="left"
                    style={{
                      paddingTop: "2rem",
                      paddingBottom: "2rem",
                      color: "#3F9AF3",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    COST EFFICIENCY
                  </Typography>

                  <Typography
                    align="left"
                    style={{
                      paddingBottom: "5rem",
                      color: "black",
                      fontSize: "1rem",
                    }}
                  >
                    By centralizing customer data, sales analytics, and
                    marketing campaigns, businesses can eliminate redundancies
                    and improve resource allocation through our Salesforce
                    implementation. Automated workflows facilitated by
                    Salesforce reduce manual intervention, thereby minimizing
                    errors and saving valuable man-hours. <br />
                    Additionally, the cloud-based infrastructure means reduced
                    IT costs as there's no need for heavy hardware investments
                    or constant software updates. The platform's scalability
                    ensures that as your business grows, Salesforce can adapt
                    without adding significant additional costs.
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={10} md={6} lg={6}>
                  <img
                    style={{ padding: "4rem", width: "100%" }}
                    src={salesforce1}
                  ></img>
                </Grid>{" "}
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="left"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid item xs={10} sm={10} md={6} lg={6}>
                  <img
                    style={{ padding: "4rem", width: "100%" }}
                    src={salesforce2}
                  ></img>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    align="left"
                    style={{
                      paddingTop: "3.5rem",
                      paddingBottom: "2rem",
                      color: "#3F9AF3",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    DATA-DRIVEN INSIGHTS
                  </Typography>

                  <Typography
                    align="left"
                    style={{
                      paddingBottom: "3.5rem",
                      color: "black",
                      fontSize: "1rem",
                    }}
                  >
                    Our Salesforce integration empowers retailers to unlock the
                    true value of customer data. Through Salesforce
                    implementation, deep insights into customer behaviors and
                    preferences can be extracted. Using sophisticated analytics
                    on such data, retailers derive actionable intelligence to
                    guide strategies, from spotlighting top products to
                    discerning seasonal shifts. <br />
                    Salesforce’s capability to segment audiences using real-time
                    data enhances marketing precision, thus boosting return on
                    investment. Essentially, Salesforce will turn your customer
                    data into a strategic tool, enabling retailers to make
                    resonant, outcome-optimizing decisions.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingBottom: "2rem", backgroundColor: "#EBF5FF" }}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="left"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    align="left"
                    style={{
                      paddingTop: "3.5rem",
                      paddingBottom: "2rem",
                      color: "#3F9AF3",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    REAL-TIME ENGAGEMENT
                  </Typography>

                  <Typography
                    align="left"
                    style={{
                      paddingBottom: "3.5rem",
                      color: "black",
                      fontSize: "1rem",
                    }}
                  >
                    The implementation of Salesforce integration offers
                    real-time capabilities, giving businesses instant access to
                    up-to-date data, facilitating prompt decision-making. This
                    cloud-based platform provides immediate insights into
                    customer interactions, enabling a swift response to evolving
                    preferences.
                    <br />
                    Your team can collaborate seamlessly with live updates,
                    ensuring alignment. Automated alerts notify stakeholders of
                    crucial events instantly. This immediacy ensures businesses
                    remain agile, making timely, data-driven decisions,
                    enhancing efficiency, and staying ahead in dynamic market
                    conditions.
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={10} md={6} lg={6}>
                  <img
                    style={{ padding: "4rem", width: "100%" }}
                    src={salesforce3}
                  ></img>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>

          <Grid
            container
            justifyContent="left"
            style={{
              backgroundColor: "#3F9AF3",
              paddingTop: "2.5rem",
              paddingBottom: "3rem",
            }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            <Grid item xs={10} sm={10} md={7} lg={7}>
              <Typography
                align="left"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "2rem",
                }}
              >
                Accelerate Your Business Growth{" "}
              </Typography>

              <Typography
                align="left"
                style={{
                  color: "white",
                  fontSize: "1rem",
                }}
              >
                Our seamless Salesforce implementation perfectly meets the
                unique needs of businesses of all sizes.
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={3}
              lg={3}
              style={{ paddingTop: "1rem" }}
            >
              <Button
                href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                style={{
                  color: "#3F9AF3",
                  backgroundColor: "white",
                  borderRadius: "10rem",
                  fontWeight: "bold",
                  padding: "0.5rem",
                }}
              >
                Schedule a call
              </Button>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="left"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    align="center"
                    style={{
                      paddingTop: "4rem",
                      paddingBottom: "2rem",
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    Why Cyntra for salesforce Implementation?{" "}
                  </Typography>
                  <Grid
                    container
                    justifyContent="center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ paddingBottom: "4rem" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      style={{ padding: "1rem" }}
                    >
                      <>
                        <img src={salesforce8} align="left"></img> <br /> <br />
                        <Typography
                          align="left"
                          style={{
                            paddingBottom: "2rem",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          Cost-Efficient{" "}
                        </Typography>
                        <Typography
                          align="left"
                          style={{
                            paddingBottom: "2rem",
                            color: "black",
                            fontSize: "0.8rem",
                          }}
                        >
                          Leveraging Cyntra's expertise ensures a faster,
                          error-free implementation, reducing overheads and
                          ensuring a quicker return on investment.
                        </Typography>{" "}
                      </>
                    </Grid>
                    <Grid
                      item
                      justifyContent="center"
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      style={{ padding: "1rem" }}
                    >
                      <>
                        <img src={salesforce9} align="left"></img> <br /> <br />
                        <Typography
                          align="left"
                          style={{
                            paddingBottom: "2rem",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          Expertise & Experience{" "}
                        </Typography>
                        <Typography
                          align="left"
                          style={{
                            paddingBottom: "2rem",
                            color: "black",
                            fontSize: "0.8rem",
                          }}
                        >
                          Cyntra boasts a team of certified salesforce
                          professionals who bring years of hands-on experience
                          in diverse industries.{" "}
                        </Typography>{" "}
                      </>
                    </Grid>
                    <Grid
                      item
                      justifyContent="center"
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      style={{ padding: "1rem" }}
                    >
                      <>
                        <img src={salesforce10} align="left"></img> <br />{" "}
                        <br />
                        <Typography
                          align="left"
                          style={{
                            paddingBottom: "2rem",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          Seamless Integration{" "}
                        </Typography>
                        <Typography
                          align="left"
                          style={{
                            paddingBottom: "2rem",
                            color: "black",
                            fontSize: "0.8rem",
                          }}
                        >
                          With Cyntra, you can integrate salesforce effortlessly
                          with your existing systems, ensuring smooth data flow
                          and enhanced functionality.
                        </Typography>{" "}
                      </>
                    </Grid>
                    <Grid
                      item
                      justifyContent="center"
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      style={{ padding: "1rem" }}
                    >
                      <>
                        <img src={salesforce11} align="left"></img> <br />{" "}
                        <br />
                        <Typography
                          align="left"
                          style={{
                            paddingBottom: "2rem",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "1rem",
                          }}
                        >
                          Continuous Support{" "}
                        </Typography>
                        <Typography
                          align="left"
                          style={{
                            paddingBottom: "2rem",
                            color: "black",
                            fontSize: "0.8rem",
                          }}
                        >
                          Our commitment doesn't end post-implementation. We
                          offer ongoing support, training, and updates to ensure
                          your salesforce platform remains cutting-edge.
                        </Typography>{" "}
                      </>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>
        </Grid>
      </>
    </div>
  );
}
