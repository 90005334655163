import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import "./style.css";

import ReactPlayer from "react-player";

import Box from "@mui/material/Box";
import src4 from "../Component/assets/SwyftmPOS.mp4";
import Paper from "@mui/material/Paper";
import { ReactVideo } from "reactjs-media";
import { Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { NavLink } from "react-router-dom";

import { useState } from "react";



const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.9rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};

const Swyft = () => {


  return (
    <>
      <>
        <header id="header" className="fixed-top ">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div
                className="col-xl-9 col-sm-12 d-flex align-items-center justify-content-between"
                style={{ minWidth: "92%" }}
              >
                <nav className="nav-menu d-lg-block anrsb">
                  <div
                    className="showInMobile"
                    style={{ padding: "0px 0px 20px 0px", borderBottom: "0px" }}
                  >
                    <img
                      src="assets/img/Logo.png"
                      alt=""
                      className="header-logo img-fluid"
                      style={{ borderBottom: "0px" }}
                    />
                    <button
                      type="button"
                      className="mobile-nav-toggle2 d-lg-none anrsb-nav-act"
                      style={{ top: "25px" }}
                    >
                      <i
                        className="icofont-close"
                        style={{ color: "#40a9f2" }}
                      ></i>
                    </button>
                  </div>
                  <ul>
                    <li className="" style={{ marginLeft: "0px" }}>
                      <NavLink className="mob-l" exact to={"/"}>
                        Home
                      </NavLink>
                    </li>
                  </ul>
                </nav>
                <NavLink exact className="aclahl" to={"/"}>
                  <img
                    src="assets/img/Logo.png"
                    alt=""
                    className="header-logo img-fluid"
                  />
                </NavLink>
                <button
                  type="button"
                  className="mobile-nav-toggle d-lg-none showInMobile anrsb-nav-act"
                >
                  <i className="icofont-navigation-menu"></i>
                </button>
              </div>
            </div>
          </div>
        </header>
      </>
      <section
        id="retail"
        className="d-flex flex-column justify-content-center clfretabcd2 "
      >
        <div className="row justify-content-center retail">
          <Box sx={{ flexGrow: 1 }} pt={2}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={15}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                pl={5}
                pt={8}
                style={{
                  textAlign: "left",
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h3"
                    style={{
                      textAlign: "left",
                      color: "#0a1d54",
                      fontSize: "2.5rem",
                      paddingTop: "5rem",
                      fontStyle: "italic",
                      fontFamily: "sans-serif",
                      fontWeight: "900",
                    }}
                  >
                    Swyft
                  </Typography>
                </ThemeProvider>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    fontSize: "2rem",
                    color: "black",
                    textAlign: "left",
                    fontFamily: "sans-serif",
                    fontWeight: "bolder",
                    paddingTop: "4rem",
                  }}
                >
                  The only retail &nbsp;{" "}
                  <span style={{ color: "#38a1f7" }}> mPOS </span>system you'll
                  ever need
                </Grid>
                <div className="ABCD!">
                  <h4
                    style={{
                      fontSize: "1.1rem",
                      marginTop: "2rem",
                      color: "#a1a1a1",
                      textAlign: "left",
                      fontFamily: " sans-serif",
                      fontWeight: "500",
                    }}
                  >
                    Simple,low-cost acceptance solution to
                    <br />
                    drive best-in-class customer experience.
                    <br />
                  </h4>
                </div>

                <Button
                  href="#Demo12"
                  className="back1"
                  variant="contained"
                
                  style={{
                    color: "white",
                    fontFamily: "sans-serif",
                    fontSize: "1rem",
                    backgroundColor: "#38a1f7",
                    paddingLeft: "0.9",
                    paddingRight: "0.9",
                    paddingTop: "0.6",
                    paddingBottom: "0.6",
                    marginTop: "1rem",
                    borderRadius: "5rem",
                  }}
                >
                  Demo
                </Button>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    fontFamily: "sans-serif",
                    fontSize: "1rem",
                    backgroundColor: "#38a1f7",
                    paddingLeft: "0.9",
                    paddingRight: "0.9",
                    paddingTop: "0.6",
                    paddingBottom: "0.6",
                    marginTop: "1rem",
                    marginLeft: "1rem",
                    borderRadius: "5rem",
                  }}
                >
                  Contact US
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <img height={700} width={450} src="/assets/mPOS.png" />
              </Grid>
            </Grid>
          </Box>
        </div>
      </section>
      <div id="Demo12">
        <section
          id="retail"
          className="d-flex flex-column justify-content-center clfretabcd1"
        >
          <Box sx={{ flexGrow: 1 }} pt={1} justifyContent="center">
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                textAlign: "left",
                fontSize: "3rem",
                color: "black",
                marginTop: "5rem",
                fontFamily: "sans-serif",
                fontWeight: "bold",
              }}
            >
              <p>Demo</p>
            </Grid>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                fontSize: "1rem",
                color: "#848790",
                textAlign: "center",
                fontFamily: "sans-serif",
              }}
            >
              Watch our products in action and see their full potential
              demonstration in these videos.
            </Grid>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={1}
            >
              <Grid item xs={12} sm={12} md={8} lg={8} p={2}>
                <ReactPlayer
                  className="react-player"
                  width="100%"
                  height="100%"
                  url={src4}
                  config={{
                    file: {
                      attributes: {
                        poster: "./assets/mpos_thumb.png",
                      },
                    },
                  }}
                  controls
                />
              </Grid>
            </Grid>
          </Box>
        </section>
      </div>
    </>
  );
};
export default Swyft;
