import { PureComponent } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends PureComponent {
    componentDidMount = () => window.scrollTo(0, 0);

    componentDidUpdate = prevProps => {
    
      let href = window.location.href
      if((href.substring((href).lastIndexOf('/') + 1)).split("#")[1]){
      }else{
        if (this.props.location !== prevProps.location) window.scrollTo(0, 0);
      }
   };

  render = () => this.props.children;
}

export default withRouter(ScrollToTop);