import React from "react";
import { useEffect } from "react";
import emailjs from "@emailjs/browser";

import {
  Grid,
  Card,
  Modal,
  makeStyles,
  Typography,
  Button,
  Input,
} from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import MobileSpecificModal from "./MobileSpecificModal";
import file from "../Component/assets/CyntraBooklet.pdf";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "1pc",
    backgroundColor: "#131d46",
    justifyContent: "center",
    alignItems: "center",
  },
  //   requestbutton: {
  //     // padding: "1pc",
  //     color: "white",
  //     fontSize: "1.rem",
  //     fontWeight: "bold",
  //     backgroundColor: "white",
  //     borderRadius: "0 2pc 2pc 0",
  //     // height: "3.3pc",
  //     // zIndex: "1",
  //     "&:hover": {
  //       //   backgroundColor: "#3AA1F4",
  //       //   color: "black"
  //     },
  //   },
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "10rem",
    marginBottom:"1rem"
  },
  inputbtn: {
    borderColor: "black",
    borderRadius: "2pc ",
  },
}));

export default function ModalP1(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [emailID, setEmailId] = React.useState("");
  const [validMail, setvalidMail] = React.useState("true");

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

 useEffect(() => {
   setOpen(true);
  }, []);

console.log(props.opn)

  const EmailContactForm = () => {
    const validEmail = emailRegex.test(emailID);

    console.log(validEmail);
    if (validEmail) {
      setvalidMail(() => true);
      console.log(emailID);
      const templateParams = {
        from: emailID,
      };
      emailjs
        .send(
          "service_0vop8mg",
          "template_ozmz287",
          templateParams,
          "qpzmVRhJ-4BJfAZKr"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
        // window.open(
        //   "https://cyntralabs.com/thankyou?utm_source=Likedin+ad_thankyou&utm_medium=social+media&utm_campaign=Linkedin+1st+ad+",
        //   "_blank"
        // );

  fetch(file).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);

      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "Cyntra_Booklet.pdf";

      alink.click();
    });
  });

        setOpen(()=>false)
    } else {
      setvalidMail(() => false);
    }
  };

  const emailValueHandler = (e) => {
    setEmailId(() => e.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <Grid>
      {isDesktopOrLaptop && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card className={classes.modal}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                color: "white",
              }}
            >
              <Grid
                container
                lg={12}
                xs={12}
                sm={12}
                md={12}
                style={{ margin: "0.5pc" }}
              >
                <Grid item lg={11} xs={11} sm={11} md={11}></Grid>
                <Grid item lg={1} xs={1} sm={1} md={1}>
                  <button
                    onClick={handleClose}
                    style={{
                      background: "transparent",
                      border: "transparent",
                      paddingLeft: "2.5pc",
                      paddingRight:"2pc",
                      color: "white",
                    }}
                  >
                    <span aria-hidden="true">X</span>
                  </button>
                </Grid>
                <Grid item lg={12} xs={12} sm={12} md={12}>
                <img src="assets/img/Logo.png" className={classes.center} />
              </Grid>
              </Grid>
             


              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                style={{
                  color: "black",
                  backgroundColor: "white",
                  paddingBottom: "1pc",
                }}
              >
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    margin: "1pc",
                    paddingTop: "1pc",
                    paddingBottom: "1pc",
                  }}
                >
                  <Typography style={{ textAlign: "center", fontSize: "1rem" }}>
                    Find out how we can make a difference in improving 
                  </Typography>
                  <Typography style={{ textAlign: "center", fontSize: "1rem" }}>
                  your customers' journey
                  </Typography>
                </Grid>

                <Grid
                  container
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    // paddingBottom: "0.3rem",
                    textAlign: "center",
                  }}
                >
                  <Grid item lg={3} xs={3} sm={3} md={3}></Grid>

                  <Grid
                    container
                    lg={6}
                    xs={6}
                    sm={6}
                    md={6}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Grid
                      item
                      lg={12}
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        paddingBottom: "0.8rem",
                        textAlign: "center",
                      }}
                    >
                      <input
                        fullWidth
                        size={40}
                        type="email"
                        style={{
                            padding: "0.95rem",
                          border: "1px solid black",
                          fontSize: "0.8rem",
                          paddingLeft: "1.5rem",
                        }}
                        onChange={(e) => emailValueHandler(e)}
                        placeholder="Enter your email here…"
                        className={classes.inputbtn}
                      />
                      {!validMail && (
                       <text style={{ fontSize: "0.8rem", color: "red" }}>
                         <br/> Please enter a valid email address.
                        </text>
                      )}
                    </Grid>
                  
                  </Grid>
                  <Grid item lg={12} xs={12} sm={12} md={12}>
                      <Button
                      
                        onClick={EmailContactForm}
                        style={{
                            // padding: "0.91rem",
                          // border: "1px solid black",
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                          color: "white",
                          backgroundColor: "#3AA1F4",
                          paddingLeft: "1rem",
                          paddingRight: "1rem",
                          paddingTop: "0.6rem",
                          paddingBottom: "0.6rem",
                          borderRadius: "2rem",
                        }}
                      >
                      Download
                      </Button>
                    </Grid>
                  
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Modal>
      )}
      {isTabletOrMobile && <MobileSpecificModal></MobileSpecificModal>}{" "}
    </Grid>
  );
}
