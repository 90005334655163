import React, { Component } from 'react';
import Helmet from 'react-helmet';
import MetaTags from 'react-meta-tags';
import ModalP from "./Model";
import { Grid } from "@material-ui/core";
export default class Retail extends Component {
    render() {
        return (
            <>
             <Grid>  
      {/* <ModalP></ModalP> */}
      </Grid>
            <head>
      
            <title>Retail</title>
        
            <meta property="og:title" content="CyntraLabs(Retail)"/>
    <meta property="og:description" content="Improving the customers’ retail experience with 30 seconds checkout and technologies like RFID scanning, plug & play solutions, and face and QR code scan."/>
    <meta property="og:url" content="https://cyntralabs.com/retail"/>
    <meta property="og:image" content="https://cyntra-images.s3.ap-south-1.amazonaws.com/email-templete-img/cyntraLabs.jpeg"/>

     
        </head>
         {/*  <section className="first_section1 clfret1">
              <div className="header_section24">
                <div className="container">
                  <div className="row ">
                    <div className="col-sm-12 mainpg_containt1  animate__animated animate__slideInDown">
                      <h2 className="fsection_h1">MORE THAN RETAIL</h2>
                      <p className="fsection_p1">Innovating and transforming the retail experience better than ever.</p>
                    </div>
                  </div>
                </div>
              </div>
              </section>*/}
          
              <section id="retail" className="d-flex flex-column justify-content-center clfret2" >
                <div className="darkbgblue"></div>
               <div className="clfret3">
                  <video id="wall_video" loop="true" autoplay="autoplay" preload="none" playsinline muted>
                    <source src="assets/videos/retail.mp4" type="video/mp4" />
                  </video>
                </div> 
                <div className="layer">
                      <div className="container" style={{padding: '80px 10px'}}>
                          <div className="row justify-content-center retail">
                              <div className="col-xl-12 animate__animated animate__slideInDown">
                                  <h1 className="clfret4" style={{marginTop: '20rem'}}>MORE THAN RETAIL</h1>
                                  <p>Innovating and transforming the retail experience better than ever.</p>
                              </div>
                          </div>
                      </div>
                </div>
            </section>
          
            <main id="main">
                <section id="portfolio" className="portfolio">
                    <div className="container">
                    <div className="section-title">
                        <h2 className="text-center">RETAIL SOLUTIONS</h2>
                    </div>
                    <div className="row portfolio-container">
                        <div className="col-lg-4 col-md-4 portfolio-item filter-web animate__animated animate__fadeInLeft">
                        <img src="./assets/img/retail/connectedRetail.jpg" className="img-fluid center" alt="" />
                        <div className="Integration-head">
                            <h2 className="">CONNECTED RETAIL</h2>
                        </div>  
                        <a className="portfolio-info">
                            <h4 className="">CONNECTED RETAIL</h4>
                            <p>CYNTRA's innovative and leading Retail products and solutions enable the global companies to seamlessly improve retail execution, increase sales, and enhance profitability.</p>
                        </a>  
                        </div>
                        <div className="col-lg-4 col-md-4 portfolio-item filter-web animate__animated animate__fadeInTop">
                        <img src="./assets/img/retail/brandProtection.jpg" className="img-fluid center" alt="" />
                        <div className="Integration-head">
                            <h2 className="">BRAND PROTECTION</h2>
                        </div>  
                        <a href="integration.php" className="portfolio-info">
                            <h4 className="">BRAND PROTECTION</h4>
                            <p>CYNTRA provides bespoke solutions that help brands with 360 degrees protection; protect your investment, your reputation, avoid  counterfeits  and enhance profits with track and trace solution. We also provide an eco-system of solutions on your business that implements the latest technology to give you access to real-time data.</p>
                        </a>  
                        </div>
                        <div className="col-lg-4 col-md-4 portfolio-item filter-web animate__animated animate__fadeInRight">
                        <img src="./assets/img/retail/enhancedCX.jpg" className="img-fluid center" alt="" />
                        <div className="Integration-head">
                            <h2 className="">ENHANCED CX</h2>
                        </div>  
                        <a href="integration.php" className="portfolio-info">
                            <h4 className="">ENHANCED CX</h4>
                            <p>How companies set themselves apart in the marketplace is how consumers connect with your brand. At CYNTRA, enhancing customer experience is always the prime focus. Whether it’s pop up stores, web or app shopping experience, QR based or voice-based commerce we ensure your brand stands out.</p>
                        </a>  
                        </div>
            
                    </div>
            
                    </div>
                </section>
                </main>
          
              <section id="productsnservices" className="productsnservices portfolio">
                <div className="container">
                <div className="section-title clfret5">
                    <h2 className="text-center">PRODUCTS & SERVICES</h2>
                    {/* <p className="header_text2">Explore our wide range of products and services.
                    The experience and expertise of our product development team, backed up by the highest commitment to
                    customer service has built great trust in the CYNTRA brand through the eyes of our customer.
                    </p> */}
                  </div>
                  <div className="row portfolio-container clfret6">
                    <div className="col-lg-4 col-md-4 portfolio-item filter-web redirection32 animate__animated animate__fadeInUp col-md-12">
                      <img src="./assets/img/retail/POS.jpg" className="img-fluid center" alt="" />
                      <div className="Integration-head">
                        <h2 className="">POINT OF SALE</h2>
                      </div>
                      <a className="row retailsol_3 portfolio-info" data-target="#carouselExampleIndicators" data-slide-to="0">
                        <h4 className="">POINT OF SALE</h4>
                        <p>At CYNTRA, PoS is not just a cash register. You turn your raw sales data into more manageable and tangible purposes. Integrated with payments, our PoS system is designed to streamline the access to all of your sales information, for accounting and management use.</p><br />
                      </a>  
                    </div>
                    <div className="col-lg-4 col-md-4 portfolio-item filter-web redirection32 animate__animated animate__fadeInUp col-md-12">
                      <img src="./assets/img/retail/digitalPayments.jpg" className="img-fluid center" alt="" />
                      <div className="Integration-head">
                        <h2 className="">DIGITAL PAYMENTS</h2>
                      </div>  
                      <a className="row retailsol_3 portfolio-info" data-target="#carouselExampleIndicators" data-slide-to="1">
                        <h4 className="">DIGITAL PAYMENTS</h4>
                        <p>Integrated Payment ensures greater accuracy, time savings and no manual transactions. Reporting and reconciliation becomes smooth with all your transactions in one place. Turn relationship management, accounting, and payment processing into a streamlined process.</p><br />
                      </a>  
                    </div>
                    <div className="col-lg-4 col-md-4 portfolio-item filter-web redirection32 animate__animated animate__fadeInRight col-md-12">
                      <img src="./assets/img/retail/Kiosk.jpg" className="img-fluid center" alt="" />
                      <div className="Integration-head">
                        <h2 className="">SELF ODERING KIOSK</h2>
                      </div>  
                      <a className="row retailsol_3 portfolio-info" data-target="#carouselExampleIndicators" data-slide-to="2">
                        <h4 className="">SELF ODERING KIOSK</h4>
                        <p>Providing full fledged menu, along with payment versatility, Self Ordering Kiosks at CYNTRA make it convenient for your customers to place their order with ease. Employees can now focus more on boosting sales, enhancing profitability and improving overall operations.</p><br />
                      </a>  
                    </div>
          
                    <div className="col-lg-4 col-md-4 portfolio-item filter-web redirection32 animate__animated animate__fadeInLeft col-md-12">
                      <img src="./assets/img/retail/livReporting.jpg" className="img-fluid center" alt="" />
                      <div className="Integration-head">
                        <h2 className="">LIV REPORTING</h2>
                      </div>  
                      <a className="row retailsol_3 portfolio-info" data-target="#carouselExampleIndicators" data-slide-to="3">
                        <h4 className="">LIV REPORTING</h4>
                        <p>Our LIV Reporting app gives our customers the power of data, real-time anywhere in the world. Decisions driven by analytics help businesses to be proactive, make informed choices and thus improving overall organizational efficiency.</p><br />
                      </a>  
                    </div>
                    <div className="col-lg-4 col-md-4 portfolio-item filter-web redirection32 animate__animated animate__fadeInUp col-md-12">
                      <img src="./assets/img/retail/rfid.jpg" className="img-fluid center" alt="" />
                      <div className="Integration-head">
                        <h2 className="">SMART RFID SOLUTIONS</h2>
                      </div>  
                      <a className="row retailsol_3 portfolio-info" data-target="#carouselExampleIndicators" data-slide-to="4">
                        <h4 className="">SMART RFID SOLUTIONS</h4>
                        <p>Smart RFID enables swift and hassle-free shopping experience. It not only provides hands free operations for your warehouse activities but avoids thefts and counterfeits. Our next-gen RFID products aim to solve multiple problems cycle from  ‘Purchase Order to Point-of-Sale’.</p><br />
                      </a>  
                    </div>
                    <div className="col-lg-4 col-md-4 portfolio-item filter-web redirection32 animate__animated animate__fadeInRight col-md-12">
                      <img src="./assets/img/retail/webmobileDEV.jpg" className="img-fluid center" alt="" />
                      <div className="Integration-head">
                        <h2 className="">WEB & MOBILE DEVELOPMENT</h2>
                      </div>
                      <a className="row retailsol_3 portfolio-info" data-target="#carouselExampleIndicators" data-slide-to="5">
                        <h4 className="">WEB & MOBILE DEVELOPMENT</h4>
                        <p>Our design team is committed to giving your customer the best user experience so that they are compelled to return to your websites and mobile apps.</p><br />
                      </a>  
                    </div>
          
                  </div>
          
                </div>
              </section>
          
              
              <section className="clfret7" id="products-services" >
                <div className="container   animate__animated animate__zoomIn">
          
          
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    {/* </div><ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                    </ol> */}
                  <div className="carousel-inner" >
                    
                    <div className="carousel-item active">
                        <div className="detail_section44" id="pos_section">
                        <div  className="header_div1 header_production1">
                            <h2 className="header_text1">POINT OF SALE</h2>
                        </div>
                        <div className="row retailsol_1">
                          <div className="col-sm-6">
                              <div className="row">
                                <ul className="detail_sect23" style={{textAlign:'left'}}>
                                  <li id="pos_li1" className="addcss13 sokbtn" name="1">
                                    <img className="detail_img2" src="./assets/img/retail/icon/omniChannel.png" /> OMNI-CHANNEL
                                  </li>
                                  <li id="pos_li2" className="sokbtn" name="2">
                                    <img className="detail_img2" src="./assets/img/retail/icon/integratedPayment.png" /> INTEGRATED PAYMENTS
                                  </li>
                                  <li id="pos_li3" className="sokbtn" name="3">
                                    <img className="detail_img2" src="./assets/img/retail/icon/customerEngagement.png" /> CUSTOMER ENGAGEMENT
                                  </li>
                                  <li id="pos_li4" className="sokbtn" name="4">
                                    <img className="detail_img2" src="./assets/img/retail/icon/coupon.png" /> DIGITAL COUPONING
                                  </li>
                                  <li id="pos_li5" className="sokbtn" name="5">
                                    <img className="detail_img2" src="./assets/img/retail/icon/inventoryControl.png" /> INVENTORY AND PRICE CONTROL
                                  </li>
                                  <li id="pos_li6" className="sokbtn" name="6">
                                    <img className="detail_img2" src="./assets/img/retail/icon/flexibleDeployment.png" /> FLEXIBLE DEPLOYMENT
                                  </li>
                                </ul>
                              </div>
                            </div>
                          
          
                          <div className="col-sm-6 details_img22">
                              <div className="detail_sec1 clfret8">
          
                                <div className="row details_img45 soksctn" name="1" id="pos_del1">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/omniChannel.png" />
                                    <p className="cont_dpara1">Provide customers with a fully-integrated shopping experience by 
                                    connecting user experiences from brick-and-mortar to mobile-browsing without any hiccups. 
                                    Let the experience be seamless be it shopping online from a desktop or mobile device, by 
                                    telephone, or in a physical store.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="2" id="pos_del2">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/integratedPayment.png" />
                                    <p className="cont_dpara1">At CYNTRA, we offer greater convenience and choice in payment services. 
                                    Payment integration through CYNTRA allows multiple modes of digital payments with ease; 
                                    credit/debit/international cards, mobile wallets, QR codes, loyalty points, and UPI. Seamless 
                                    integration with your payment and billing system avoids manual errors and produce reports that 
                                    reconcile.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="3" id="pos_del3" >
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/customerEngagement.png" />
                                    <p className="cont_dpara1">Shoppers now use multiple channels to make their purchase, providing you with 
                                    numerous opportunities to connect with them. Expand your strategy to meet shoppers where they are, on 
                                    their channel of preference, to educate, inform, and entertain them with your brand and valuable content.
                                    Our solutions let you personalize customer experience and make your business more human.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="4" id="pos_del4" >
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/coupon.png" />
                                    <p className="cont_dpara1">Run digital promotions and create personalized coupons on our platform, 
                                    for your loyal customers. With the digital shift in retail, there is a great need for 
                                    promotional activities, but there is an even greater need to provide savings options for 
                                    consumers to have them keep coming back.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="5" id="pos_del5">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/inventoryControl.png" />
                                    <p className="cont_dpara1">Our point of 
                                    sale inventory management system helps you keep track of your inventory, allows business 
                                    owners to have more than one business location and adequately keep track of inventory at each, 
                                    without being physically present — no more worries about employee theft or pricing inconsistency between 
                                    one location and another. Have all the information that you need at a glance.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="6" id="pos_del6" >
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/flexibleDeployment.png" />
                                    <p className="cont_dpara1">Our platform can be deployed on your own servers, as an appliance or 
                                    in the cloud; without disrupting your current IT landscape. Our applications are deployed 
                                    with a near-zero downtime.
                                    </p>
                                  </div>
                                </div>
          
                               </div> 
                           </div>
                          
                        </div>
                    </div>
                    </div>
          
                    <div className="carousel-item">
                      <div className="retailsol_1" id="digi_section">
                        <div  className="header_div1 header_production1">
                            <h2 className="header_text1">DIGITAL PAYMENTS</h2>
                        </div>
                        <div className="row retailsol_1" >
                          <div className="col-sm-6">
                              <div className="row">
                                <ul className="detail_sect23">
                                  <li id="digi_li1" className="addcss13 sokbtn" name="1">
                                    <img className="detail_img2" src="./assets/img/retail/icon/robustSecurity.png" /> ROBUST SECURITY
                                  </li>
                                  {/* <li id="digi_li2">
                                    <img className="detail_img2" src="./assets/img/retail/icon/multiPaymentMethod.png" /> MULTI PAYMENT METHOD
                                  </li> */}
                                  <li id="digi_li3" className="sokbtn" name="2">
                                    <img className="detail_img2" src="./assets/img/retail/icon/multichannel.png" /> OMNI-CHANNEL PROCESSIING
                                  </li>
                                  <li id="digi_li4" className="sokbtn" name="3">
                                    <img className="detail_img2" src="./assets/img/retail/icon/dashboard.png" /> POWERFUL DASHBOARD
                                  </li>
                                  <li id="digi_li5" className="sokbtn" name="4">
                                    <img className="detail_img2" src="./assets/img/retail/icon/acceleratedDeployment.png" /> ACCELERATED DEPLOYMENT
                                  </li>
                                  <li id="digi_li6" className="sokbtn" name="5">
                                    <img className="detail_img2" src="./assets/img/retail/icon/apiIntegration.png" /> API INTEGRATION
                                  </li>
                                  <li id="digi_li7" className="sokbtn" name="6">
                                    <img className="detail_img2" src="./assets/img/retail/icon/value.png" /> VALUE ADDED SOLUTIONS
                                  </li> 
                                </ul>
                              </div>
                          </div>
          
                          <div className="col-sm-6 details_img22">
                              <div className="detail_sec1 clfret10" >
          
                                <div className="row details_img45 soksctn" name="1" id="digi_del1">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/robustSecurity.png" />
                                    <p className="cont_dpara1">Online payment systems are indispensable to the growth of e-commerce 
                                    across the globe. CYNTRA ensures security, convenience, cardholder authentication, and 
                                    verification of merchant; it is easy to implement without complications. Monitor incoming 
                                    payments and detect fraudulent transactions to keep your business safe.
                                    </p>
                                  </div>
                                </div>
          
                                {/* <div className="row details_img45 clfret9" id="digi_del2" >
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/multiPaymentMethod.png" />
                                    <p className="cont_dpara1">Process credit/debit cards, internet banking, mobile wallets 
                                    and even UPI transactions on a single integration
                                    </p>
                                  </div>
                </div> */}
          
                                <div className="row details_img45 clfret9 soksctn" name="2" id="digi_del3">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/multichannel.png" />
                                    <p className="cont_dpara1">The retail landscape is genearlly fragmented as merchants 
                                      design their payment environments using solutions from multiple vendors. CYNTRA's 
                                      single integration, however, offers you a seamless solution, allowing you to accept 
                                      payments across all sales channels and devices, whether via mobile, online or 
                                      point of sale (POS) — through a single omni-channel payment gateway and processing 
                                      platform. Process credit/debit cards, internet banking, mobile wallets and even UPI 
                                      transactions on a single integration.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="3" id="digi_del4" >
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/dashboard.png" />
                                    <p className="cont_dpara1">Built in analytics helps you generate reports and analyze 
                                    your sales performance. With CYNTRA you achieve insights on how to improve business.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="4" id="digi_del5">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/acceleratedDeployment.png" />
                                    <p className="cont_dpara1">By automating and standardizing to industry best practice, 
                                    CYNTRA Payments provides accelerated deployment, better value, and reduced risk. 
                                    Through our modular API delivery model, we provide accelerated time-to-market in 
                                    the adoption of key payments solutions, embedding them into your business use 
                                    cases such as driving secure e-commerce and the digital economy. By automating 
                                    and standardizing to industry best practice, CYNTRA Payments provides accelerated 
                                    deployment, better value, and reduced risk.
                                  </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="5" id="digi_del6">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/apiIntegration.png" />
                                    <p className="cont_dpara1">
                                      CYNTRA’s API framework provides a pre-built solution that allows businesses to create safe, 
                                      cost-effective and enjoyable payment experiences. This attracts new customers and keeps 
                                      existing customers happy and loyal. We make it easier for your businesses to accept consumers' 
                                      payments through any digital channel. Multiple payment ways, all at a reduced cost.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="6" id="digi_del7">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/value.png" />
                                    <p className="cont_dpara1">We offer to add more functions to your business to enhance value from 
                                    online transaction for booking or purchase, digital vouchers, payment web links, to payments via QR codes and more.
                                    </p>
                                  </div>
                                </div>
          
                              </div> 
                          </div>
                        </div>
                      </div>
                    </div>
          
                   
                    <div className="carousel-item">
                        <div className="retailsol_1" id="kiosk_section">
                          <div  className="header_div1 header_production1">
                              <h2 className="header_text1">SELF ODERING KIOSK</h2>
                          </div>
                          <div className="row retailsol_1">
                            <div className="col-sm-6">
                                <div className="row">
                                  <ul className="detail_sect23">
                                    <li id="kiosk_li1" className="addcss13">
                                      <img className="detail_img2 sokbtn" name="1" src="./assets/img/retail/icon/qrCode.png" /> CONTACTLESS ORDERING
                                    </li>
                                    <li id="kiosk_li2">
                                      <img className="detail_img2 sokbtn" name="2" src="./assets/img/retail/icon/seamlessIntegration.png" /> SEAMLESS INTEGRATION
                                    </li>
                                    <li id="kiosk_li3">
                                      <img className="detail_img2 sokbtn" name="3" src="./assets/img/retail/icon/voice.png" /> VOICE ACTIVATION
                                    </li>
                                    <li id="kiosk_li4">
                                      <img className="detail_img2 sokbtn" name="4" src="./assets/img/retail/icon/payment.png" /> INTEGRATED PAYMENTS
                                    </li>
                                    <li id="kiosk_li5">
                                      <img className="detail_img2 sokbtn" name="5" src="./assets/img/retail/icon/socialDistancing.png" /> IMPROVED ORDER ACCURACY
                                    </li>
                                    <li id="kiosk_li6">
                                      <img className="detail_img2 sokbtn" name="6" src="./assets/img/retail/icon/expressCheckout.png" /> EXPRESS CHECKOUT
                                    </li>
                                  </ul>
                                </div>
                            </div>
          
                            <div className="col-sm-6 details_img22">
                                <div className="detail_sec1 clfret11"  >
          
                                  <div className="row details_img45 soksctn" name="1" id="kiosk_del1">
                                    <div className="cont_detail1">
                                      <img src="./assets/img/retail/icon/qrCode.png" />
                                      <p className="cont_dpara1">Contactless in-store ordering solution helps restaurants and 
                                      eateries minimize physical contacts for menu, billing, and cash transactions. Enjoy 
                                      safe dining and food ordering experience simply by scanning QR code displayed on the Kiosk.
                                      </p>
                                    </div>
                                  </div>
          
                                  <div className="row details_img45 clfret9 soksctn" name="2" id="kiosk_del2" >
                                    <div className="cont_detail1">
                                      <img src="./assets/img/retail/icon/seamlessIntegration.png" />
                                      <p className="cont_dpara1">Integration is a necessary step to make your self-service kiosk system 
                                      more competitive and avoid glitches. API integration allows requests which are from customers 
                                      via kiosk to communicate with the server and update the requests quickly. Seamless integration 
                                      encourages bigger orders and faster checkouts turning kiosk into ‘all-in-one’ system.
                                      </p>
                                    </div>
                                  </div>
          
                                  <div className="row details_img45 clfret9 soksctn" name="3" id="kiosk_del3" >
                                    <div className="cont_detail1">
                                      <img src="./assets/img/retail/icon/voice.png" />
                                      <p className="cont_dpara1">CYNTRA’s voice ordering AI platform interacts on behalf of the restaurants 
                                      with its customers to take orders and book tables. Voice activation enables restaurants to receive 
                                      orders via voice interface deployed on the kiosks.</p>
                                    </div>
                                  </div>
          
                                  <div className="row details_img45 clfret9 soksctn" name="4" id="kiosk_del4" >
                                    <div className="cont_detail1">
                                      <img src="./assets/img/retail/icon/payment.png" />
                                      <p className="cont_dpara1">Our self ordering Kiosks consists of various trusted payment methods. 
                                      Check out the menu, order, customize dishes and pay in one place. Customers can pay by card or even go contactless.
                                      </p>
                                    </div>
                                  </div>
          
                                  <div className="row details_img45 clfret9 soksctn" name="5" id="kiosk_del5" >
                                    <div className="cont_detail1">
                                      <img src="./assets/img/retail/icon/socialDistancing.png" />
                                      <p className="cont_dpara1">Kiosks enable entering orders directly; it helps eliminate errors, as the order goes from the 
                                      customer to the kiosk to the kitchen. Self-order kiosks give people the chance to make more informed choices, so 
                                      it tastes great every time.
                                      </p>
                                    </div>
                                  </div>
          
                                  <div className="row details_img45 clfret9 soksctn" name="6" id="kiosk_del6">
                                    <div className="cont_detail1">
                                      <img src="./assets/img/retail/icon/expressCheckout.png" />
                                      <p className="cont_dpara1">It’s a known fact that customers order more when someone isn’t looking. Through 
                                      self-service kiosks, customers can breeze through your restaurant line faster, feeling more satisfied and 
                                      spending more than usual. You save on labor costs, enhance your brand, and build a better reputation with customers.
                                      </p>
                                    </div>
                                  </div>
          
                                </div> 
                            </div>
                          </div>
                        </div>
                    </div>
          
                  
                    <div className="carousel-item">
                      <div className="retailsol_1" id="liv_section">
                        <div  className="header_div1 header_production1">
                            <h2 className="header_text1">LIV REPORTING</h2>
                        </div>
                        <div className="row retailsol_1">
                          <div className="col-sm-6">
                              <div className="row">
                                <ul className="detail_sect23">
                                  <li id="liv_li1" className="addcss13 sokbtn" name="1">
                                    <img className="detail_img2" src="./assets/img/retail/icon/realTimeKPI.png" /> REAL TIME KPIs
                                  </li>
                                  <li id="liv_li2" className="sokbtn" name="2">
                                    <img className="detail_img2" src="./assets/img/retail/icon/forcastingTrend.png" /> FORECASTING TRENDS
                                  </li>
                                  <li id="liv_li3" className="sokbtn" name="3">
                                    <img className="detail_img2" src="./assets/img/retail/icon/employeeManagement.png" /> EMPLOYEE MANAGEMENT
                                  </li>
                                  <li id="liv_li4" className="sokbtn" name="4">
                                    <img className="detail_img2" src="./assets/img/retail/icon/inDepthAnalytics.png" /> INSIGHTS ON THE GO
                                  </li>
                                  <li id="liv_li5" className="sokbtn" name="5">
                                    <img className="detail_img2" src="./assets/img/retail/icon/dataHarmonization.png" /> DATA HARMONIZATION
                                  </li>
                                  <li id="liv_li6" className="sokbtn" name="6">
                                    <img className="detail_img2" src="./assets/img/retail/icon/inventoryPriceControl.png" /> INVENTORY & PRICING CONTROL
                                  </li>
                                </ul>
                              </div>
                          </div>
          
                          <div className="col-sm-6 details_img22">
                              <div className="detail_sec1 clfret12"  >
          
                                <div className="row details_img45 soksctn" name="1" id="liv_del1">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/realTimeKPI.png" />
                                    <p className="cont_dpara1">Operational reporting that is real-time in nature. Get up-to-date reporting of key data metrics 
                                    while maintaining high performance levels for operational processing. Real-time analytics allows you to present real-time 
                                    transactional information. Also, you get to compare the same with your past data in a single analytical environment too.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="2" id="liv_del2">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/forcastingTrend.png" />
                                    <p className="cont_dpara1">Forecast inventory requirements, manage shipping schedules, run promotional sales and configure store 
                                    layouts to maximize sales. Optimize your marketing campaigns with predictive analytics, as well as promote cross-sell 
                                    opportunities. Attract, retain and care for your most valued customers.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="3" id="liv_del3">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/employeeManagement.png" />
                                    <p className="cont_dpara1">Data and reports give you the ability to audit employee performance, in terms of sales 
                                    generated and also in productivity measurable such as attendance, inventory tasks completion, and hours worked. 
                                    By gauging employee performance necessary changes can be implemented to resolve any bottlenecks. Also, you can 
                                    leverage your top salespeople to provide the biggest boosts to your business.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="4" id="liv_del4">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/inDepthAnalytics.png" />
                                    <p className="cont_dpara1">Get access to what matters, even when you’re on the go. CYNTRA’s Liv Reporting app let's 
                                    you use your smartphone to check in on metrics of success from anywhere. Get access to transactional insights, 
                                    exceptions and warnings, and customer information in a way that is meaningful to you. Keep a pulse on what's 
                                    happening, improve line-of-sight and ensure your staff is informed too of important information as it happens.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="5" id="liv_del5">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/dataHarmonization.png" />
                                    <p className="cont_dpara1">Harmonized data creates a 360 degree view of the business. Consistent, shared data and best 
                                    practices that can be leveraged across retail accounts and internal departments lead to more process efficiency 
                                    during operations and decision making. Create and access business intelligence insights, while also lowering the 
                                    total cost of data analysis.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="6" id="liv_del6" >
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/inventoryPriceControl.png" />
                                    <p className="cont_dpara1">Avoid spoilage and dead stock, save on storage cost, run promotions on slow moving items and 
                                    control prices at different store locations based on demands and your customers. Stay cost-efficient and get flawless 
                                    inventory management with our Liv Reporting, Inventory and pricing management software.
                                    </p>
                                  </div>
                                </div>
          
                              </div> 
                          </div>
                        </div>
                      </div>
                    </div>
          
   
                     <div className="carousel-item">
                      <div className="retailsol_1" id="rfid_section">
                        <div  className="header_div1 header_production1">
                            <h2 className="header_text1">SMART RFID SOLUTIONS</h2>
                        </div>
                        <div className="row retailsol_1">
                          <div className="col-sm-6">
                              <div className="row">
                                <ul className="detail_sect23">
                                  <li id="rfid_li1" className="addcss13 sokbtn" name="1">
                                    <img className="detail_img2" src="./assets/img/retail/icon/brandProtection.png" /> BRAND PROTECTION
                                  </li>
                                  <li id="rfid_li2" className="sokbtn" name="2">
                                    <img className="detail_img2" src="./assets/img/retail/icon/trackTrace.png" /> TRACK & TRACE
                                  </li>
                                  <li id="rfid_li3" className="sokbtn" name="3">
                                    <img className="detail_img2" src="./assets/img/retail/icon/enhancedCX.png" /> ENHANCED CUSTOMER EXPERIENCE
                                  </li>
                                  <li id="rfid_li4" className="sokbtn" name="4">
                                    <img className="detail_img2" src="./assets/img/retail/icon/verifiedReturns.png" /> VERIFIED RETURNS
                                  </li>
                                  <li id="rfid_li5" className="sokbtn" name="5">
                                    <img className="detail_img2" src="./assets/img/retail/icon/endlessAisle.png" /> ENDLESS AISLE
                                  </li>
                                  <li id="rfid_li6" className="sokbtn" name="6">
                                    <img className="detail_img2" src="./assets/img/retail/icon/30SecCheckout.png" /> 30 SEC CHECKOUT
                                  </li>
                                </ul>
                              </div>
                          </div>
          
                          <div className="col-sm-6 details_img22">
                              <div className="detail_sec1 clfret13" >
          
                                <div className="row details_img45 soksctn" name="1" id="rfid_del1">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/brandProtection.png" />
                                    <p className="cont_dpara1">CYNTRA provides 360 degrees of protection for your brand; combat the 
                                    risk for counterfeit and grey market fraud, increase profits, protect your investment, your 
                                    reputation and your market share. Strengthen your brand.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="2" id="rfid_del2">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/trackTrace.png" />
                                    <p className="cont_dpara1">Using track and trace technology, affixed or embedded into individual 
                                    products at the manufacturing stage allows every item to be tracked along the supply chain. 
                                    This gives complete visibility and offers insights to allow for quick, data-driven decisions 
                                    for your business.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="3" id="rfid_del3">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/enhancedCX.png" />
                                    <p className="cont_dpara1">Let your customers leave the store with not just a fun and swift 
                                    shopping experience but also a long term relationship of your brand in their shopping bags.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="4" id="rfid_del4" >
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/verifiedReturns.png" />
                                    <p className="cont_dpara1">The studies have shown that about 6.5 percent of retail returns are 
                                    fraudulent or tampered, adding to your costs and reducing your net sales. With Smart RFID, 
                                    you individually address fraudulent or abuse returners, reduces the number of unprofitable 
                                    consumers, protect good customers, stop your financial drain and preserves net sales.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="5" id="rfid_del5" >
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/endlessAisle.png" />
                                    <p className="cont_dpara1">Bringing the power and flexibility of online sales channel into brick 
                                    and mortar stores. Endless Aisle reduces lost sales. It allows you to offer an extensive product 
                                    selection which otherwise on physical displays is a challenge.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="6" id="rfid_del6">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/30SecCheckout.png" />
                                    <p className="cont_dpara1">Directing your customers straight to checkout after they found a product 
                                    will increase your conversions, enhance sales and revenue, more than that you give an easier, 
                                    faster, and simpler user experience.This is exactly what 30 seconds Checkout does. By skipping 
                                    the queues and long waits, you’ll reduce the likelihood of your clients abandoning their carts.
                                    </p>
                                  </div>
                                </div>
          
                              </div> 
                          </div>
                        </div>
                      </div>
                     </div>
          
  
                     <div className="carousel-item">
                      <div className="retailsol_1" id="web_section">
                        <div  className="header_div1 header_production1">
                            <h2 className="header_text1">WEB & MOBILE DEVELOPMENT</h2>
                        </div>
                        <div className="row retailsol_1">
                          <div className="col-sm-6">
                              <div className="row">
                                <ul className="detail_sect23">
                                  <li id="web_li1" className="addcss13 sokbtn" name="1">
                                    <img className="detail_img2" src="./assets/img/retail/icon/ecommerceEnabled.png" /> E-COMMERCE ENABLED
                                  </li>
                                  <li id="web_li2" className="sokbtn" name="2">
                                    <img className="detail_img2" src="./assets/img/retail/icon/UX.png" /> UX CENTRIC DESIGN
                                  </li>
                                  <li id="web_li3" className="sokbtn" name="3">
                                    <img className="detail_img2" src="./assets/img/retail/icon/crossPlatform.png" /> CROSS PLATFORM
                                  </li>
                                  <li id="web_li4" className="sokbtn" name="4">
                                    <img className="detail_img2" src="./assets/img/retail/icon/seo.png" /> SEARCH ENGINE OPTIMIZATION
                                  </li>
                                  <li id="web_li5" className="sokbtn" name="5">
                                    <img className="detail_img2" src="./assets/img/retail/icon/support.png" /> SUPPORT & MAINTENANCE
                                  </li>
                                  <li id="web_li6" className="sokbtn" name="6">
                                    <img className="detail_img2" src="./assets/img/retail/icon/digitalMarketing.png" /> DIGITAL MARKETING
                                 </li>
                                    </ul>
                                    </div>
                              
                          </div>
          
                          <div className="col-sm-6 details_img22">
                              <div className="detail_sec1 clfret14" >
          
                                <div className="row details_img45 soksctn" name="1" id="web_del1">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/ecommerceEnabled.png" />
                                    <p className="cont_dpara1">Reach out to your prospective customers worldwide; 
                                    complete e-commerce solution with an online storefront will enable you to 
                                    sell your products and services online, the most cost-effective way of promoting 
                                    your business in a competitive online world. With all the basic features you need, 
                                    you also have the option of customizing your site to offer those processes that keep 
                                    you ahead of the competition.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="2" id="web_del2" >
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/UX.png" />
                                    <p className="cont_dpara1">Centric Design - It starts with you and ends with the product 
                                    that is tailored to your needs. We value you and your business and therefore design 
                                    from your perspective. You are an integral part of the planning, design, and 
                                    development of your product.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="3" id="web_del3" >
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/crossPlatform.png" />
                                    <p className="cont_dpara1">Given the exponential increase in the cost per platform development 
                                    and the need for rapid time to market your brand, cross-platform development is the way to 
                                    go for any business. Cross-platform offers fewer technical glitches and the design performance 
                                    stays uniform. Cost-effectiveness, easy hosting, and cloud integration all come in handy.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="4" id="web_del4">
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/seo.png" />
                                    <p className="cont_dpara1">Be found when your customers are searching! Our affordable SEO Services 
                                    deliver a modern SEO strategy designed to engage your customers in 2020. Our team uses modern 
                                    SEO services, backed by the best digital practices, to increase your traffic & rankings for 
                                    vetted keywords and long-tail phrases that drive business to your site.
                                    </p>
                                  </div>
                                </div>
          
                                <div className="row details_img45 clfret9 soksctn" name="5" id="web_del5" >
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/support.png" />
                                    <p className="cont_dpara1">Don’t let your software turn obsolete! Increase your business productivity, 
                                    add more flexibility, scalability and realize significant cost benefits to your business with 
                                    innovative software maintenance and enhancements. Get flexible Software Maintenance Services including 
                                    ongoing software enhancement, periodic security review & compliance, SLA driven support and much more. </p>
                                  </div>
                                  </div>
                                
          
                                <div className="row details_img45 clfret9 soksctn" name="6" id="web_del6" >
                                  <div className="cont_detail1">
                                    <img src="./assets/img/retail/icon/digitalMarketing.png" />
                                    <p className="cont_dpara1">Your business needs an online marketing strategy to drive more qualified visitors 
                                    to your site and convert those visitors into leads and sales. CYNTRA will help you leverage digital 
                                    technologies, tactics, and channels to connect your organization with existing and prospective customers. 
                                    We will help your businesses grow, in spite of constraints like location, by enabling 
                                    them to reach a targeted audience.
                                    </p>
                                  </div>
                                </div>
          
                              
                          </div>
                        </div>
                      
                     </div>
                     </div>
          
                  </div>
                  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
          
          
          
          
          
                  {/* <ul className="plusul1">
                    <a href="#redirect23"><li id="plus1" className="plus_addcss21">+</li></a>
                    <a href="#redirect23"><li id="plus2">+</li></a>
                    <a href="#redirect23"><li id="plus3">+</li></a>
                    <a href="#redirect23"><li id="plus4">+</li></a>
                    <a href="#redirect23"><li id="plus5">+</li></a>
                    <a href="#redirect23"><li id="plus6">+</li></a>
                  </ul>
                   <div className="detail_section44" id="pos_section">
                     <div  className="header_div1 header_production1">
                        <h2 className="header_text1">POINT OF SALE</h2>
                     </div>
                     <div className="row retailsol_1">
                      <div className="col-sm-6 animate__animated animate__fadeInLeft">
                          <div className="row">
                            <ul className="detail_sect23">
                              <li id="pos_li1" className="addcss13">
                                <img className="detail_img2" src="./assets/img/retail/icon/omniChannel.png" /> OMNI-CHANNEL
                              </li>
                              <li id="pos_li2">
                                <img className="detail_img2" src="./assets/img/retail/icon/integratedPayment.png" /> INTEGRATED PAYMENTS
                              </li>
                              <li id="pos_li3">
                                <img className="detail_img2" src="./assets/img/retail/icon/customerEngagement.png" /> CUSTOMER ENGAGEMENT
                              </li>
                              <li id="pos_li4">
                                <img className="detail_img2" src="./assets/img/retail/icon/coupon.png" /> DIGITAL COUPONING
                              </li>
                              <li id="pos_li5">
                                <img className="detail_img2" src="./assets/img/retail/icon/inventoryControl.png" /> INVENTORY AND PRICE CONTROL
                              </li>
                              <li id="pos_li6">
                                <img className="detail_img2" src="./assets/img/retail/icon/flexibleDeployment.png" /> FLEXIBLE DEPLOYMENT
                              </li>
                            </ul>
                          </div>
                      </div>
          
                      <div className="col-sm-6 details_img22  animate__animated animate__fadeInRight">
                          <div className="detail_sec1 clfret15">
          
                            <div className="row details_img45" id="pos_del1">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/omniChannel.png" />
                                <p className="cont_dpara1">Provide customers with a fully-integrated shopping experience by 
                                connecting user experiences from brick-and-mortar to mobile-browsing without any hiccups. 
                                Let the experience be seamless be it shopping online from a desktop or mobile device, by 
                                telephone, or in a physical store.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="pos_del2">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/integratedPayment.png" />
                                <p className="cont_dpara1">At CYNTRA, we offer greater convenience and choice in payment services. 
                                Payment integration through CYNTRA allows multiple modes of digital payments with ease; 
                                credit/debit/international cards, mobile wallets, QR codes, loyalty points, and UPI. Seamless 
                                integration with your payment and billing system avoids manual errors and produce reports that 
                                reconcile.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="pos_del3" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/customerEngagement.png" />
                                <p className="cont_dpara1">Shoppers now use multiple channels to make their purchase, providing you with 
                                numerous opportunities to connect with them. Expand your strategy to meet shoppers where they are, on 
                                their channel of preference, to educate, inform, and entertain them with your brand and valuable content.
                                Our solutions let you personalize customer experience and make your business more human.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="pos_del4">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/coupon.png" />
                                <p className="cont_dpara1">Run digital promotions and create personalized coupons on our platform, 
                                for your loyal customers. With the digital shift in retail, there is a great need for 
                                promotional activities, but there is an even greater need to provide savings options for 
                                consumers to have them keep coming back.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="pos_del5">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/inventoryControl.png" />
                                <p className="cont_dpara1">Our point of 
                                sale inventory management system helps you keep track of your inventory, allows business 
                                owners to have more than one business location and adequately keep track of inventory at each, 
                                without being physically present — no more worries about employee theft or pricing inconsistency between 
                                one location and another. Have all the information that you need at a glance.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="pos_del6">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/flexibleDeployment.png" />
                                <p className="cont_dpara1">Our platform can be deployed on your own servers, as an appliance or 
                                in the cloud; without disrupting your current IT landscape. Our applications are deployed 
                                with a near-zero downtime.
                                </p>
                              </div>
                            </div>
          
                          </div> 
                      </div>
                    </div>
                   </div>
          
          
                   <div className="retailsol_1 clfret9" id="digi_section" >
                     <div  className="header_div1 header_production1">
                        <h2 className="header_text1">DIGITAL PAYMENTS</h2>
                     </div>
                     <div className="row" >
                      <div className="col-sm-6  animate__animated animate__fadeInLeft">
                          <div className="row">
                            <ul className="detail_sect23">
                              <li id="digi_li1" className="addcss13">
                                <img className="detail_img2" src="./assets/img/retail/icon/robustSecurity.png" /> ROBUST SECURITY
                              </li>
                              <li id="digi_li2">
                                <img className="detail_img2" src="./assets/img/retail/icon/multiPaymentMethod.png" /> MULTI PAYMENT METHOD
                              </li>
                              <li id="digi_li3">
                                <img className="detail_img2" src="./assets/img/retail/icon/multichannel.png" /> MULTI CHANNEL PROCESSING
                              </li>
                              <li id="digi_li4">
                                <img className="detail_img2" src="./assets/img/retail/icon/dashboard.png" /> POWERFUL DASHBOARD
                              </li>
                              <li id="digi_li5">
                                <img className="detail_img2" src="./assets/img/retail/icon/acceleratedDeployment.png" /> ACCELERATED DEPLOYMENT
                              </li>
                              <li id="digi_li6">
                                <img className="detail_img2" src="./assets/img/retail/icon/apiIntegration.png" /> API INTEGRATION
                              </li>
                              <li id="digi_li7">
                                <img className="detail_img2" src="./assets/img/retail/icon/value.png" /> VALUE ADDED SOLUTIONS
                            </ul>
                          </div>
                      </div>
          
                      <div className="col-sm-6 details_img22  animate__animated animate__fadeInRight">
                          <div className="detail_sec1 clfret16"  >
          
                            <div className="row details_img45" id="digi_del1">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/robustSecurity.png" />
                                <p className="cont_dpara1">Online payment systems are indispensable to the growth of e-commerce 
                                across the globe. CYNTRA ensures security, convenience, cardholder authentication, and 
                                verification of merchant; it is easy to implement without complications. Monitor incoming 
                                payments and detect fraudulent transactions to keep your business safe.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="digi_del2" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/multiPaymentMethod.png" />
                                <p className="cont_dpara1">Process credit/debit cards, internet banking, mobile wallets 
                                and even UPI transactions on a single integration
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="digi_del3" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/multichannel.png" />
                                <p className="cont_dpara1">With CYNTRA’s single integration, you can accept payments seamlessly 
                                on different channels either online, on your mobile devices, or (what are the other options
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="digi_del4">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/dashboard.png" />
                                <p className="cont_dpara1">Built in analytics helps you generate reports and analyze 
                                your sales performance. With CYNTRA you achieve insights on how to improve business.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="digi_del5">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/acceleratedDeployment.png" />
                                <p className="cont_dpara1">By automating and standardizing to industry best practice, 
                                CYNTRA Payments provides accelerated deployment, better value, and reduced risk. 
                                Through our modular API delivery model, we provide accelerated time-to-market in 
                                the adoption of key payments solutions, embedding them into your business use 
                                cases such as driving secure e-commerce and the digital economy. By automating 
                                and standardizing to industry best practice, CYNTRA Payments provides accelerated 
                                deployment, better value, and reduced risk.
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="digi_del6" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/apiIntegration.png" />
                                <p className="cont_dpara1">
                                  CYNTRA’s API framework provides a pre-built solution that allows businesses to create safe, 
                                  cost-effective and enjoyable payment experiences. This attracts new customers and keeps 
                                  existing customers happy and loyal. We make it easier for your businesses to accept consumers' 
                                  payments through any digital channel. Multiple payment ways, all at a reduced cost.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="digi_del7" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/value.png" />
                                <p className="cont_dpara1">We offer to add more functions to your business to enhance value from 
                                online transaction for booking or purchase, digital vouchers, payment web links, to payments via QR codes and more.
                                </p>
                              </div>
                            </div>
          
                          </div> 
                      </div>
                    </div>
                   </div>
          
          
                   <div className="retailsol_1 clfret9" id="kiosk_section" >
                     <div  className="header_div1 header_production1">
                        <h2 className="header_text1">SELF ODERING KIOSK</h2>
                     </div>
                     <div className="row">
                      <div className="col-sm-6  animate__animated animate__fadeInLeft">
                          <div className="row">
                            <ul className="detail_sect23">
                              <li id="kiosk_li1" className="addcss13">
                                <img className="detail_img2" src="./assets/img/retail/icon/qrCode.png" /> CONTACTLESS ORDERING
                              </li>
                              <li id="kiosk_li2">
                                <img className="detail_img2" src="./assets/img/retail/icon/seamlessIntegration.png" /> SEAMLESS INTEGRATION
                              </li>
                              <li id="kiosk_li3">
                                <img className="detail_img2" src="./assets/img/retail/icon/voice.png" /> VOICE ACTIVATION
                              </li>
                              <li id="kiosk_li4">
                                <img className="detail_img2" src="./assets/img/retail/icon/payment.png" /> INTEGRATED PAYMENTS
                              </li>
                              <li id="kiosk_li5">
                                <img className="detail_img2" src="./assets/img/retail/icon/socialDistancing.png" /> IMPROVED ORDER ACCURACY
                              </li>
                              <li id="kiosk_li6">
                                <img className="detail_img2" src="./assets/img/retail/icon/expressCheckout.png" /> EXPRESS CHECKOUT
                              </li>
                            </ul>
                          </div>
                      </div>
          
                      <div className="col-sm-6 details_img22  animate__animated animate__fadeInRight">
                          <div className="detail_sec1 clfret17"  >
          
                            <div className="row details_img45" id="kiosk_del1">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/qrCode.png" />
                                <p className="cont_dpara1">Contactless in-store ordering solution helps restaurants and 
                                eateries minimize physical contacts for menu, billing, and cash transactions. Enjoy 
                                safe dining and food ordering experience simply by scanning QR code displayed on the Kiosk.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="kiosk_del2">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/seamlessIntegration.png" />
                                <p className="cont_dpara1">Integration is a necessary step to make your self-service kiosk system 
                                more competitive and avoid glitches. API integration allows requests which are from customers 
                                via kiosk to communicate with the server and update the requests quickly. Seamless integration 
                                encourages bigger orders and faster checkouts turning kiosk into ‘all-in-one’ system.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="kiosk_del3" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/voice.png" />
                                <p className="cont_dpara1">CYNTRA’s voice ordering AI platform interacts on behalf of the restaurants 
                                with its customers to take orders and book tables. Voice activation enables restaurants to receive 
                                orders via voice interface deployed on the kiosks.
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="kiosk_del4">
                                <img src="./assets/img/retail/icon/payment.png" />
                                <p className="cont_dpara1">Our self ordering Kiosks consists of various trusted payment methods. 
                                Check out the menu, order, customize dishes and pay in one place. Customers can pay by card or even go contactless.
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="kiosk_del5" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/socialDistancing.png" />
                                <p className="cont_dpara1">Kiosks enable entering orders directly; it helps eliminate errors, as the order goes from the 
                                customer to the kiosk to the kitchen. Self-order kiosks give people the chance to make more informed choices, so 
                                it tastes great every time.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="kiosk_del6" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/expressCheckout.png" />
                                <p className="cont_dpara1">It’s a known fact that customers order more when someone isn’t looking. Through 
                                self-service kiosks, customers can breeze through your restaurant line faster, feeling more satisfied and 
                                spending more than usual. You save on labor costs, enhance your brand, and build a better reputation with customers.
                                </p>
                              </div>
                            </div>
          
                          </div> 
                      </div>
                    </div>
                   </div>
          
          
                   <div className="retailsol_1 clfret9" id="liv_section">
                     <div  className="header_div1 header_production1">
                        <h2 className="header_text1">LIV REPORTING</h2>
                     </div>
                     <div className="row">
                      <div className="col-sm-6  animate__animated animate__fadeInLeft">
                          <div className="row">
                            <ul className="detail_sect23">
                              <li id="liv_li1" className="addcss13">
                                <img className="detail_img2" src="./assets/img/retail/icon/realTimeKPI.png" /> REAL TIME KPIs
                              </li>
                              <li id="liv_li2">
                                <img className="detail_img2" src="./assets/img/retail/icon/forcastingTrend.png" /> FORECASTING TRENDS
                              </li>
                              <li id="liv_li3">
                                <img className="detail_img2" src="./assets/img/retail/icon/employeeManagement.png" /> EMPLOYEE MANAGEMENT
                              </li>
                              <li id="liv_li4">
                                <img className="detail_img2" src="./assets/img/retail/icon/inDepthAnalytics.png" /> INSIGHTS ON THE GO
                              </li>
                              <li id="liv_li5">
                                <img className="detail_img2" src="./assets/img/retail/icon/dataHarmonization.png" /> DATA HARMONIZATION
                              </li>
                              <li id="liv_li6">
                                <img className="detail_img2" src="./assets/img/retail/icon/inventoryPriceControl.png" /> INVENTORY & PRICING CONTROL
                              </li>
                            </ul>
                          </div>
                      </div>
          
                      <div className="col-sm-6 details_img22  animate__animated animate__fadeInRight">
                          <div className="detail_sec1 clfret18"  >
          
                            <div className="row details_img45" id="liv_del1">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/realTimeKPI.png" />
                                <p className="cont_dpara1">Operational reporting that is real-time in nature. Get up-to-date reporting of key data metrics 
                                while maintaining high performance levels for operational processing. Real-time analytics allows you to present real-time 
                                transactional information. Also, you get to compare the same with your past data in a single analytical environment too.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="liv_del2">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/forcastingTrend.png" />
                                <p className="cont_dpara1">Forecast inventory requirements, manage shipping schedules, run promotional sales and configure store 
                                layouts to maximize sales. Optimize your marketing campaigns with predictive analytics, as well as promote cross-sell 
                                opportunities. Attract, retain and care for your most valued customers.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="liv_del3">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/employeeManagement.png" />
                                <p className="cont_dpara1">Data and reports give you the ability to audit employee performance, in terms of sales 
                                generated and also in productivity measurable such as attendance, inventory tasks completion, and hours worked. 
                                By gauging employee performance necessary changes can be implemented to resolve any bottlenecks. Also, you can 
                                leverage your top salespeople to provide the biggest boosts to your business.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="liv_del4" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/inDepthAnalytics.png" />
                                <p className="cont_dpara1">Get access to what matters, even when you’re on the go. CYNTRA’s Liv Reporting app let's 
                                you use your smartphone to check in on metrics of success from anywhere. Get access to transactional insights, 
                                exceptions and warnings, and customer information in a way that is meaningful to you. Keep a pulse on what's 
                                happening, improve line-of-sight and ensure your staff is informed too of important information as it happens.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="liv_del5">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/dataHarmonization.png" />
                                <p className="cont_dpara1">Harmonized data creates a 360 degree view of the business. Consistent, shared data and best 
                                practices that can be leveraged across retail accounts and internal departments lead to more process efficiency 
                                during operations and decision making. Create and access business intelligence insights, while also lowering the 
                                total cost of data analysis.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="liv_del6" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/inventoryPriceControl.png" />
                                <p className="cont_dpara1">Avoid spoilage and dead stock, save on storage cost, run promotions on slow moving items and 
                                control prices at different store locations based on demands and your customers. Stay cost-efficient and get flawless 
                                inventory management with our Liv Reporting, Inventory and pricing management software.
                                </p>
                              </div>
                            </div>
          
                          </div> 
                      </div>
                    </div>
                   </div>
          
          
                   <div className="retailsol_1 clfret9" id="rfid_section">
                     <div  className="header_div1 header_production1">
                        <h2 className="header_text1">SMART RFID SOLUTIONS</h2>
                     </div>
                     <div className="row">
                      <div className="col-sm-6  animate__animated animate__fadeInLeft">
                          <div className="row">
                            <ul className="detail_sect23">
                              <li id="rfid_li1" className="addcss13">
                                <img className="detail_img2" src="./assets/img/retail/icon/brandProtection.png" /> BRAND PROTECTION
                              </li>
                              <li id="rfid_li2">
                                <img className="detail_img2" src="./assets/img/retail/icon/trackTrace.png" /> TRACK & TRACE
                              </li>
                              <li id="rfid_li3">
                                <img className="detail_img2" src="./assets/img/retail/icon/enhancedCX.png" /> ENHANCED CUSTOMER EXPERIENCE
                              </li>
                              <li id="rfid_li4">
                                <img className="detail_img2" src="./assets/img/retail/icon/verifiedReturns.png" /> VERIFIED RETURNS
                              </li>
                              <li id="rfid_li5">
                                <img className="detail_img2" src="./assets/img/retail/icon/endlessAisle.png" /> ENDLESS AISLE
                              </li>
                              <li id="rfid_li6">
                                <img className="detail_img2" src="./assets/img/retail/icon/30SecCheckout.png" /> 30 SEC CHECKOUT
                              </li>
                            </ul>
                          </div>
                      </div>
          
                      <div className="col-sm-6 details_img22  animate__animated animate__fadeInRight">
                          <div className="detail_sec1 clfret19"  >
          
                            <div className="row details_img45" id="rfid_del1">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/brandProtection.png" />
                                <p className="cont_dpara1">CYNTRA provides 360 degrees of protection for your brand; combat the 
                                risk for counterfeit and grey market fraud, increase profits, protect your investment, your 
                                reputation and your market share. Strengthen your brand.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="rfid_del2">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/trackTrace.png" />
                                <p className="cont_dpara1">Using track and trace technology, affixed or embedded into individual 
                                products at the manufacturing stage allows every item to be tracked along the supply chain. 
                                This gives complete visibility and offers insights to allow for quick, data-driven decisions 
                                for your business.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="rfid_del3">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/enhancedCX.png" />
                                <p className="cont_dpara1">Let your customers leave the store with not just a fun and swift 
                                shopping experience but also a long term relationship of your brand in their shopping bags.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="rfid_del4">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/verifiedReturns.png" />
                                <p className="cont_dpara1">The studies have shown that about 6.5 percent of retail returns are 
                                fraudulent or tampered, adding to your costs and reducing your net sales. With Smart RFID, 
                                you individually address fraudulent or abuse returners, reduces the number of unprofitable 
                                consumers, protect good customers, stop your financial drain and preserves net sales.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="rfid_del5" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/endlessAisle.png" />
                                <p className="cont_dpara1">Bringing the power and flexibility of online sales channel into brick 
                                and mortar stores. Endless Aisle reduces lost sales. It allows you to offer an extensive product 
                                selection which otherwise on physical displays is a challenge.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="rfid_del6">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/30SecCheckout.png" />
                                <p className="cont_dpara1">Directing your customers straight to checkout after they found a product 
                                will increase your conversions, enhance sales and revenue, more than that you give an easier, 
                                faster, and simpler user experience.This is exactly what 30 seconds Checkout does. By skipping 
                                the queues and long waits, you’ll reduce the likelihood of your clients abandoning their carts.
                                </p>
                              </div>
                            </div>
          
                          </div> 
                      </div>
                    </div>
                   </div>
          
          
                   <div className="retailsol_1 clfret9" id="web_section">
                     <div  className="header_div1 header_production1">
                        <h2 className="header_text1">WEB & MOBILE DEVELOPMENT</h2>
                     </div>
                     <div className="row">
                      <div className="col-sm-6  animate__animated animate__fadeInLeft">
                          <div className="row">
                            <ul className="detail_sect23">
                              <li id="web_li1" className="addcss13">
                                <img className="detail_img2" src="./assets/img/retail/icon/ecommerceEnabled.png" /> E-COMMERCE ENABLED
                              </li>
                              <li id="web_li2">
                                <img className="detail_img2" src="./assets/img/retail/icon/UX.png" /> UX CENTRIC DESIGN
                              </li>
                              <li id="web_li3">
                                <img className="detail_img2" src="./assets/img/retail/icon/crossPlatform.png" /> CROSS PLATFORM
                              </li>
                              <li id="web_li4">
                                <img className="detail_img2" src="./assets/img/retail/icon/seo.png" /> SEARCH ENGINE OPTIMIZATION
                              </li>
                              <li id="web_li5">
                                <img className="detail_img2" src="./assets/img/retail/icon/support.png" /> SUPPORT & MAINTENANCE
                              </li>
                              <li id="web_li6">
                                <img className="detail_img2" src="./assets/img/retail/icon/digitalMarketing.png" /> DIGITAL MARKETING
                            </ul>
                          </div>
                      </div>
          
                      <div className="col-sm-6 details_img22  animate__animated animate__fadeInRight">
                          <div className="detail_sec1 clfret20" >
          
                            <div className="row details_img45" id="web_del1">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/ecommerceEnabled.png" />
                                <p className="cont_dpara1">Reach out to your prospective customers worldwide; 
                                complete e-commerce solution with an online storefront will enable you to 
                                sell your products and services online, the most cost-effective way of promoting 
                                your business in a competitive online world. With all the basic features you need, 
                                you also have the option of customizing your site to offer those processes that keep 
                                you ahead of the competition.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="web_del2">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/UX.png" />
                                <p className="cont_dpara1">Centric Design - It starts with you and ends with the product 
                                that is tailored to your needs. We value you and your business and therefore design 
                                from your perspective. You are an integral part of the planning, design, and 
                                development of your product.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="web_del3">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/crossPlatform.png" />
                                <p className="cont_dpara1">Given the exponential increase in the cost per platform development 
                                and the need for rapid time to market your brand, cross-platform development is the way to 
                                go for any business. Cross-platform offers fewer technical glitches and the design performance 
                                stays uniform. Cost-effectiveness, easy hosting, and cloud integration all come in handy.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="web_del4">
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/seo.png" />
                                <p className="cont_dpara1">Be found when your customers are searching! Our affordable SEO Services 
                                deliver a modern SEO strategy designed to engage your customers in 2020. Our team uses modern 
                                SEO services, backed by the best digital practices, to increase your traffic & rankings for 
                                vetted keywords and long-tail phrases that drive business to your site.
                                </p>
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="web_del5" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/support.png" />
                                <p className="cont_dpara1">Don’t let your software turn obsolete! Increase your business productivity, 
                                add more flexibility, scalability and realize significant cost benefits to your business with 
                                innovative software maintenance and enhancements. Get flexible Software Maintenance Services including 
                                ongoing software enhancement, periodic security review & compliance, SLA driven support and much more.
                              </div>
                            </div>
          
                            <div className="row details_img45 clfret9" id="web_del6" >
                              <div className="cont_detail1">
                                <img src="./assets/img/retail/icon/digitalMarketing.png" />
                                <p className="cont_dpara1">Your business needs an online marketing strategy to drive more qualified visitors 
                                to your site and convert those visitors into leads and sales. CYNTRA will help you leverage digital 
                                technologies, tactics, and channels to connect your organization with existing and prospective customers. 
                                We will help your businesses grow, in spite of constraints like location, by enabling 
                                them to reach a targeted audience.
                                </p>
                              </div>
                            </div>
          
                          </div> 
                      </div>
                    </div>
                   </div> */}
          
                   {/* <div className="row plus_row1">
                      <div className="col-sm-4 ">
                        <h2 className="plus_para1">PRODUCTS & SERVICES</h2> 
                      </div>
                      <div className="col-sm-4 ps_div3">
                        <ul className="plusul1">
                          <a href="#redirect23"><li id="plus1" className="plus_addcss21">+</li></a>
                          <a href="#redirect23"><li id="plus2">+</li></a>
                          <a href="#redirect23"><li id="plus3">+</li></a>
                          <a href="#redirect23"><li id="plus4">+</li></a>
                          <a href="#redirect23"><li id="plus5">+</li></a>
                          <a href="#redirect23"><li id="plus6">+</li></a>
                        </ul>
                </div> */}
                    </div>
                </div>
               
              </section>
          
          
          
             
              <section className="clfret21">
                <div className="container">
                   <div className="row retailsol_1">
                     <div  className="header_div1">
                        <h2 className="header_text1">INDUSTRY SOLUTIONS</h2>
                     </div>
                     <div className="col-sm-3 retailsol_2 animate__animated animate__fadeInLeft">
                        <a className="row retailsol_3">
                          <img src="./assets/img/retail/FB.png" title="Connected Retail" className="retaolsol_img1"/>
                          <h3 className="connectedretail_h1">FOOD & BEVERAGES</h3>
                          <div  className="hvr_div1 hvr_divinds">
                            <div className="hover_efct23">
                              <h2 className="hvr_containt1">FOOD & BEVERAGES</h2>
                              <ul className="industries_ul1">
                                <li>+ POS</li>
                                <li>+ KDS</li>
                                <li>+ CLOUD KITCHEN</li>
                                <li>+ SELF  ODERING KIOSK</li>
                                <li>+ CONTACTLESS ODERING</li>
                                <li>+ LIVE REPORTING</li>
                                <li>+ INTEGRATED PAYMENTS</li>
                              </ul>
                            </div>
                          </div>
                        </a>
                     </div>
          
                     <div className="col-sm-3 retailsol_2 animate__animated animate__fadeInLeft">
                        <a className="row retailsol_3">
                          <img src="./assets/img/retail/Merchandising.png" title="Connected Retail" className="retaolsol_img1"/>
                          <h3 className="connectedretail_h1">MERCHANDISING</h3>
                          <div  className="hvr_div1 hvr_divinds">
                            <div className="hover_efct23">
                              <h2 className="hvr_containt1">MERCHANDISING</h2>
                              <ul className="industries_ul1">
                                <li>+ POS</li>
                                <li>+ RFID</li>
                                <li>+ ENDLESS AISLE</li>
                                <li>+ SELF CHECKOUT</li>
                                <li>+ LIVE REPORTING</li>
                                <li>+ INTEGRATED PAYMENTS</li>
                              </ul>
                            </div>
                          </div>
                        </a>
                     </div>
          
                     <div className="col-sm-3 retailsol_2 animate__animated animate__fadeInRight">
                        <a className="row retailsol_3">
                          <img src="./assets/img/retail/entertainment.png"itle="Connected Retail" className="retaolsol_img1"/>
                          <h3 className="connectedretail_h1">ENTERTAINNMENT</h3>
                          <div  className="hvr_div1 hvr_divinds">
                            <div className="hover_efct23">
                              <h2 className="hvr_containt1">ENTERTAINNMENT</h2>
                              <ul className="industries_ul1">
                                <li>+ POS</li>
                                <li>+ SELF  ODERING KIOSK</li>
                                <li>+ VOICE ODERING</li>
                                <li>+ LIVE REPORTING</li>
                                <li>+ INTEGRATED PAYMENTS</li>
                              </ul>
                            </div>
                          </div>
                        </a>
                     </div>
          
                     <div className="col-sm-3 retailsol_2 animate__animated animate__fadeInRight">
                        <a className="row retailsol_3">
                          <img src="./assets/img/retail/hospital.png" title="ENHANCED CX" className="retaolsol_img1"/>
                          <h3 className="connectedretail_h1">HEALTHCARE</h3>
                          <div  className="hvr_div1 hvr_divinds">
                            <div className="hover_efct23">
                              <h2 className="hvr_containt1">HEALTHCARE</h2>
                              <ul className="industries_ul1">
                                <li>+ POS</li>
                                <li>+ HEALTHCARE KIOSK <br/> &nbsp;&nbsp;&nbsp; SOLUTION </li>
                                <li>+ INFORMATION KIOSK</li>
                                <li>+ LIVE REPORTING</li>
                                <li>+ INTEGRATED PAYMENTS</li>
                              </ul>
                            </div>
                          </div>
                        </a>
                     </div>
                   </div>
                </div>
              </section>
          
              <div className="browser_div323">
                <a href="cyntra_retail_pdf.pdf" target="blank" >
                  <h5 className="clfret22">Brochure</h5>
                </a>
              </div>
              <div className="retail_pdf">
                <a href="Cyntra-RetailSolutions.pdf" target="blank" >
                <h5 className="clfret22">Retail</h5>
                </a>
              </div>
              
          
          
          
           </>
        )
    }
}