import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class BlogClb1 extends Component {
    render() {
        return (
                <>
                
                <main id="main" className="site-main" role="main">
                
                <section className="blog757">
                    </section>
                    <section className="leastblog_div9">
                        <div className="leatest_bloghead32">
                            <div className="container">
                                <h2 className="about_head1">Contactless Ordering &amp; Payments: Beyond COVID19</h2>
  
                                <p className="heading_para1">
                                    Isha Lall |  August 21, 2020                </p>
                            </div>
                        </div>
                    </section>
                    <section className="ourteam_section1 section545">
                        <div className="row ourteam_div1">
                            <div className="col-sm-12 pl-0" style={{ display: 'flex', alignItems: 'center'}}>
                                <div className="col-sm-8" style={{padding: '0px'}}>
                                    <p>Contactless has become a buzz word worldwide, more importantly in the retail industry. We are witnessing a global shift towards a contactless economy due to the social distancing practices that sparked as a result of COVID-19. Despite no vaccines, no cure, and multiple versions of lockdown, countries have now eased up a little and started opening up.</p>
                                    <h3>Altered Human Behavior: A Permanent Shift</h3>
                                    <p>COVID has remodeled the world and consumer behavior as we know it. People are adapting to think and live differently and shop digitally. The virus might go eventually, but as per studies, altered consumer behavior is here to stay. With the affordability of smartphones and internet access, it is getting easier to shift to a contactless economy.<br/>
                                        After all these months of lockdown, the consumers are keen to step out, shop at stores and return to restaurants. At the same time, they are looking for secure yet convenient ways to shop and pay.</p>
                                </div>
                                <div className="col-sm-4">
                                    <img src="./assets/img/contactless-ordering-1200x1303.png" className="blog_img32" title="Connected Retail"/>
                                </div>
                            </div>
                            <h3>Contactless Ordering: The New Norm</h3>
                            <p>Consumers are extremely cautious about the risk of being exposed to the virus. A research done by <a href="https://www.accenture.com/us-en/insights/consumer-goods-services/coronavirus-consumer-behavior-research">Accenture </a>says though the consumer is fearful of their health, even more, fearful of the health of others.</p>
                            <p>Hence, the consumer is looking for alternate solutions that can give them safer retail experience. People are willing to gravitate towards contactless ordering solutions that can be accessed from their own devices. This won’t be a huge shift as people are used to digital shopping and payments via Google Pay, Apple Pay, or scan and pay like PAYTM.</p>
                            <p>According to another research at Dynata, USA has seen the biggest shift in contactless payments, moving from 38% to 46% since the advent of COVID.</p>
                            <h3>CYNTRA’s Contactless: Safe, Speedy and Secure</h3>
                            <p>Cyntra’s QR code-based solution can help businesses to rapidly switch to contactless. The solution is a user-centric web application that can be seamlessly integrated with the backend Point of Sale system. This will not only make it easier for your business to get orders and payments fast and secure, but also provide quick, secure, and convenient checkout experiences for the customer.</p>
                            <p>Serving the need of the customer is primary for any business’ growth. Whether in-store, online or on the go, it is imperative to have a comprehensive strategy that supports the dynamic nature of the industry and allows for scalability in the future.</p>
                            <blockquote><p>Tony Robbins rightly said, “By changing nothing, nothing changes.”&nbsp;Change is the law of life and business.</p></blockquote>
            
                        </div>
                            
                    </section>
       
        
            </main>
                
            </>
    )
}
}