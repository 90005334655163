import React from 'react'

const BlankLayout = ({children}) => {
    return (
        <>
                        {/* <Header {...children.props}/> */}

                {children}
            {/* <Footer {...children.props}/> */}
        </>
    )
}
export default BlankLayout