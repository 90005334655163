import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class PageNotFound extends Component {
    render() {
        return (
            <>
                <main>
                    <div className="container" style={{paddingTop: '100px', paddingBottom: '100px'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <img src="assets/img/404.png" style={{width: '100%'}} />
                                <div className="col-md-12 align-self-center">
                                    <p>The page you are looking for does not exist.
                                    How you got here is a mystery. But you can click the button below
                                    to go back to the homepage.
                                    </p>
                                    <NavLink to={"/"} className="btn green">HOME</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}