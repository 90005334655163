import React, { Component } from 'react';
import Helmet from 'react-helmet';
import MetaTags from 'react-meta-tags';

export default class Integration extends Component {
    render() {
        return (
        <>  
        <head>
            <div class="wrapper">
          <Helmet>
            <title>Integration</title>
          <meta property="og:title" content="CyntraLabs(Integration)"/>
    <meta property="og:description" content="CyntraLabs specializes in Process Integration, System Integration, B2B & EDI Integration, Custom Integration Connectors, Data integration, and Enterprise IT Advisory Services."/>
    <meta property="og:url" content="https://cyntralabs.com/integration"/>
    <meta property="og:image" content="https://cyntra-images.s3.ap-south-1.amazonaws.com/email-templete-img/cyntraLabs.jpeg"/>

          </Helmet>
        </div>
        </head>
                <section id="integration" style={{ padding: '0px'}}>
                    <div className="darkbgblue"></div>
                    <div className="clfinit0">
                        <video id="wall_video" loop="true" autoplay="autoplay" preload="none" playsinline muted>
                            <source src="assets/videos/integration.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="layer">
                        <div className="container" style={{padding: '66px 10px'}}>
                            <div className="row justify-content-center integration">
                                <div className="col-xl-12 animate__animated animate__slideInDown">
                                    <h1 style={{ textAlign: 'left' }}>INTEGRATION SIMPLIFIED</h1>
                                    <p style={{ textAlign: 'left' }}>Powered by knowledge, research and industry wide experience.</p>
                                    <div className="row integrationheader animate__animated animate__zoomIn hideInMobile">
                                    <div className="clfinit01">
                                        <div className="col-lg-2 col-md-6 imgdiv clfinit2" >
                                        <div className="box">
                                            <img className="client_bk img-fluid  shisedeo_logodiv1 "  src="assets/img/Integration/logoExpertise/sap.png"/> 
                                        </div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 mt-4 mt-md-0 imgdiv clfinit4" >
                                        <div className="box qvc_logodiv1">
                                            <img className="client_bk img-fluid "  src="assets/img/Integration/logoExpertise/boomi.png"/> 
                                        </div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 mt-4 mt-lg-0 imgdiv clfinit6" >
                                        <div className="box">
                                            <img className="client_bk img-fluid  shisedeo_logodiv1 "  src="assets/img/Integration/logoExpertise/tibco.png"/>
                                        </div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 imgdiv clfinit8" >
                                            <div className="box">
                                            <img className="client_bk img-fluid "  src="assets/img/Integration/logoExpertise/mulesoft.png"/> 
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 mt-4 mt-md-0 imgdiv clfinit8" >
                                        <div className="box shisedeo_logodiv1">
                                            <img className="client_bk img-fluid "  src="assets/img/Integration/logoExpertise/pega.png"/> 
                                        </div>
                                        </div>
                                        <div className="col-lg-2 col-md-6 mt-4 mt-lg-0 shisedeo_logodiv1 imgdiv clfinit10" >
                                        <div className="box shisedeo_logodiv1">
                                            <img className="client_bk img-fluid "  src="assets/img/Integration/logoExpertise/IBM.png"/>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <main id="main">
                   
                    <section id="cta" className="specializes">
                    <div className="blurbg"></div>
                    <div className="container" style={{padding: '80px 10px'}}>
                        <div className="row">
                            <div className="col-lg-9 text-lg-left animate__animated animate__fadeInLeft">
                                <h2>CYNTRA SPECIALIZES IN</h2>
                                <ul className="specialize12 animate__animated animate__fadeInLeft">
                                <li className=""> <span className="clfinit02">&#10003;</span> Process Integration</li>
                                <li className=""> <span className="clfinit02">&#10003;</span> System Integration</li>
                                <li className=""> <span className="clfinit02">&#10003;</span> B2B & EDI Integration</li>
                                <li className=""> <span className="clfinit02">&#10003;</span> Custom Integration Connectors</li> 
                                <li className=""> <span className="clfinit02">&#10003;</span> Data integration</li>
                                <li className=""> <span className="clfinit02">&#10003;</span> Enterprise IT Advisory Services.</li>
                                </ul>
                        </div>
                        </div>
                    </div>
                    </section>
                    <section id="cta" className="interface">
                    <div className="blurbg"></div>
                    <div className="container" style={{padding: '80px 10px'}}>
                        <div className="row">
                        <div className="col-lg-12 text-lg-left p-d-f specializes_div1   animate__animated animate__fadeInRight">
                            <div className="clfinit03">
                            <h2>PAY PER INTERFACE</h2>
                                <p>We have a transparent, T-shirt sizing pricing model that allows you to precisely budget your projects.</p>
                                <p>You pay only when the interface is deployed.</p>
                                <p>With our industry expertise, we help integrate your business processes and unlock data across heterogeneous and highly  dynamic IT landscapes in a manageable and cost-effective way.
                                </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    </section>
                    </main>
                  
                    <section id="pricing" className="pricing clients client7678">
                    <div className="container">
                    <div className="section-title  animate__animated animate__slideInDown">
                        <h2>OUR INTEGRATION EXPERTISE</h2>
                            <p className="clfinit04">With 240,000+ hours of cross consulting, we work with all major integration tools, both on-premise and on the cloud.</p>
                            <p>We integrate all major ERP and business systems like SAP, Salesforce, ServiceNow, Oracle ERP, SuccessFactors, Workday, Coupa, Concur etc.</p>
                            <p>We come with a proven and robust delivery model that guarantees success and help you build a scalable IT landscape for the future.</p>
                    </div>
                    <div className="row no-gutters clients-wrap clearfix wow fadeInUp">

                        <div className="col-lg-4 col-md-4 col-xs-6">
                            <div className="client-logo animate__animated animate__zoomIn">
                            <img src="assets/img/Integration/logoExpertise/sap.png" className="img-fluid clfinit112" alt="" />
                            </div>

                            <div className="client-logo animate__animated animate__zoomIn">
                            <img src="assets/img/Integration/logoExpertise/boomi.png" className="img-fluid clfinit112" alt=""/>
                            </div>

                            <div className="client-logo animate__animated animate__zoomIn">
                            <img src="assets/img/Integration/logoExpertise/tibco.png" className="img-fluid clfinit112" alt="" />
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-4 col-xs-6">

                            <div className="client-logo animate__animated animate__zoomIn">
                            <img src="assets/img/Integration/logoExpertise/mulesoft.png" className="img-fluid clfinit112" alt="" />
                            </div>
                        
                            <div className="client-logo animate__animated animate__zoomIn">
                            <img src="assets/img/Integration/logoExpertise/pega.png" className="img-fluid clfinit112" alt="" />
                            </div>

                            <div className="client-logo animate__animated animate__zoomIn">
                            <img src="assets/img/Integration/logoExpertise/IBM.png" className="img-fluid clfinit112" alt="" />
                            </div>
                            
                        </div>

                        <div className="col-lg-4 col-md-4 col-xs-6">

                            <div className="client-logo animate__animated animate__zoomIn">                           
                            <div className="customer text-center clfinit05" >
                                <div className="counter_bardiv33 counter_int2">
                                    <p className="counter-count counter_int2">2000</p><span className="counter_span22 counter_int2">+</span>
                                </div>
                                <p className="customer-p counter121">Interfaces Deployed</p>
                            </div>
                            </div>

                            <div className="client-logo animate__animated animate__zoomIn">
                           
                            <div className="customer text-center">
                                <div className="counter_bardiv33 counter_int2">
                                    <p className="counter-count counter_int2">9</p><span className="counter_span22 counter_int2">.4/10</span>
                                </div>
                                <p className="customer-p counter121">Customer Satisfaction Rating</p>
                            </div>
                            </div>
                          

                            
                        
                            <div className="client-logo animate__animated animate__zoomIn">
                          
                            <div className="customer text-center clfinit05" >
                                <div className="counter_bardiv33 counter_int2">
                                    <p className="counter-count counter_int2">3</p><span className="counter_span22 counter_int2">X</span>
                                </div>
                                <p className="customer-p counter121">Faster Implementation</p>
                            </div>
                        </div>
                        </div>
                        </div>

                    </div>
                    </section>

                    <section id="about" className="about clfinit11" >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 animate__animated animate__fadeInLeft">
                                    <div className="discoverLeft">
                                            <p className="title"><b> Why Us?</b></p>
                                            <p>Every organization undergoes transformation, journeying through growth, expanding 
                                                organically or through mergers & acquisitions; which demands adding new systems to the IT landscape. </p>
                                            
                                            <p>We are committed to helping organizations ensure business continuity, unlock the power of data, zero-downtime system integration, and use technology to transform.</p>
                                                    <p>Let's partner for success!</p>
                                                    <div className="pt-3 mb-5 dismore67  animate__animated animate__slideInDown">
                                                        <a href="#contact" className="discovermore_btn1">Discover More</a>
                                                    </div>
                                                    
                                    </div>
                                    </div>
                                    <div className="col-lg-6  animate__animated animate__fadeInRight">
                                    <div className="discoverRightdiv">
                                                        <div className="discoverRight">
                                                        <p className="title"> <b>Leverage</b> Existing IT Landscape</p>
                                                <p>CYNTRA provides solutions that leverage existing IT landscape investments. We transform your application landscape to be scalable and agile, enabling developers to quickly build new applications and integrate with existing solutions.</p>
                                                <p>We provide you with an extensive architectural review and improvement to stay ahead of the rest.</p>
                                                							
                                                </div>
                                                <div className="discoverRightdiv">
                                                        <div className="discoverRight">
                                                            <p className="title"> <b>Experience   </b>Robust Integrations </p>
                                                            <p>With CYNTRA, system integration is a robust process, be it a handful of interfaces or a 
                                                    full-blown integration project. 
                                                    </p>
                                                    <p>We make sure your system has exhaustive connection points, 
                                                    providing the agility and the ability to quickly do integrations at the pace of your business needs.
                                                    </p>
                                                </div>
                                                <div className="discoverRightdiv">
                                                        <div className="discoverRight">
                                                            <p className="title"> <b>Achieve  </b>Intended Business Outcomes</p>
                                                            <p>Understanding your goals, operations and strategy is a must. More than that, we focus on understanding:</p>
                                                            <ul>
                                                                <li><i className="ri-check-line outcomes_points1"></i> Your current challenges.</li>
                                                                <li><i className="ri-check-line outcomes_points1"></i> What you’ve tried, and what has or hasn’t worked.</li>
                                                                <li><i className="ri-check-line outcomes_points1"></i> Your overall goals and what you aspire to achieve and why.</li>
                                                    </ul>
                                                    <p>CYNTRA delivers the best for your business.</p>
                                                <div> 								
                                                </div>
                                                </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </div>
                            </div>
                    </section>

                <section id="cta" className="operate">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-lg-left ">
                            <h2 className="c">HOW WE OPERATE</h2>
                            </div>
                        </div>
                    </div>
                    
                    </section>

                    <div className="intrigation_pdf">
                    <a href="Cyntra-IntegrationServices.pdf" target="blank" >Integration</a>
                    </div>
                    </>
                    
                        )
                    }
                }