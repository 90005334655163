import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import "./style.css";
import src from "../Component/assets/Breez_Food.mp4";
import ReactPlayer from "react-player";
import file from "../Component/assets/Breez_Deck123.pdf";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { ReactVideo } from "reactjs-media";
import src1 from "../Component/assets/Retail_Checkout.mp4";
import src2 from "../Component/assets/BreezHotelCheck-In.mp4";
import src3 from "../Component/assets/Breez_Deck .mp4";
import { Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { NavLink } from "react-router-dom";

import { useState } from "react";

////import React, { Component } from 'react';
// import Carousel from "react-elastic-carousel";
// import { ReactVideo, YoutubePlayer } from 'reactjs-media';
// const breakPoints = [
//     { width: 1, itemsToShow: 1 },
//     { width: 550, itemsToShow: 2 },
//     { width: 768, itemsToShow: 3 },
//     { width: 1200, itemsToShow: 3 },
// ];
// const links = [
//     {
//         "poster": "./gallery/Media/img/videos-img/1.jpeg",
//         "src": "./gallery/Media/videos/wendys-KIOSKFULLVIDEO.mp4"
//     },
//     {
//         "poster": "./gallery/Media/img/videos-img/2.jpeg",
//         "src": "./gallery/Media/videos/Cravrr-POS.mp4"
//     },
//     {
//         "poster": "./gallery/Media/img/videos-img/3.jpeg",
//         "src": "./gallery/Media/videos/Dark Ketchen.mp4"
//     },
//     {
//         "poster": "./gallery/Media/img/videos-img/4.jpg",
//         "src": "./gallery/Media/videos/ralphloren.mp4"
//     },
//     {
//         "poster": "./gallery/Media/img/videos-img/5.jpeg",
//         "src": "./gallery/Media/videos/w.mp4"
//     },
//     {
//         "poster": "./gallery/Media/img/videos-img/6.jpeg",
//         "src": "./gallery/Media/videos/Cravrr.mp4"
//     },
//     {
//         "poster": "./gallery/Media/img/videos-img/7.jpg",
//         "src": "./gallery/Media/videos/poswithassistant1.mp4"
//     },
//     {
//         "poster": "./gallery/Media/img/videos-img/8.jpg",
//         "src": "./gallery/Media/videos/poswithassistant2.mp4"
//     },
//     {
//         "poster": "./gallery/Media/img/videos-img/9.jpg",
//         "src": "./gallery/Media/videos/contactless_solution.mp4"
//     }
// ]
//  // export default class Demo extends Component {
// //     render() {
// //         return (
// //            <>
// //            <main id="main">
// //                 <section className="aboutheader_main3" >
// //                     <div className="container">
// //                         <h2 className="about_head1">DEMO</h2>
// //                         <p className="heading_para1">Delivering Innovation and Excellence beyond Expectation </p>
// //                     </div>
// //                 </section>

// //                 <section id="demo" className="mydiv portfolio  animate__animated animate__fadeInUp" style={{padding: '0px'}}>
// //                     <div id="mydiv" className="row">
{
  /* <Carousel breakPoints={breakPoints}>
                            {links.map((item,index) => (
                                <ReactVideo
                                    src={item.src}
                                    poster={item.poster}
                                    height={350} />
                            ))}
                        </Carousel> */
}
// //                         <iframe src="gallery/index.html" width="100%" height="420" frameBorder="0">Browser not compatible.</iframe>
// //                     </div>
// //                     <p className="showdemo1" id="our-demo"></p>

//  //                </section>
//  //            </main>
// //            </>
// //         )
// //     }
////  }

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));

const onButtonClick = () => {
  fetch(file).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);

      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "BreezPitchDeck.pdf";

      alink.click();
    });
  });
};

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.9rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};

const Breez = () => {
  const [pas, setPas] = useState(false);
  const [vsrc, setVsrc] = useState(src);
  const [post1, setPost1] = useState("gallery/V.html");
  const [isActive, setIsActive] = useState(true);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);

  const handleClick = () => {
    setIsActive(true);
    setIsActive1(false);
    setIsActive2(false);
    setIsActive3(false);
    console.log("False");
    setPost1("gallery/V.html");
    setVsrc(src);
  };
  const handleClick1 = () => {
    setIsActive(false);
    setIsActive1(true);
    setIsActive2(false);
    setIsActive3(false);
    setVsrc(src1);
    console.log("False");
  };
  const handleClick2 = () => {
    setIsActive(false);
    setIsActive1(false);
    setIsActive2(true);
    setIsActive3(false);
    setVsrc(src2);
    setPost1("gallery/V.html");
    console.log("False");
  };
  const handleClick3 = () => {
    setIsActive(false);
    setIsActive1(false);
    setIsActive2(false);
    setIsActive3(true);
    setVsrc(" ");
    setPost1(" ");
    console.log("true");
  };
  const onButtonClickpas = () => {
    console.log("hello");
    setPas(true);
  };

  // render() {
  //     const href = window.location.href
  //     let checkHash = false;
  //     if ((href.substring((href).lastIndexOf('/') + 1)).split("#")[1]) {
  //         checkHash = "#" + (href.substring((href).lastIndexOf('/') + 1)).split("#")[1]

  //     }

  return (
    <>
      <>
        <header id="header" className="fixed-top ">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div
                className="col-xl-9 col-sm-12 d-flex align-items-center justify-content-between"
                style={{ minWidth: "92%" }}
              >
                <nav className="nav-menu d-lg-block anrsb">
                  <div
                    className="showInMobile"
                    style={{ padding: "0px 0px 20px 0px", borderBottom: "0px" }}
                  >
                    <img
                      src="assets/img/Logo.png"
                      alt=""
                      className="header-logo img-fluid"
                      style={{ borderBottom: "0px" }}
                    />
                    <button
                      type="button"
                      className="mobile-nav-toggle2 d-lg-none anrsb-nav-act"
                      style={{ top: "25px" }}
                    >
                      <i
                        className="icofont-close"
                        style={{ color: "#40a9f2" }}
                      ></i>
                    </button>
                  </div>
                  <ul>
                    <li className="" style={{ marginLeft: "0px" }}>
                      <NavLink className="mob-l" exact to={"/"}>
                        Home
                      </NavLink>
                    </li>
                  </ul>
                </nav>
                <NavLink exact className="aclahl" to={"/"}>
                  <img
                    src="assets/img/Logo.png"
                    alt=""
                    className="header-logo img-fluid"
                  />
                </NavLink>
                <button
                  type="button"
                  className="mobile-nav-toggle d-lg-none showInMobile anrsb-nav-act"
                >
                  <i className="icofont-navigation-menu"></i>
                </button>
              </div>
            </div>
          </div>
        </header>
      </>
      <section
        id="retail"
        className="d-flex flex-column justify-content-center clfretabcdbreez"
      >
        <div className="row justify-content-center retail">
          <Box sx={{ flexGrow: 1 }} pt={2}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mb={5}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                pl={2}
                pr={2}
                pt={5}
                style={{
                  textAlign: "left",
                }}
                pb={2}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h3"
                    style={{
                      textAlign: "left",
                      color: "black",

                      fontFamily: "sans-serif",
                      fontWeight: "bolder",
                    }}
                  >
                    BREEZ PITCH DECK
                  </Typography>
                </ThemeProvider>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    fontSize: "1.5rem",
                    color: "#38a1f7",
                    textAlign: "left",
                    fontFamily: "sans-serif",
                    fontWeight: "550",
                  }}
                >
                  30 Seconds Checkout
                </Grid>
                <div className="ABCD!">
                  <h4
                    style={{
                      fontSize: "1rem",
                      marginTop: "2rem",
                      color: "#a1a1a1",
                      textAlign: "left",
                      fontFamily: " sans-serif",
                      fontWeight: "lighter",
                    }}
                  >
                    A streamlined and informative
                    <br />
                    overview contailning everything you
                    <br />
                    need to know about our product.
                  </h4>
                </div>

                <Button
                  href="#Demo12"
                  className="back1"
                  variant="contained"
                  onClick={onButtonClickpas}
                  style={{
                    color: "white",
                    fontFamily: "sans-serif",
                    fontSize: "1rem",
                    backgroundColor: "#38a1f7",
                    paddingLeft: "0.9",
                    paddingRight: "0.9",
                    paddingTop: "0.6",
                    paddingBottom: "0.6",
                    marginTop: "1rem",
                    borderRadius: "5rem",
                  }}
                >
                  Demo
                </Button>
                <Button
                  variant="contained"
                  onClick={onButtonClick}
                  href="#Hom12"
                  style={{
                    color: "white",
                    fontFamily: "sans-serif",
                    fontSize: "1rem",
                    backgroundColor: "#38a1f7",
                    paddingLeft: "0.9",
                    paddingRight: "0.9",
                    paddingTop: "0.6",
                    paddingBottom: "0.6",
                    marginTop: "1rem",
                    marginLeft: "1rem",
                    borderRadius: "5rem",
                  }}
                >
                  Download
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <ReactPlayer
                  style={{ boxShadow: "3px 3px 3px 3px #9E9E9E" }}
                  className="react-player"
                  width="100%"
                  height="100%"
                  stop={pas}
                  url={src3}
                  // color: isActive3 ? 'white' : '#504949',

                  // config={{
                  //     file: {

                  //         attributes: {
                  //             poster:  isActive3 ? "./assets/img/Air.png": "./assets/img/img.png",

                  //         }
                  //     }
                  // }}

                  controls
                />
                {/* <iframe src="gallery/sliderdemo.html"  width="100%" height="420" frameBorder="0">Browser not compatible.</iframe> */}
              </Grid>
            </Grid>
          </Box>
        </div>
      </section>

      <div id="Demo12">
        <section
          id="retail"
          className="d-flex flex-column justify-content-center clfretabcd1"
        >
          <Box sx={{ flexGrow: 1 }} pt={1} justifyContent="center">
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={8}
              style={{
                textAlign: "left",
                fontSize: "3rem",
                color: "black",
                fontFamily: "sans-serif",
                fontWeight: "bold",
              }}
            >
              <p>Demo</p>
            </Grid>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                fontSize: "1rem",
                color: "#848790",
                textAlign: "center",
                fontFamily: "sans-serif",
              }}
            >
              Watch our products in action and see their full potential
              demonstration in these videos.
            </Grid>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={1}
            >
              <Grid item xs={12} sm={12} md={5} lg={5} p={2}>
                {/* <Grid container justifyContent='center' xs={12} sm={12} md={12} lg={12} mt={1}>
                              <Grid item xs={12} sm={12} md={5} lg={5}  >
                                   <Button variant="contained">Mpos</Button>

                                  </Grid>
                                  <Grid item xs={12} sm={12} md={5} lg={5}   >
                                  <Button variant="contained">Retail</Button>

                                  </Grid>
                            </Grid> */}

                {/* <Button fullWidth    style={{
                                      padding: "1.2rem", marginBottom: "1.2rem", marginTop: "2rem", fontWeight: "bold", borderRadius: "1rem", backgroundColor: isActive ? '#37a1f7' : 'White',
                                      color: isActive ? 'white' : '#504949', border: isActive ? "2px solid #37a1f7" : "2px solid #37a1f7"
                                  }} onClick={handleClick}>Food Ordering</Button>
                                  <Button fullWidth style={{
                                      padding: "1.2rem", marginBottom: "1.2rem", fontWeight: "bold", borderRadius: "1rem", backgroundColor: isActive1 ? '#37a1f7' : 'White',
                                      color: isActive1 ? 'white' : '#504949', border: isActive1 ? "2px solid #37a1f7" : "2px solid #37a1f7"
                                  }} onClick={handleClick1}>Retail Checkout</Button>
                                  <Button fullWidth style={{
                                      padding: "1.2rem", marginBottom: "1.2rem", fontWeight: "bold", borderRadius: "1rem", backgroundColor: isActive2 ? '#37a1f7' : 'White',
                                      color: isActive2 ? 'white' : '#504949', border: isActive2 ? "2px solid #37a1f7" : "2px solid #37a1f7"
                                  }} onClick={handleClick2}>Hotel & Services</Button>
                                  <Button  fullWidth style={{
                                      padding: "1.2rem", marginBottom: "1.2rem", fontWeight: "bold", borderRadius: "1rem", backgroundColor: isActive3 ? '#37a1f7' : 'White',
                                      color: isActive3 ? 'white' : '#504949', border: isActive3 ? "2px solid #37a1f7" : "2px solid #37a1f7"
                                  }} onClick={handleClick3}>Airport Check-in</Button> */}

                <ReactPlayer
                  className="react-player"
                  width="100%"
                  height="100%"
                  url={src}
                  // color: isActive3 ? 'white' : '#504949',

                  config={{
                    file: {
                      attributes: {
                        poster: "./assets/demo/food.png",
                      },
                    },
                  }}
                  controls
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} p={2}>
                <ReactPlayer
                  className="react-player"
                  width="100%"
                  height="100%"
                  url={src1}
                  // color: isActive3 ? 'white' : '#504949',

                  config={{
                    file: {
                      attributes: {
                        poster: "./assets/demo/retail.png",
                      },
                    },
                  }}
                  controls
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid item xs={12} sm={12} md={5} lg={5} p={2}>
                <ReactPlayer
                  className="react-player"
                  width="100%"
                  height="100%"
                  url={src2}
                  // color: isActive3 ? 'white' : '#504949',

                  config={{
                    file: {
                      attributes: {
                        poster: "./assets/demo/hotelcheckin.png",
                      },
                    },
                  }}
                  controls
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} p={2}>
                <ReactPlayer
                  className="react-player"
                  width="100%"
                  height="100%"
                  url={" "}
                  // color: isActive3 ? 'white' : '#504949',

                  config={{
                    file: {
                      attributes: {
                        poster: "./assets/demo/airport.png",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              p={2}
            >
              <NavLink exact activeClassName="active" to={"Demo"}>
                <Button
                  variant="outlined"
                  style={{
                    textTransform: "capitalize",
                    color: "black",
                    borderBlockColor: "black",
                    fontWeight: "bold",
                  }}
                >
                  Other Demos...
                </Button>
              </NavLink>
            </Grid>
          </Box>
        </section>
      </div>
    </>
  );
};
export default Breez;
