import React, { Component } from 'react';

export default class PrivacyPolicy extends Component {
    render() {
        return (
            <>
                <main id="main">
                    <section id="breadcrumbs" className="breadcrumbs">
                        <div className="cont">
                            <h2>Privacy Policy</h2>
                        </div>
                    </section>
                    <section className="inner-page">
                        <div className="cont">
                            <h3>Introduction</h3>
                            <p>CYNTRA LABS (“we” or “us” or “our”) respects the privacy of its users (“user” or “you”).
                                This Privacy Policy explains how we collect, use, disclose, and safeguard your information when
                                you visit our website www.cyntralabs.com, including any other media form, media channel, or mobile
                                website, or connected thereto. Please read this privacy policy carefully. If you do not agree with
                                the terms of this privacy policy, please do not access the site.
                            </p>
                            <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason.
                                We will alert you about any changes by updating the “Last Updated” date of this Privacy Policy.
                                Any changes or modifications will be effective immediately upon posting the updated Privacy Policy
                                on the Site, and you waive the right to receive specific notice of each such change or
                                modification.</p>
                            <p>You are encouraged to periodically review this Privacy Policy to stay informed of updates.
                                You will be deemed to have been made aware of, will be subject to, and will be deemed to have
                                accepted the changes in any revised Privacy Policy by your continued use of the Site after the
                                date such revised Privacy Policy is posted</p>
                            <p>The policy sets out the different areas where user privacy is concerned and outlines the obligations &
                                requirements of the users, the website and website owners. Furthermore the way this website processes,
                                stores and protects user data and information will also be detailed within this policy.</p>
                            <h3>The Website</h3>
                            <p>This website and its owners take a proactive approach to user privacy and ensure the necessary
                                steps are taken to protect the privacy of its users throughout their visiting experience. </p>
                            <h3 id="cookie-policy">Cookie Policy</h3>
                            <p>This website uses cookies to better the users experience while visiting.
                                Where applicable this website uses a cookie control system allowing the user on their first
                                visit to the website to allow or disallow the use of cookies on their computer / device.
                                This complies with recent legislation requirements for websites to obtain explicit consent
                                from users before leaving behind or reading files such as cookies on a user’s
                                computer / device.</p>
                            <p>These cookies are used to store information, such as what time your current visit occurred,
                                whether you have been to the site before, and what site referred you to the web page.</p>
                            <p>These cookies contain no personally identifiable information but they will use your computer’s
                                IP address to know from where in the world you are accessing the Internet. Google may transfer
                                this information to third-parties where required to do so by law, or where such third-parties
                                process the information on Google’s behalf.</p>
                            <h3>Opt-out</h3>
                            <p>In order to provide website visitors with more choice on how data is collected by Google Analytics,
                                Google has developed the Google Analytics Opt-out Browser Add-on. The add-on communicates with the
                                Google Analytics JavaScript (ga.js) to stop data being sent to Google Analytics. The Google
                                Analytics Opt-out Browser Add-on does not affect usage of the website in any other way. </p>
                            <p>For more information on the usage of cookies by Google Analytics please see the Google website.
                                A link to the privacy advice for this product is provided below for your convenience.</p>
                            <h3>Disabling Cookies</h3>
                            <p>If you would like to restrict the use of cookies you can control this in your Internet browser. </p>
                            <h3>Contact & Communication</h3>
                            <p>Users contacting this website and/or its owners do so at their own discretion and provide any
                                such personal details requested at their own risk. Your personal information is kept private
                                and stored securely until a time it is no longer required or has no use, as detailed in the
                                Data Protection Act 1998. Every effort has been made to ensure a safe and secure form to
                                email submission process but advice users using such form to email processes that they do
                                so at their own risk.</p>
                            <p>This website and its owners use any information submitted to provide you with further 
                                information about the products / services they offer or to assist you in answering any 
                                questions or queries you may have submitted. This includes using your details to subscribe 
                                you to any email newsletter program the website operates but only if this was made clear 
                                to you and your express permission was granted when submitting any form to email process. 
                                Or whereby you the consumer have previously purchased from or enquired about purchasing
                                from the company a product or service that the email newsletter relates to. This is by
                                no means an entire list of your user rights in regard to receiving email marketing 
                                material. Your details are not passed on to any third parties.</p>
                            <h3>External Links</h3>
                            <p>Although this website only looks to include quality, safe and relevant external links, users are 
                                advised adopt a policy of caution before clicking any external web links mentioned throughout this
                                website.</p>
                            <p>The owners of this website cannot guarantee or verify the contents of any externally linked
                                website despite their best efforts. Users should therefore note they click on external
                                links at their own risk and this website and its owners cannot be held liable for any
                                    damages or implications caused by visiting any external links mentioned.</p>
                            <h3>Social Media Platforms</h3>
                            <p>Communication, engagement and actions taken through external social media platforms that this website and 
                                its owners participate on are custom to the terms and conditions
                                as well as the privacy policies held with each social media platform respectively.</p>
                            <p>Users are advised to use social media platforms wisely and communicate / engage upon 
                                them with due care and caution in regard to their own privacy and personal details. 
                                Neither this website nor its owners will ever ask for personal or sensitive information 
                                through social media platforms and encourage users wishing to discuss sensitive details to
                                contact them through primary communication channels such as by telephone or email.</p>
                            <p>This website may use social sharing buttons which help share web
                            content directly from web pages to the social media platform in question.
                            Users are advised before using such social sharing buttons that they do so at their own
                            discretion and note that the social media platform may track and save your request to share a
                                web page respectively through your social media platform account.</p>
                            <h3>Shortened Links in Social Media</h3>
                            <p>This website and its owners through their social media platform accounts may share web links to relevant 
                            web pages. By default some social media platforms shorten lengthy urls or web addresses
                            (this is an example: https://bit.ly/zyVUBo).</p>
                            <p>Users are advised to take caution and good judgment before clicking any shortened urls published on 
                            social media platforms by this website and its owners. Despite the best efforts to ensure only genuine 
                            urls are published many social media platforms are prone to spam and hacking and therefore this website
                            and its owners cannot be held liable for any damages or implications caused by visiting any 
                            shortened links.</p>

                            <h3> Security of Your Information</h3>
                            <p>We use administrative, technical, and physical security measures to help protect your personal information. 
                            While we have taken reasonable steps to secure the personal information you provide to us, please be aware 
                            that despite our efforts, no security measures are perfect or impenetrable, and no method of data 
                            transmission can be guaranteed against any interception or other type of misuse. Any information
                            disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, 
                            we cannot guarantee complete security if you provide personal information.</p>
                            <h3>Policy for Children</h3>
                            <p>We do not knowingly solicit information from or market to children under the age of 13. If you become 
                            aware of any data we have collected from children under age 13, please contact us using the contact 
                            information provided below.</p>
                            <h3>Controls for Do-Not-Track Features</h3>
                            <p>Most web browsers and some mobile operating include a Do-Not-Track (“DNT”) feature or setting you can 
                            activate to signal your privacy preference not to have data about your online browsing activities 
                            monitored and collected. No uniform technology standard for recognizing and implementing DNT signals 
                            has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism
                            that automatically communicates your choice not to be tracked online. If a standard for online tracking
                            is adopted that we must follow in the future, we will inform you about that practice in a revised version
                            of this Privacy Policy. Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”) 
                            feature or setting you can activate to signal your privacy preference not to have data about your 
                            online browsing activities monitored and collected. If you set the DNT signal on your browser, 
                            we will respond to such DNT browser signals.</p>
                            <h3>Contact Us</h3>
                            <p>If you have questions or comments about this Privacy Policy, please contact us below.</p>

                        </div>
                    </section>
                </main>
            </>
        )
    }
}