import React from 'react';
import { Route } from 'react-router-dom';

const AppRoute = ({ component: Component, layout: Layout, show: show, ...rest}) => {

    const checkPrivileges = () => {
        return show
    }

    return(
        <Route {...rest}
            render = { props => (
                <Layout {...rest} checkPrivileges={checkPrivileges}>
                    <Component {...props} checkPrivileges={checkPrivileges} />
                </Layout>
            )}
        />
    )
};

export default AppRoute;