import React from "react";
import {
  Card,
  Grid,
  List,
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormGroup,
} from "@material-ui/core";
import { Input, Button } from "@mui/material";
import pic1 from "../assets/FoodPage/ss.png";
import pic2 from "../assets/FoodPage/3890485.jpg";
import pic3 from "../assets/FoodPage/Screenshot_2023-07-21_at_12.33.06_PM-removebg-preview.png";
import pic4 from "../assets/FoodPage/Screenshot_2023-07-25_at_1.55.25_PM-removebg-preview.png";
import pic5 from "../assets/FoodPage/5374800-removebg-preview.png";
import pic6 from "../assets/FoodPage/6155808-removebg-preview (1).png";
import pic7 from "../assets/FoodPage/MicrosoftTeams-image (51) (1).png";
import pic8 from "../assets/FoodPage/MicrosoftTeams-image (21) (1).png";
import pic9 from "../assets/FoodPage/Group 1286.png";
import pic10 from "../assets/FoodPage/image (1).png";
import pic11 from "../assets/FoodPage/image (1)1.png";

import pic12 from "../assets/FoodPage/credit_card_FILL0_wght200_GRAD0_opsz24.svg";
import pic13 from "../assets/FoodPage/inventory_2_FILL1_wght200_GRAD0_opsz24.svg";
import pic14 from "../assets/FoodPage/attach_money_FILL1_wght300_GRAD0_opsz24.svg";
import pic15 from "../assets/FoodPage/restaurant_FILL1_wght300_GRAD0_opsz24.svg";
import pic16 from "../assets/FoodPage/shopping_bag_FILL1_wght300_GRAD0_opsz24.svg";
import pic17 from "../assets/FoodPage/monitoring_FILL1_wght300_GRAD0_opsz24.svg";

import icon from "../assets/FoodPage/Icons/bar.png";
import icon1 from "../assets/FoodPage/Icons/cafe.png";
import icon2 from "../assets/FoodPage/Icons/croissant.png";
import icon3 from "../assets/FoodPage/Icons/fast casual.png";
import icon4 from "../assets/FoodPage/Icons/large chain.png";
import icon5 from "../assets/FoodPage/Icons/pizzeria.png";
import icon6 from "../assets/FoodPage/Icons/quick service.png";
import icon7 from "../assets/FoodPage/Icons/popup.png";
import icon8 from "../assets/FoodPage/Icons/MicrosoftTeams-image (45) (1).png";
import icon9 from "../assets/FoodPage/Icons/MicrosoftTeams-image (46) (1).png";
import icon10 from "../assets/FoodPage/Icons/MicrosoftTeams-image (47) (1).png";
import icon11 from "../assets/FoodPage/Icons/MicrosoftTeams-image (48) (1).png";
import icon12 from "../assets/FoodPage/Icons/MicrosoftTeams-image (49) (1).png";
import icon13 from "../assets/FoodPage/Icons/MicrosoftTeams-image (50) (1).png";

import breez from "../assets/FoodPage/breez Explore (1).png";
import astra from "../assets/FoodPage/astra explore (2).png";
import leap from "../assets/FoodPage/MicrosoftTeams-image (44) (1).png";
import swyft from "../assets/FoodPage/MicrosoftTeams-image (43) (1).png";
import digital from "../assets/FoodPage/digital signage explore (1).png";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "2.25rem",
    paddingBottom: "2.25rem",
  },
  inputbtn: {
    borderRadius: "0.5rem",
    backgroundColor: "#F0F4F3",
    height: "3rem",
    padding: "0.3rem",
    paddingLeft: "1rem",
  },
}));

export default function Fnb() {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      style={{ backgroundColor: "#F9FAFB" }}
    >
      <Grid
        container
        justifyContent="left"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ backgroundColor: "#0A0F25" }}
      >
        <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>

        <Grid item xs={10} sm={10} md={6} lg={6} style={{ marginTop: "10rem" }}>
          <Typography
            align="left"
            style={{ fontWeight: "bold", color: "white" }}
          >
            FOOD & BEVERAGE
          </Typography>
          <Typography
            align="left"
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "40px",
              lineHeight: "2.8rem",
              paddingBottom: "1.5rem",
            }}
          >
            Bringing AI-Driven Transformation in Food & Beverage Industry
          </Typography>{" "}
          <Typography
            align="left"
            style={{
              color: "white",
              fontSize: "15px",
            }}
          >
            Unlock new levels of efficiency, innovation, and profitability in
            your F&B business with our state-of-the-art tools, including Breez,
            our AI-powered self-service kiosk. Our cutting-edge retail solutions
            not only accelerate order processing to 30-second checkouts, but
            also offer personalized menu recommendations based on customer
            preferences. Cyntra technologies will help you make more intelligent
            data analytics and streamline order management, speedily.
          </Typography>{" "}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "1rem", paddingBottom: "3rem" }}
          >
            <Grid
              item
              align="left"
              xs={10}
              sm={10}
              md={4}
              lg={4}
              style={{ paddingTop: "2rem" }}
            >
              <Button
                href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                style={{
                  color: "white",
                  backgroundColor: "#3F9AF3",
                  fontWeight: "bold",
                  borderRadius: "10rem",
                  padding: "0.7rem",
                }}
              >
                Schedule a call
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          justifyContent="center"
          xs={10}
          sm={10}
          md={4}
          lg={4}
          style={{ marginTop: "6rem", paddingBottom: "2rem" }}
        >
          <img src={pic8} style={{ width: "60%" }} />
        </Grid>
        <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>
      </Grid>
      <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>
      <Grid item justifyContent="center" xs={10} sm={10} md={10} lg={10}>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1rem",
              }}
            >
              The Right Fit for Your Eateries
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <img src={icon6} style={{ width: "20%" }}></img>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    padding: "1rem",
                  }}
                >
                  Quick Service
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <img src={icon5} style={{ width: "20%" }}></img>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    padding: "1rem",
                  }}
                >
                  Pizzeria
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <img src={icon3} style={{ width: "20%" }}></img>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    padding: "1rem",
                  }}
                >
                  Fast Casual
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <img src={icon2} style={{ width: "20%" }}></img>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    padding: "1rem",
                  }}
                >
                  Patisseries
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <img src={icon} style={{ width: "20%" }}></img>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    padding: "1rem",
                  }}
                >
                  Bars & Breweries
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <img src={icon4} style={{ width: "20%" }}></img>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    padding: "1rem",
                  }}
                >
                  Large Chains
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <img src={icon7} style={{ width: "20%" }}></img>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    padding: "1rem",
                  }}
                >
                  Pop-up Restaurants
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <img src={icon1} style={{ width: "20%" }}></img>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    padding: "1rem",
                  }}
                >
                  Cafes
                </Typography>
              </Grid>{" "}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            backgroundColor: "#0A0F25",
            padding: "2rem",
            borderRadius: "2rem",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Accelerate Your Business Growth
            </Typography>{" "}
            <Typography
              style={{
                color: "white",
              }}
            >
              Our innovative technology solutions are tailored to meet the
              unique needs of businesses of all sizes.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button
              href="https://cyntra.ai/contactus"
              style={{
                marginTop: "1.5rem",
                color: "white",
                backgroundColor: "#3F9AF3",
                fontWeight: "bold",
                borderRadius: "10rem",
                padding: "0.7rem",
              }}
            >
              Contact us
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic1} style={{ width: "50%" }}></img>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "30px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              Gamified Ordering Journey{" "}
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Goal-based food orders stimulate customer engagement through
              attractive designs and order representations. Customization of
              food items and add-ons are disclosed progressively through
              interactive gamified user interfaces. Loyalty points are also
              added to the user’s profile whenever a milestone has been reached.
            </Typography>{" "}
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "30px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              30-Second Checkouts
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Through voice activation with AI, responsive touch interface, and
              smart face scan, dining and takeaway orders are sped up. This
              feature-rich order journey ensures 30-second checkouts. Orders are
              delivered swiftly through simple modification choices based on
              individual preferences, past selections, and tailored
              recommendations.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic2} style={{ width: "50%" }}></img>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic3} style={{ width: "50%" }}></img>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "30px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              Voice Activated Orders With AI
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              The voice activation module interprets and processes spoken
              commands to ensure effortless order placement. It guarantees
              precision by using AI to rectify semantic misinterpretations,
              adapting to various accents and speech patterns. This results in
              an efficient, hands-free, and user-friendly ordering experience.
            </Typography>{" "}
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "30px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              Customized Orders
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Order customization is made simple. Whether it's adding extra
              toppings, choosing a healthier option, or avoiding certain
              allergens, our self-service kiosk provides a system where a myriad
              of options can be chosen. The device also remembers past orders
              and preferences of customers, suggesting dishes they might enjoy,
              making the ordering process seamless and highly customizable.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic4} style={{ width: "50%" }}></img>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic5} style={{ width: "50%" }}></img>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "30px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              Better Customer Service
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              The self-service kiosk uses user profiles to enhance
              personalization, tailoring orders to individual tastes and dietary
              needs. It suggests food items based on past orders, recommends
              repeatable past food items, and applies preferred payment methods.
              By tracking a customer's purchases, custom rewards and incentives
              can be created to encourage repeat visits and foster customer
              loyalty.
            </Typography>{" "}
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "30px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              Customer Loyalty Program
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Incentives are tailored based on individual viewing habits, genre
              preferences, and frequency of visits. For instance, a patron
              frequently attending sci-fi movies might receive early-bird
              booking options for upcoming releases in the same genre. This
              level of personalization not only enhances the movie-going
              experience but also fosters brand loyalty, driving repeat visits
              and higher engagement.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic6} style={{ width: "50%" }}></img>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "#3F9AF3",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
                paddingTop: "1.5rem",
              }}
            >
              Elevate Your Restaurant's Potential
            </Typography>{" "}
            <Typography
              align="center"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              Transform restaurant operations with a comprehensive suite of
              solutions, including advanced POS, purchasing controls, inventory
              management, and accounting essentials.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="left"
              style={{ margin: "1rem" }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid
                item
                align="center"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={pic12} style={{ width: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Purchasing
                </text>
                <Typography>
                  Spend less time on paperwork and more on growing your business
                </Typography>
              </Grid>
              <Grid
                item
                align="center"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={pic13} style={{ width: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Inventory
                </text>
                <Typography>
                  Ensure right amount of stock at the right time, and maximize
                  profits
                </Typography>
              </Grid>
              <Grid
                item
                align="center"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={pic14} style={{ width: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Accounting
                </text>
                <Typography>
                  Manage finances, and maintain compliance with tax laws and
                  regulations
                </Typography>
              </Grid>
              <Grid
                item
                align="center"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={pic15} style={{ width: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Menu Engineering
                </text>
                <Typography>
                  Create a menu that balances cost, profits and customer
                  satisfaction{" "}
                </Typography>
              </Grid>
              <Grid
                item
                align="center"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={pic16} style={{ width: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Loyalty Program
                </text>
                <Typography>
                  Reward your customers for repeat business and build a loyal
                  customer base
                </Typography>
              </Grid>
              <Grid
                item
                align="center"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={pic17} style={{ width: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Analytics & Reporting
                </text>
                <Typography>
                  Get real-time insights into your restaurant's performance{" "}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={4} lg={4}>
              <img src={pic10} style={{width:"100%"}} />

              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Help Your Pop-Up Store Dominate Flash Retailing With Cyntra’s
              Cutting-Edge Products
            </Typography>{" "}
            <Typography
              align="center"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              There are many roadblocks to running a successful pop-up store.
              The biggest of them all is the lack of a business-friendly
              infrastructure, leading to missed sales opportunities. Your pop-up
              shops can avoid the hurdles of operational challenges, business
              barriers, and limited marketing time with the following advantages
              bequeathed by Cyntra.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid
                item
                align="left"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={icon8} style={{ height: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {" "}
                  Swift Set-up
                </text>
                <Typography>
                  For pop-up stores, time is limited . Cyntra lets you set up
                  shop quickly with their simple plug & play installations and
                  minimal product configurations. This maximizes your business
                  uptime and minimizes the time-taking set-up phase.{" "}
                </Typography>
              </Grid>
              <Grid
                item
                align="left"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={icon9} style={{ height: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {" "}
                  Cutting-Edge Retail Products
                </text>
                <Typography>
                Cyntra offers a range of products powered by the latest hardware and equipped with smart software. We guarantee a standout pop-up shop experience to your customers that’ll set you apart from the competition. 
                </Typography>
              </Grid>
              <Grid
                item
                align="left"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={icon10} style={{ height: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {" "}
                  Speedy Checkouts
                </text>
                <Typography>
                We have revolutionized the checkout process with 30-second checkouts, offered by Breez, our flagship self-service kiosk. Increased sales and reduced abandoned purchases within the limited time is made possible through Breez.   
                </Typography>
              </Grid>
              <Grid
                item
                align="left"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={icon11} style={{ height: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {" "}
                  RFID-Enhanced Inventory Management{" "}
                </text>
                <Typography>
                Pop-up shops need accurate stock updates. With our RFID-integrated products, get precise inventory tracking and instant checkouts. Ensure your store is equipped with high-demand items and make informed restocking decisions. 
                </Typography>
              </Grid>
              <Grid
                item
                align="left"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={icon12} style={{ height: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {" "}
                  Gamified Customer Journey
                </text>
                <Typography>
                Pop-up stores should captivate shoppers. Cyntra's gamified platform features achievements, tracks progress, and offers rewards. This guarantees an interactive shopping journey, keeping customers engaged even after leaving your pop-up shop. 
                </Typography>
              </Grid>
              <Grid
                item
                align="left"
                xs={12}
                sm={12}
                md={4}
                lg={4}
                style={{ padding: "1rem" }}
              >
                <img src={icon13} style={{ height: "20%" }} />
                <text style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  {" "}
                  Robust Backend System{" "}
                </text>
                <Typography>
                Managing multiple pop-up locations is easy with Cyntra. Our robust backend allows you to synchronize operations across different venues, ensuring uniformity in pricing and promotions. This centralized management gives businesses a competitive edge .
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="left"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ paddingTop: "1rem" }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              align="left"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1.5rem",
              }}
            >
              Experience a Seamless Retail Ecosystem
            </Typography>{" "}
            <Typography
              align="left"
              style={{
                color: "black",
                fontSize: "15px",
              }}
            >
              Cyntra's unified retail ecosystem can mean the difference between
              merely surviving in the competitive market and thriving with
              unparalleled growth. Reach out to us today.
            </Typography>{" "}
            <Grid
              item
              align="left"
              xs={10}
              sm={10}
              md={4}
              lg={4}
              style={{ paddingTop: "2rem" }}
            >
              <Button
                href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                style={{
                  color: "white",
                  backgroundColor: "#3F9AF3",
                  fontWeight: "bold",
                  borderRadius: "10rem",
                  padding: "0.7rem",
                }}
              >
                Schedule a call
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <img src={pic7} style={{ width: "70%" }}></img>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "1rem", paddingBottom: "3rem" }}
          ></Grid>
        </Grid>
        <Grid container justifyContent="left" xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              align="center"
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                lineHeight: "2.8rem",
                paddingBottom: "1rem",
              }}
            >
              Streamline Your Business With Our Complete Suite of Applications
            </Typography>{" "}
            <Typography
              align="center"
              style={{
                color: "black",
                fontSize: "15px",
                paddingBottom: "2rem",
              }}
            >
              Elevate the experience of your customers with our comprehensive
              suite of cutting-edge features. Seamlessly integrate efficient
              checkouts, streamlined ordering processes, and robust inventory
              and operations tools to unlock unprecedented productivity.
            </Typography>{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingBottom: "1rem" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                style={{ height: "20%" }}
              >
                <img src={breez} style={{ height: "10rem" }} />
                <Typography style={{ fontWeight: "bold", paddingTop: "1rem" }}>
                  Breez Kiosk
                </Typography>
                <Typography>
                  Revamp shopping experience with 30-second checkouts
                </Typography>
                {/* <a>Read more</a> */}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <img src={leap} style={{ height: "10rem" }} />
                <Typography style={{ fontWeight: "bold", paddingTop: "1rem" }}>
                  Leap POS
                </Typography>
                <Typography>
                  Streamline retail operations, no training or infrastructure
                  needed
                </Typography>
                {/* <a>Read more</a> */}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <img src={swyft} style={{ height: "10rem" }} />
                <Typography style={{ fontWeight: "bold", paddingTop: "1rem" }}>
                  Swyft mPos
                </Typography>
                <Typography>
                  Process payments and oversee sales anytime, anywhere{" "}
                </Typography>
                {/* <a>Read more</a> */}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <img src={astra} style={{ height: "10rem" }} />
                <Typography style={{ fontWeight: "bold", paddingTop: "1rem" }}>
                  Astra Analytics
                </Typography>
                <Typography>
                  Get real-time insights on sales inventory, and key metrics ​
                </Typography>
                {/* <a>Read more</a> */}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <img src={digital} style={{ height: "10rem", width: "10rem" }} />
                <Typography style={{ fontWeight: "bold", paddingTop: "1rem" }}>
                  Digital Signage
                </Typography>
                <Typography>
                  Enhance brand marketing with in-sync displays
                </Typography>
                {/* <a>Read more</a> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item justifyContent="center" xs={1} sm={1} md={1} lg={1}></Grid>
    </Grid>
  );
}
