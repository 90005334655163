import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class BlogClb2 extends Component {
    render() {
        return (
                <>
                <main id="main">
                    <section className="blog757">
                    </section>
                    <section className="leastblog_div9">
                        <div className="leatest_bloghead32">
                            <div className="container">
                                <h2 className="about_head1">Timely Customer Connect with Right Analytics</h2>
                                <p className="heading_para656">Key to grow your Business</p>
                                <p className="heading_para1">
                                    by Isha Lall | Jul 08, 2020
                                </p>
                            </div>
                        </div>
                    </section>

                    <section className="ourteam_section1 section545">
                        <div className="row ourteam_div1">
                            <p className="blog_head65">Customer journeys are more varied and distinct than ever with the rise of new digital 
                                technologies & mobile and online shopping. Today’s consumers expect brands to anticipate their needs. 
                            </p>
                            <div className="col-sm-8 pl-0">
                                <h3>How as a brand are you growing? </h3>

                                <p className="blog_para4">Are you there with useful information at the right moment, every time?</p>

                                <p className="blog_para4">Do you have the right tools to understand fast-paced consumer journey and non-linear trends?</p> 

                                <p className="blog_para4">Are you tapping in the right data to deliver a personalized and relevant experience to your customer?</p>

                                <h3>Engage customers, how? </h3>
                                <p className="blog_para4">Insights, the right technology, and the right moment are all you need to engage your customers.</p>
                            </div>
                            <div className="col-sm-4">
                            <img src="./assets/img/blog1.jpg" className="blog_img32" title="Connected Retail"/>
                            </div>
                            <p>With <b>CYNTRA’s LIV REPORTING App,</b> you can </p>
                            <ul className="blog_para4 blog_li76">
                                <li><b>CONNECT your SYSTEM:</b> Integrate platforms and uncover store deeper insights for all our business lines. Have access to real-time data</li>
                                <li><b>HAVE access to REAL-TIME DATA:</b> Make informed decisions, conduct experiments to discover new business value, and map critical marketing metrics and dashboards to support decisions. </li>
                                <li><b>SHARE INSIGHTS:</b> The enhanced measurement and data access capabilities in ‘Liv Reporting’ analytics offer a clear and specific understanding of your business performance. You can make reporting 
                                and insights available to your teams so more people can take thoughtful actions.</li>
                            </ul>

                            <h3>Smart Benefit with LIV REPORTING </h3>
                            <p>With LIV REPORTING, there’s a smarter benefit of bringing analytics & promotions 
                                together; the direct and actionable steps you can take to improve business outcomes. </p>

                        </div>
                    </section>

                </main>
            </>
    )
}
}