import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import ModalP from "./Model";
import { Grid } from "@material-ui/core";

export default class About extends Component {
  render() {
    return (
      <>
        <Grid>  
      {/* <ModalP></ModalP> */}
      </Grid>
        <div class="wrapper">
          <MetaTags>
            <title>About Us</title>
            <meta id="meta-description" name="description" content="Making customer retail check in/out faster and better and streamlining system integration" />
            <meta id="og-title" property="og:title" content="CyntraLabs | About Us" />    
            <meta  name="keywords" content=" " />
            
          </MetaTags>
        </div>
        <main id="main">
          {/* ======= Breadcrumbs ======= */}
          {/* ======= Hero Section ======= */}
          <section id="hero" className="clfab1">
            <div className="row justify-content-center integration">
              <div className="container animate__animated animate__fadeInDown ">
                <div className="row justify-content-center">
                  <div className="col-xl-8 ">
                    <h1>ABOUT US</h1>
                    <p>
                      Delivering Innovation and Excellence beyond Expectation
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End Hero */}

          <section className="inner-page">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 ourstory_imgsec2">
                  <div className="fstdiv33">
                    <div className="story_imgdiv1">
                      <img
                        className="story_img1 clfab2"
                        src="assets/img/story_img1.jpg"
                      />
                    </div>

                    <div className="story_imgdiv2 clfret9">
                      <img
                        className="story_img2"
                        src="assets/img/story_img2.jpg"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <h3 className="ourstory_h1">OUR STORY</h3>
                  <p className="ourstory_p1">
                    Having worked in retail for over a couple of decades and
                    coming with the ability to understand the nuances of
                    consumer behavior, our vision was an Innovation space that
                    could curate ‘Unified’, ‘Easy to Deploy’ and 'Progressive'
                    solutions that also bring ‘Rapid Operational Value’.Hence,
                    in 2014 CYNTRA LABS was born.
                  </p>
                  <p className="ourstory_p1">
                    CYNTRA is led by experienced business executives with deep
                    expertise in technology,consumer products, and the global
                    retail industry.
                  </p>
                  <p className="ourstory_p1">
                    Since inception, our work has gotten credit for the industry
                    solutions we provide, for the values we give to our
                    partners, and for the flexible and customer-driven approach
                    we have. We take pride in our association with top global
                    brands across industries, across the globe.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="ourteam_section1">
                            <div className="row ourteam_div1">
                                <h1 className="ourteam65 mb-2">OUR TEAM</h1>
                                <p className="ourstory_p2">
                                    Our company’s leaders share a passion for developing innovative technology solutions 
                                    that drive significant bottom-line value, enabling customers and partners to succeed 
                                    and win. Our team works together in one environment committed to deliver nothing, but the best.
                                </p>
                                <div className="col-sm-4 ourtem_mem5">
                                    <img className="ourteam_img1" src="assets/img/team/Sanjeev.jpeg">
                                    <h3 className="ourteam_para1">Sanjeev Varshney</h3>
                                    <h3 className="ourstory_para2">Founder</h3>
                                </div>
                                <div className="col-sm-4 ourtem_mem5">
                                    <img className="ourteam_img1" src="assets/img/team/Amit.jpeg">
                                    <h3 className="ourteam_para1">Amit Bhatia</h3>
                                    <h3 className="ourstory_para2">CEO</h3>
                                </div>
                                <div className="col-sm-4 ourtem_mem5">
                                    <img className="ourteam_img1" src="assets/img/team/person3.jpg">
                                    <h3 className="ourteam_para1">Himanshu Nagrath</h3>
                                    <h3 className="ourstory_para2">CTO</h3>
                                </div>

                            </div>
                        </section> */}
          <section className="pt-0">
            <div className="row ourteam_div1">
              <div className="header_div1">
                <h1 className="ourteam65">WHAT WE STAND FOR</h1>
                <p className="ourstory_p2">
                  Our company’s leaders share a passion for developing
                  innovative technology solutions that drive significant
                  bottom-line value, enabling customers and partners to succeed
                  and win. Our team works together in one environment committed
                  to deliver nothing, but the best.
                </p>
              </div>
              <div className="col-sm-4 retailsol_2 aboutdo_2 animate__animated animate__fadeInRight redirection32">
                <a
                  className="row retailsol_3"
                  data-target="#carouselExampleIndicators"
                  data-slide-to="5"
                >
                  <img
                    src="./assets/img/about_innovation.jpeg"
                    title="Connected Retail"
                    className="retaolsol_img1"
                  />
                  <div className="aboutus_div2">
                    {/* <img className="detail_img2" src="./assets/img/retail/icon/customerEngagement.png"/> */}
                    <h2 className="showdis_cust1">INNOVATION</h2>
                  </div>
                  <div className="hvr_div1 abt_hvr34">
                    <div className="hover_efct23">
                      <h2 className="hvr_containt1 mb-3">INNOVATION</h2>
                      <ul className="industries_ul1">
                        <li>+ To ideate better and innovative strategy</li>
                        <li>+ To create Now and future proof solutions</li>
                        <li>+ To adapt to changing trends effortlessly</li>
                        <li>+ To outplace and outpace your competitors</li>
                      </ul>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-sm-4 retailsol_2 aboutdo_2 animate__animated animate__fadeInRight redirection32">
                <a
                  className="row retailsol_3"
                  data-target="#carouselExampleIndicators"
                  data-slide-to="5"
                >
                  <img
                    src="./assets/img/commitment.png"
                    title="Connected Retail"
                    className="retaolsol_img1"
                  />
                  <div className="aboutus_div2">
                    {/* <img className="detail_img2" src="./assets/img/retail/icon/customerEngagement.png"/> */}
                    <h2 className="showdis_cust1">COMMITMENT</h2>
                  </div>
                  <div className="hvr_div1 abt_hvr34">
                    <div className="hover_efct23">
                      <h2 className="hvr_containt1 mb-3">COMMITMENT</h2>
                      <ul className="industries_ul1">
                        <li>+ To ensure all stakeholders are working</li>
                        <li>+ To communicate hassle-free </li>
                        <li>+ To drive better results </li>
                        <li>+ To enhance growth</li>
                      </ul>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-sm-4 retailsol_2 aboutdo_2 animate__animated animate__fadeInRight redirection32">
                <a
                  className="row retailsol_3"
                  data-target="#carouselExampleIndicators"
                  data-slide-to="5"
                >
                  <img
                    src="./assets/img/excellence.jpg"
                    title="Connected Retail"
                    className="retaolsol_img1"
                  />
                  <div className="aboutus_div2">
                    {/* <img className="detail_img2" src="./assets/img/retail/icon/customerEngagement.png"/> */}
                    <h2 className="showdis_cust1">EXCELLENCE</h2>
                  </div>
                  <div className="hvr_div1 abt_hvr34">
                    <div className="hover_efct23">
                      <h2 className="hvr_containt1 mb-3">EXCELLENCE</h2>
                      <ul className="industries_ul1">
                        <li>+ To be customer-driven more than ever</li>
                        <li>+ To deliver exceptional quality. Always!</li>
                        <li>+ To take real time and informed decisions </li>
                        <li>+ To enhance customer experience</li>
                      </ul>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </section>

          <section className="progressbar_section1 clfab3">
            <div className="counter">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <div className="employees">
                      <div className="counter_bardiv33">
                        <p className="counter-count">6</p>
                      </div>
                      <p className="employee-p">
                        YEARS IN<br></br>BUSINESS
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <div className="customer">
                      <div className="counter_bardiv33">
                        <p className="counter-count">50</p>
                        <span className="counter_span22">+</span>
                      </div>
                      <p className="customer-p">
                        OUTLETS<br></br>INSTALLED{" "}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <div className="design">
                      <div className="counter_bardiv33">
                        <p className="counter-count">100</p>
                        <span className="counter_span22">K+</span>
                      </div>
                      <p className="design-p">
                        TRANSATIONS<br></br>CAPTURED
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <div className="order">
                      <div className="counter_bardiv33">
                        <span className="counter_span33">$</span>
                        <p className="counter-count">10</p>
                        <span className="counter_span22">M+</span>
                      </div>
                      <p className="order-p">
                        TRANSACTION<br></br>VALUE
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="ourteam_section1">
            <div className="row ourteam_div1">
              <h1 className="ourteam65">Latest from the Blog</h1>

              <div className="col-sm-4 ourtem_mem5">
                <div className="blog_section21">
                  <img
                    className="blogimg32"
                    src="./assets/img/cover.png"
                  />
                  <div className="blog_div21">
                    <h3 className="blog_para1">
                      The Future of Retail: How CyntraLabs is Leading the Way{" "}
                    </h3>
                    <p className="blog_para2">
                      The retail industry is constantly evolving, with new
                      technologies and trends emerging regularly. From online
                      shopping to mobile checkout, retailers are constantly
                      seeking ways to improve the customer experience and
                      increase operational efficiency.
                      <br></br>
                    </p>
                  </div>
                  <NavLink className="blog_a21" to={"blog-future-of-retail"}>
                    READ MORE
                  </NavLink>
                </div>
              </div>
              <div className="col-sm-4 ourtem_mem5">
                <div className="blog_section21">
                  <img className="blogimg32" src="assets/img/blog1.jpg" />
                  <div className="blog_div21">
                    <h3 className="blog_para1">
                      Timely Customer Connect with Right Analytics
                    </h3>
                    <p className="blog_para2">
                      Customer journeys are more varied and distinct than ever
                      with the rise of new digital technologies & mobile and
                      online shopping. Today’s consumers expect brands to
                      anticipate their needs.
                    </p>
                  </div>
                  <NavLink
                    className="blog_a21"
                    to={"blog-connect-with-right-analytics"}
                  >
                    READ MORE
                  </NavLink>
                </div>
              </div>

           
              <div className="col-sm-4 ourtem_mem5">
                <div className="blog_section21">
                  <img
                    className="blogimg32"
                    src="../../assets/img/blog_breeze3.jpg"
                  />
                  <div className="blog_div21">
                    <h3 className="blog_para1">
                      Self-Service Kiosk – It’s all about Customer Convenience
                    </h3>
                    <p className="blog_para2">
                      Technology plays an important role in today’s time in
                      almost everyone’s life in some way or the other. It
                      affects the way we all communicate, think, operate and
                      learn. It has truly become an inevitable part of today’s
                      time
                      <br></br>
                    </p>
                  </div>
                  <NavLink className="blog_a21" to={"blog-breeze"}>
                    READ MORE
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}
