import React from 'react'

import Header from '../Include/Header'
import Footer from '../Include/Footer'

const DefaultLayout = ({children}) => {
    return (
        <>
            <Header {...children.props}/>
                {children}
            <Footer {...children.props}/>
        </>
    )
}
export default DefaultLayout