import React from "react";
import { useEffect } from "react";
import emailjs from "@emailjs/browser";

import {
  Grid,
  Card,
  Modal,
  makeStyles,
  Typography,
  Button,
  Input,
} from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import MobileSpecificModal from "./MobileSpecificModal";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
   
  
    justifyContent: "center",
    alignItems: "center",
  },
  //   requestbutton: {
  //     // padding: "1pc",
  //     color: "white",
  //     fontSize: "1.rem",
  //     fontWeight: "bold",
  //     backgroundColor: "white",
  //     borderRadius: "0 2pc 2pc 0",
  //     // height: "3.3pc",
  //     // zIndex: "1",
  //     "&:hover": {
  //       //   backgroundColor: "#3AA1F4",
  //       //   color: "black"
  //     },
  //   },
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "10rem",
  },
  inputbtn: {
    borderColor: "black",
  
  },
}));

export default function ModalP() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [emailID, setEmailId] = React.useState("");
  const [validMail, setvalidMail] = React.useState("true");

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  useEffect(() => {
    setTimeout(() => setOpen(true), 3000);
  }, []);

  const EmailContactForm = () => {
    // const validEmail = emailRegex.test(emailID);

    // console.log(validEmail);
    // if (validEmail) {
    //   setvalidMail(() => true);
    //   console.log(emailID);
    //   const templateParams = {
    //     from: emailID,
    //   };
    //   emailjs
    //     .send(
    //       "service_0vop8mg",
    //       "template_ozmz287",
    //       templateParams,
    //       "qpzmVRhJ-4BJfAZKr"
    //     )
    //     .then(
    //       function (response) {
    //         console.log("SUCCESS!", response.status, response.text);
    //       },
    //       function (error) {
    //         console.log("FAILED...", error);
    //       }
    //     );
    // } else {
    //   setvalidMail(() => false);
    // }
 
  };

  const emailValueHandler = (e) => {
    setEmailId(() => e.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1000px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <Grid>
      {isDesktopOrLaptop && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card className={classes.modal}>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                color: "white",
              }}
            >
              <Grid
                container
                lg={12}
                xs={12}
                sm={12}
                md={12}
            
              >
                
                <Grid item lg={12} xs={12} sm={12} md={12}>
                  <Button
                    onClick={handleClose}
                    style={{
                      background: "transparent",
                      border: "transparent",
                      float:'right',
                      color: "black",
                      padding:"1rem",
                    }}
                  >
                    X
                  </Button>
                </Grid>
              </Grid>
              {/* <Grid item lg={12} xs={12} sm={12} md={12}>
                <img src="assets/img/Logo.png" className={classes.center} style={{marginTop:"-2rem"}} />
              </Grid> */}

            
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                style={{
                  color: "#AFB1B9",
                  backgroundColor: "white",
                padding:"1rem",
                }}
              >
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    margin: "1pc",
             
                  
                  }}
                >
                  <Typography style={{ textAlign: "center", fontSize: "2.5rem" ,fontFamily:"poppins",fontWeight:"bold",color:"black"}}>
                  Hey there, our retail site is relocated!
                  </Typography>
              
                  <Typography style={{ textAlign: "center", fontSize: "1rem"  ,color:"black"}}>
                  Visit us at our new online home for retail, <a href="https://cyntra.ai/">https://cyntra.ai</a> 
                  </Typography>
                  <Typography style={{ textAlign: "center", fontSize: "1rem"  ,color:"black"}}>      
                  Update your bookmarks and join us on the new site!
                  </Typography>
                  
                </Grid>

                <Grid
                  container
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                     padding:"1rem",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                   
                    {/* <Grid
                      item
                      lg={7}
                      xs={12}
                      sm={12}
                      md={7}
                      style={{
                        paddingBottom: "2rem",
                        textAlign: "center",
                      }}
                    >
                      <input
                        fullWidth
                        size={40}
                        type="email"
                        style={{
                            padding: "1rem",
                          border: "1px solid black",
                          fontSize: "0.8rem",
                          paddingLeft: "1.5rem",
                        }}
                        onChange={(e) => emailValueHandler(e)}
                        placeholder="Enter your email here…"
                        className={classes.inputbtn}
                      />
                      {!validMail && (
                        <text style={{ fontSize: "0.7rem", color: "red" }}>
                          Please enter a valid email address.
                        </text>
                      )}
                    </Grid> */}
                    <Grid item lg={4} xs={4} sm={4} md={4}  >
                      <Button
                        fullWidth
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href='https://cyntra.ai/';
                            }}
                        style={{
                            padding: "1rem",
                          // border: "1px solid black",
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                          textAlign:"center",
                      textTransform:"none",
                          color: "white",
                          fontFamily:"Poppins",
                            backgroundColor: "#3AA1F4",
                          alignContent:"center",
                          borderRadius: "2rem",
                          paddingBottom:"1rem"
                        }}
                      >
                        Take me there
                      </Button>
                    </Grid>
                 
                
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Modal>
      )}
      {isTabletOrMobile && <MobileSpecificModal></MobileSpecificModal>}{" "}
    </Grid>
  );
}
