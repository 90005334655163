import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ModalP from "./Model";
import { Grid } from "@material-ui/core";

export default class Kiosks extends Component {

    constructor(props) {
      super(props);
      this.state = {
        slideIndex: 1,
      }
      this.plusSlides = this.plusSlides.bind(this);
    }

    componentDidMount(){
      showSlides(1);

      function showSlides(n) {
        var i;
        var slides = document.getElementsByClassName("mySlides");
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";  
        }
        slides[0].style.display = "block";  
      }
    }

    plusSlides(n){
      const {slideIndex} = this.state
      var slideIndexx = 1;
      if (n + slideIndex > 2) { slideIndexx = 1 }    
      if (n + slideIndex < 1) { slideIndexx = 2 }
      this.setState({slideIndex: slideIndexx})
    }

    render() {
        
        return (
            <>
                  <Grid>  
      {/* <ModalP></ModalP> */}
      </Grid>
            <section id="retail" className="d-flex flex-column justify-content-center clfki1" style={{ backgroundSize: 'cover'}}>
              <div className="darkbgblue ks34"></div>
                <div className="layer ks35">
                  <div className="container">
                      <div className="row justify-content-center retail">
                          <div className="col-xl-12 animate__animated animate__slideInDown ks42" style={{ textAlign: 'left', marginTop: '3rem'}}>
                            <div className="ks32">
                              <img src="assets/img/Logo.png" alt="" className="ks36"/>
                            </div>
                            <h1 className="ks01"><span className="ks37">SELF</span>-SERVICE</h1>
                            <h1 className="ks02">KIOSKS</h1>
                            <p className="ks03">
                                Innovating and transforming the retail
                                experience,better than ever— with
                                kiosks in use across various
                                industries:</p>
                            <div className="ret23er">
                              <img src="assets/img/zip1/airport-icon.png" alt="" className="ks05" />
                              <span className="ks04">AIRPORT/HOTEL CHECK-IN</span>
                            </div>
                            <div className="ret23er">
                              <img src="assets/img/zip1/healthcare-icon.png" alt="" className="ks05"/>
                              <span className="ks04">HEALTHCARE</span>
                            </div>
                            <div className="ret23er">
                              <img src="assets/img/zip1/restaurants-icon.png" alt="" className="ks05"/>
                              <span className="ks04">RESTAURANTS</span>
                            </div>
                            <div className="ret23er">
                              <img src="assets/img/zip1/movie-theater-icon.png" alt="" className="ks05 ks06"/>
                              <span className="ks04">MOVIE THEATERS</span>
                            </div>
                          </div>
                      </div>
                  </div>
            </div>
        </section> {/* </div> End Hero --> */}
      
        <main id="main">
            <section id="portfolio" className="portfolio ks43">
              <img src="assets/img/retail/page2-placeholder.png" alt="" className="ks17"/>
            </section>
      
          <section id="retail" className="d-flex ks43 clfki2" >
            <div className="darkbgblue" className="ks07"></div>
            {/* #031835 */}
              <div className="ks08">
                <img src="assets/img/retail/shopping.png" alt="" className="ks21"/>
              </div>
              <div className="ks09">
                <h1 className="ks11">Improve the <span className="ks38">experience</span> and <span className="ks38">flow</span><br/>of your customer’s orders</h1>
                <p className="ks10">Whether your business is in healthcare, retail,entertainment, or anything in between, CyntraLabs understands that excellence is crucial to the success of your business.</p>
                <p className="ks10">In an increasingly contactless and autonomous<br/>world, we also know that consumer behavior has, inturn changed.<br/>
                With CyntraLab’s self-service kiosks, your<br/>business will be able to offer an agile, heightened<br/>user experience to your customers<br/>that could set your business apart from<br/>the competition.</p>
              </div>
            </section>
            
          <section id="retail" className="d-flex kiosks_cont1 ks43 ks45 clfki3">
            {/* <div className="darkbgblue" className="ks07"></div> -->
            <!-- #031835 */}
              <div className="ks12">
                  <div className="ks13">
                      <div className="ks14">
                        <h1 className="ks15" style={{marginTop:'-18px'}}>Kiosk Features</h1>
                        <p className="ks16">CyntraLabs offers kiosks with features that can help grow your business in the modern age.</p>
                        </div>
                      <div className="ks14" style={{display:'grid',flexDirection:'row',}}>
                        <div className="ks17 ks44">
                            <img src="assets/img/zip1/contactless-ordering-icon.png" alt="" className="ks33"/>
                        </div>
                        <h3 className="ks18">Contactless Ordering</h3>
                        <p className="ks16">Contactless in-store ordering solution helps restaurants and eateries minimize physical contacts for menu, billing, and cash transactions. Enjoy safe dining and foodordering experience simply by scanning QR code displayed on the Kiosk.</p>
                        </div>
                    </div>
                  <div className="ks13">
                      <div className="ks14" style={{marginTop:'-30px'}}>
                        <div className="ks17 ks44">
                            <img src="assets/img/zip1/seamless-integration-icon.png" alt="" className="ks33"/>
                        </div>
                        <h3 className="ks18">Seamless Integration</h3>
                        <p className="ks16">Integration is a necessary step to make your self-service kiosk system more competitive and avoid glitches. API integration allows requests which are from customers via kiosk to communicate with the server and update the requests quickly. Seamless integration encourages bigger orders and faster checkouts turning kiosk into ‘all-in-one’ system.</p>
                        </div>
                      <div className="ks14">
                        <div className="ks17 ks44">
                            <img src="assets/img/zip1/voice-activation-icon.png" alt="" className="ks33"/>
                        </div>
                        <h3 className="ks18">Voice Activation</h3>
                        <p className="ks16">CYNTRA’s voice ordering AI platform interacts on behalf of the restaurants with its customers to take orders and book tables. Voice activation enables restaurants to receive orders via voice interface deployed on the kiosks</p>
                        </div>
                    </div>
              </div>
              <div className="ks19">
                <img src="assets/img/zip1/kiosk-restaurant.png" alt="" className="ks20"/>
              </div>
            </section>
            </main>
      
      
          <section id="retail" className="d-flex kiosks_cont ks43 ks46 clfki4">
            {/*<div className="darkbgblue" className="ks07"></div> -->
            <!-- #031835 -->*/}
              <div className="ks08">
                <img src="assets/img/zip1/kiosk-airport.png" alt="" className="ks21"/>
              </div>
              <div className="ks22">
                  <div className="ks13">
                      <div className="ks14" style={{marginTop:'-25px'}}>
                        <div className="ks17 ks44">
                            <img src="assets/img/retail/icon/expressCheckout.png" alt="" className="ks23"/>
                        </div>
                        <h3 className="ks18">Express Checkout</h3>
                        <p className="ks16">It’s a known fact that customers order more when someone isn’t looking. Through self-service kiosks, customers can breeze through your restaurant line faster, feeling more satisfied and spending more than usual. You save on labor costs, enhance your brand, and build a better reputation with customers.</p>
                        </div>
                      <div className="ks14">
                        <div className="ks17 ks44">
                            <img src="assets/img/zip1/integrated-payments-icon.png" alt="" className="ks33"/>
                        </div>
                        <h3 className="ks18">Integrated Payments</h3>
                        <p className="ks16">Our self ordering Kiosks consists of various trusted payment methods. Checkout the menu, order, customize dishes and pay in one place. Customers can pay by card or even go contactless.</p>
                        </div>
                    </div>
                  <div className="ks24">
                      <div className="ks14">
                        <div className="ks17 ks44">
                            <img src="assets/img/retail/icon/socialDistancing.png" alt="" className="ks23"/>
                        </div>
                        <h3 className="ks18">Improved Order Accuracy</h3>
                        <p className="ks16">Kiosks enable entering orders directly; it helps eliminate errors, as the order goes from the customer to the kiosk to the kitchen. Self-order kiosks give people the chance to make more informed choices, so it tastes great every time.</p>
                        </div>
                    </div>
              </div>
            </section>
      
      
          {/* ======= Breadcrumbs ======= */}
          <section className="aboutheader_main3 ks43 clfki2 ">
              <div className="container">
                  <h2 className="about_head1 ks39">DEMOS</h2>
                  <p className="heading_para1 ks40">Delivering Innovation and Excellence beyond Expectation </p>
              </div>
      
            <div id="mydiv" className="row ks41">
                {/* <iframe src="gallery/index.html" width="100%" height="420" frameBorder="0">Browser not compatible.</iframe> -->*/}
                <div className="slideshow-container ks41" name="1">
                  <div className="mySlides" name="1" style={{display: (this.state.slideIndex === 1)?'block':'none' }}>
                    <img src="assets/img/zip1/restaurant-demo.png" className="ks31"/>
                    <div className="text2e ks47">
                      <h2 className="ks25">SELF-ORDERING</h2>
                      <h2 className="ks25">KIOSKS FOR</h2>
                      <h2 className="ks26">RESTAURANTS</h2>
                      <p className="ks27">Simplify and speed up</p>
                      <p className="ks28">the ordering process.</p>
                      <a className="cta-btn align-middle cta1234" href="#">Learn More</a>
                      {/* <a href="#"><img src="assets/img/zip1/learn-more.png" className="ks30" /></a> */}
                    </div>
                  </div>
                  <div className="mySlides" name="2" style={{display: (this.state.slideIndex === 2)?'block':'none' }}>
                    <img src="assets/img/zip1/retail-demo.png" className="ks31"/>
                    <div className="text ks47">
                      <h2 className="ks25">SELF-ORDERING</h2>
                      <h2 className="ks25">KIOSKS FOR</h2>
                      <h2 className="ks26">RETAILERS</h2>
                      <p className="ks27">Contactless solutions</p>
                      <p className="ks28">for modern retail shopping.</p>
                      <a className="cta-btn align-middle cta1234" href="#">Learn More</a>
                    </div>
                  </div>
                  <a className="prev plusSlides" onClick={() => this.plusSlides(-1)} data-action="-1">&#10094;</a>
                  <a className="next plusSlides" onClick={() => this.plusSlides(-1)} data-action="+1">&#10095;</a>
                </div>
             </div>
             <p className="showdemo1" id="our-demo"></p>
            </section>
      
    {/*End Pricing Section */}
          <div className="browser_div323">
            <a href="cyntra_retail_pdf.pdf" target="blank" >
              <h5 className="ks29">Brochure</h5>
            </a>
          </div>
          <div className="retail_pdf">
            <a href="Cyntra-RetailSolutions.pdf" target="blank" >
              <h5 className="ks29">Retail</h5>
            </a>
          </div>
          
         
      
        </>
        )
    }
}