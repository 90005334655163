import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useRef } from "react";
import src from "../Component/assets/Breez_Food.mp4";
import ReactPlayer from "react-player";
import file from "../Component/assets/CyntraBooklet.pdf";
import Box from "@mui/material/Box";
import src1 from "../Component/assets/Retail_Checkout.mp4";
import src2 from "../Component/assets/BreezHotelCheck-In.mp4";
import { Divider } from "@mui/material";
import src4 from "../Component/assets/SwyftmPOS.mp4";
import { Typography } from "@mui/material";
import ModalP1 from "./Popup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import Helmet from "react-helmet";
import ModalP from "./Model";


const useStyles = makeStyles((theme) => ({
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "5rem",
    paddingTop: "1.3rem",
    paddingBottom: "1.3rem",
  },
}));

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.9rem",
  "@media (min-width:800px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};

const Home3 = () => {
  const [open, setOpen] = React.useState("");
  const onButtonClick = () => {
    setOpen(<ModalP1></ModalP1>);

    // fetch(file).then((response) => {
    //   response.blob().then((blob) => {
    //     const fileURL = window.URL.createObjectURL(blob);

    //     let alink = document.createElement("a");
    //     alink.href = fileURL;
    //     alink.download = "Cyntra_Booklet.pdf";

    //     alink.click();
    //   });
    // });
  };
  const ref = useRef(null);
  // const click = () => {
  //   // ref.current?.scrollIntoView({ behavior: "smooth" });
  //   Link="https://outlook.office.com/bookwithme/user/06f3f368011544a28300bdc571f97168@cyntralabs.com/meetingtype/jfVTZNLEq0OL0kREkGz52w2?anonymous&ep=mlink"
  // };

  return (
    <>
         <Grid>  
      {/* <ModalP></ModalP> */}
      </Grid>
     <div class="wrapper">
          <Helmet>
            <title>Demo</title>
            <meta id="meta-description" name="description" content="30 Seconds Checkout." />
            <meta id="og-title" property="og:title" content="CyntraLabs | Demo" />  
            <meta  name="keywords" content="Food Ordering , Hotel Checkout , Retail Checkout " />  
          </Helmet>
        </div>
      <div>{open}</div>
     
      <Grid
        marginTop={6}
        style={{ backgroundColor: "#38a1f7", color: "white" }}
      >
        <Typography
          style={{ fontWeight: "bold" }}
          paddingTop="1rem"
          paddingBottom="0.5rem"
        >
          Get basic setup up and running in just 2 weeks.
          <a href="https://outlook.office365.com/owa/calendar/DemoPageBookings@cyntralabs.com/bookings/">
            {" "}
            <span style={{ textDecoration: "underline", color: "white" }}>
              {" "}
              TRY NOW!
            </span>
          </a>
        </Typography>
      </Grid>
      <div id="fullpage">
        <section
          id="retail1"
          className="d-flex flex-column justify-content-center clfretabcd"
        >
          <div className="row justify-content-center retail">
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                padding={2}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} pb={2} pt={2}>
                  <Typography
                    variant="h4"
                    style={{
                      padding: "1rem",
                      fontFamily: "Gill Sans,sans-serif",
                      textTransform: "uppercase",
                      paddingTop: "1.5rem",
                      color: "white",
                      fontSize: "2.5rem",
                      textAlign: "center",
                      fontWeight: "500",
                    }}
                  >
                    Reinvent your business with faster checkouts
                  </Typography>

                  <Typography
                    variant="h5"
                    style={{
                      marginTop: "0.5rem",
                      color: "white",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      fontFamily: "Gill Sans,sans-serif",
                      fontWeight: 400,
                      paddingLeft: "0.5rem",
                      paddingRight: "0.5rem",
                    }}
                  >
                    Seamless 30 seconds checkout with features like facial
                    recognition, voice inputs, RFID scanning <br /> and fully
                    integrated plug & play solutions to bring rapid operational
                    value.
                  </Typography>

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={4}
                    style={{ marginBottom: "2rem" }}
                  >
                    <Button
                      onClick={onButtonClick}
                      variant="contained"
                      style={{
                        color: "white",
                        fontWeight: "550",
                        fontFamily: "Gill Sans,sans-serif",
                        fontSize: "0.8rem",
                        backgroundColor: "#38a1f7",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        paddingTop: "0.7rem",
                        paddingBottom: "0.7rem",
                        marginTop: "1rem",
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                        borderRadius: "5rem",
                      }}
                    >
                      {" "}
                      Download Brochure
                    </Button>

                    <Button
                      variant="contained"
                      // onClick={Click}
                      href="https://outlook.office365.com/owa/calendar/SeamlesscustomercheckinoutLiveDemo@cyntralabs.com/bookings/"
                      style={{
                        color: "black",
                        fontWeight: "550",
                        fontSize: "0.8rem",
                        fontFamily: "Gill Sans,sans-serif",
                        backgroundColor: "white",
                        paddingLeft: "1.5rem",
                        paddingRight: "1.5rem",
                        paddingTop: "0.7rem",
                        paddingBottom: "0.7rem",
                        marginTop: "1rem",
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem",
                        borderRadius: "5rem",
                      }}
                    >
                      Schedule a live demo
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        </section>
        <div class="section">
          <div id="Demo12" ref={ref}>
            <section
              id="retail"
              className="d-flex flex-column justify-content-center clfretabcd1"
            >
              <Box sx={{ flexGrow: 1 }} justifyContent="center">
                <Grid
                  container
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    textAlign: "left",
                    fontSize: "3rem",
                    color: "black",
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                  }}
                ></Grid>

                <Grid
                  container
                  justifyContent="center"
                  mt={7}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  p={2}
                >
                  <Typography
                    variant="h4"
                    style={{
                      textAlign: "left",
                      color: "#0a1d54",
                      fontFamily: "sans-serif",
                      fontWeight: "bolder",
                    }}
                  >
                    Breez Kiosk{" "}
                    <span style={{ fontWeight: "normal" }}>Demo</span>
                    <Divider
                      style={{
                        backgroundColor: "#38a1f7",
                        marginRight: "8rem",
                        marginLeft: "8rem",
                        height: "0.2rem",
                      }}
                    ></Divider>
                  </Typography>

                  <Grid
                    container
                    justifyContent="center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    mt={3}
                  >
                    <Grid item xs={12} sm={12} md={4} lg={4} p={2}>
                      <ReactPlayer
                        className="react-player"
                        width="100%"
                        height="100%"
                        url={src1}
                        // color: isActive3 ? 'white' : '#404949',
                        config={{
                          file: {
                            attributes: {
                              poster: "./assets/demo/retail.png",
                            },
                          },
                        }}
                        controls
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} p={2}>
                      <ReactPlayer
                        className="react-player"
                        width="100%"
                        height="100%"
                        url={src}
                        config={{
                          file: {
                            attributes: {
                              poster: "./assets/demo/food.png",
                            },
                          },
                        }}
                        controls
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} p={2}>
                      <ReactPlayer
                        className="react-player"
                        width="100%"
                        height="100%"
                        // url="https://www.youtube.com/watch?v=RrcVeq4c0h8"
                        url={src2}
                        // light="./assets/demo/hotelcheckin.png"
                        config={{
                          file: {
                            attributes: {
                              poster: "./assets/demo/hotelcheckin.png",
                            },
                          },
                        }}
                        controls
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    variant="h4"
                    style={{
                      textAlign: "left",
                      color: "#0a1d54",
                      marginTop: "3rem",
                      fontFamily: "sans-serif",
                      fontWeight: "bolder",
                    }}
                  >
                    Swyft mPOS{" "}
                    <span style={{ fontWeight: "normal" }}>Demo</span>
                    <Divider
                      style={{
                        backgroundColor: "#38a1f7",
                        marginRight: "8.5rem",
                        marginLeft: "8.5rem",
                        height: "0.2rem",
                      }}
                    ></Divider>
                  </Typography>

                  <Grid
                    container
                    justifyContent="center"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    mt={3}
                    mb={5}
                  >
                    <Grid item xs={12} sm={12} md={4} lg={4} p={2}>
                      <ReactPlayer
                        className="react-player"
                        width="100%"
                        height="100%"
                        url={src4}
                        config={{
                          file: {
                            attributes: {
                              poster: "./assets/demo/swyft.png",
                            },
                          },
                        }}
                        controls
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home3;
