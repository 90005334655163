import React , {useState} from 'react';
import { NavLink } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { emailconfig, CONTACTUS_API } from "../utils/constant.js";



const Footer = (props) => {
        const href = window.location.href
        const checkHash = "#"+(href.substring((href).lastIndexOf('/') + 1)).split("#")[1]
        const checkHash2 = href.substring((href).lastIndexOf('/') + 1)
        console.log("checkHash2 = ", checkHash2)
        const {isTestimonials, isScheduleDiscoveryCall, isPartners, isFooter} = props.checkPrivileges()
        const recaptchaRef = React.useRef();
        const formRef = React.useRef();
        const [message, setMessage] = useState();

        const onSubmitWithReCAPTCHA = async () => {
            let { name, designation, email, contact, Industry, CompanyName, message } = formRef.current
            name = name.value;
            designation = designation.value;
            email = email.value;
            contact = contact.value;
            Industry = Industry.value;
            CompanyName = CompanyName.value;
            message = message.value;
           
            setMessage('Request in process...')
            if((name !== "" && name !== undefined) && (email !== "" && email !== undefined)){
                var html = "<div style='padding: 10px 20px;'>";
                    html += "<h2 style='border-bottom: 1px solid rgb(153, 153, 153); padding: 10px 0px;'>Send Query</h2>";
                    html += "<p><b>Name: </b> "+name+"</p>";
                    html += "<p><b>Designation: </b> "+designation+"</p>";
                    html += "<p><b>Email: </b> "+email+"</p>";
                    html += "<p><b>Contact No: </b> "+contact+"</p>";
                    html += "<p><b>Industry: </b> "+Industry+"</p>";
                    html += "<p><b>Company Name: </b> "+CompanyName+"</p>";
                    html += "<p><b>Message: </b> "+message+"</p>";
                    html += "</div>";
    
                var postobj = {
                    "app": emailconfig.app,
                    "to": emailconfig.to, 
                    "subject": "Query for Retail",
                    "body": html
                   
                }
                
                var optionAxios = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                }
                await axios.post(CONTACTUS_API, postobj, optionAxios).then( async (response) => {
                    console.log(response)
                    document.getElementById("contact-form").reset();
                    setMessage("Your message has been sent. Thank you!")
                }).catch( async (err) => {
                    console.log(err)
                    setMessage("Unable to send email. Please try again.")
                })
            }else{
                setMessage("All the fields are mendetory.")
            }
        }
        return (
            <>
                
            {(checkHash === "#productsnservices" && checkHash2 !== "retail#productsnservices")?
              <section id="productsnservices" className={(checkHash === "#productsnservices")?"productsnservices clf1 scrolltosec portfolio":"productsnservices clf1"}>
                <div className="container">
                <div className="section-title clfret5">
                    <h2 className="text-center">PRODUCTS & SERVICES</h2>
                    <p className="header_text2">Explore our wide range of products and services.<br/>
                    The experience and expertise of our product development team, backed up by the highest commitment to
                    customer service has built great trust in the CYNTRA brand through the eyes of our customer.
                    </p>
                  </div>
                  <div className="row portfolio-container clfret6">
                    <div className="col-lg-4 col-md-4 portfolio-item filter-web redirection32 animate__animated animate__fadeInLeft col-md-12">
                      <img src="../assets/img/retail/POS.jpg" className="img-fluid center" alt="" />
                      <div className="Integration-head">
                        <h2 className="">POINT OF SALE</h2>
                      </div>
                      <a className="row retailsol_3 portfolio-info" data-target="#carouselExampleIndicators" data-slide-to="0">
                        <h4 className="">POINT OF SALE</h4>
                        <p>At CYNTRA, PoS is not just a cash register. You turn your raw sales data into more manageable and tangible purposes. Integrated with payments, our PoS system is designed to streamline the access to all of your sales information, for accounting and management use.</p><br />
                      </a>  
                    </div>
                    <div className="col-lg-4 col-md-4 portfolio-item filter-web redirection32 animate__animated animate__fadeInUp col-md-12">
                      <img src="../assets/img/retail/digitalPayments.jpg" className="img-fluid center" alt="" />
                      <div className="Integration-head">
                        <h2 className="">DIGITAL PAYMENTS</h2>
                      </div>  
                      <a className="row retailsol_3 portfolio-info" data-target="#carouselExampleIndicators" data-slide-to="1">
                        <h4 className="">DIGITAL PAYMENTS</h4>
                        <p>Integrated Payment ensures greater accuracy, time savings and no manual transactions. Reporting and reconciliation becomes smooth with all your transactions in one place. Turn relationship management, accounting, and payment processing into a streamlined process.</p><br />
                      </a>  
                    </div>
                    <div className="col-lg-4 col-md-4 portfolio-item filter-web redirection32 animate__animated animate__fadeInRight col-md-12">
                      <img src="../assets/img/retail/Kiosk.jpg" className="img-fluid center" alt="" />
                      <div className="Integration-head">
                        <h2 className="">SELF ODERING KIOSK</h2>
                      </div>  
                      <a className="row retailsol_3 portfolio-info" data-target="#carouselExampleIndicators" data-slide-to="2">
                        <h4 className="">SELF ODERING KIOSK</h4>
                        <p>Providing full fledged menu, along with payment versatility, Self Ordering Kiosks at CYNTRA make it convenient for your customers to place their order with ease. Employees can now focus more on boosting sales, enhancing profitability and improving overall operations.</p><br />
                      </a>  
                    </div>
          
                    <div className="col-lg-4 col-md-4 portfolio-item filter-web redirection32 animate__animated animate__fadeInLeft col-md-12">
                      <img src="../assets/img/retail/livReporting.jpg" className="img-fluid center" alt="" />
                      <div className="Integration-head">
                        <h2 className="">LIV REPORTING</h2>
                      </div>  
                      <a className="row retailsol_3 portfolio-info" data-target="#carouselExampleIndicators" data-slide-to="3">
                        <h4 className="">LIV REPORTING</h4>
                        <p>Our LIV Reporting app gives our customers the power of data, real-time anywhere in the world. Decisions driven by analytics help businesses to be proactive, make informed choices and thus improving overall organizational efficiency.</p><br />
                      </a>  
                    </div>
                    <div className="col-lg-4 col-md-4 portfolio-item filter-web redirection32 animate__animated animate__fadeInUp col-md-12">
                      <img src="../assets/img/retail/rfid.jpg" className="img-fluid center" alt="" />
                      <div className="Integration-head">
                        <h2 className="">SMART RFID SOLUTIONS</h2>
                      </div>  
                      <a className="row retailsol_3 portfolio-info" data-target="#carouselExampleIndicators" data-slide-to="4">
                        <h4 className="">SMART RFID SOLUTIONS</h4>
                        <p>Smart RFID enables swift and hassle-free shopping experience. It not only provides hands free operations for your warehouse activities but avoids thefts and counterfeits. Our next-gen RFID products aim to solve multiple problems cycle from  ‘Purchase Order to Point-of-Sale’.</p><br />
                      </a>  
                    </div>
                    <div className="col-lg-4 col-md-4 portfolio-item filter-web redirection32 animate__animated animate__fadeInRight col-md-12">
                      <img src="../assets/img/retail/webmobileDEV.jpg" className="img-fluid center" alt="" />
                      <div className="Integration-head">
                        <h2 className="">WEB & MOBILE DEVELOPMENT</h2>
                      </div>
                      <a className="row retailsol_3 portfolio-info" data-target="#carouselExampleIndicators" data-slide-to="5">
                        <h4 className="">WEB & MOBILE DEVELOPMENT</h4>
                        <p>Our design team is committed to giving your customer the best user experience so that they are compelled to return to your websites and mobile apps.</p><br />
                      </a>  
                    </div>
          
                  </div>
          
                </div>
              </section>
              : <></> }
          
                {(isTestimonials || checkHash === "#pricing")?
                    <section id="pricing" className={(checkHash === "#pricing")?"pricing clf1 scrolltosec":"pricing clf1"} >
                        <div className="container   animate__animated animate__zoomIn">
                            <div className="section-title animate__animated animate__fadeInDown">
                                <h2 className="text-center cs11" >Testimonials</h2>
                                {/* <p className="cs12">Here’s what some of our global partners have to say about us:</p> */}
                            </div>
                            <div className="row">
                                <div className="col-sm-12">			
                                    <div id="myCarousel" className=" slide showmore700Slider" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                            <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                                            <li data-target="#myCarousel" data-slide-to="1"></li>
                                        </ol>   
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <div className="row clf2" >
                                                    <div className="col-sm-4 animate__animated animate__fadeInLeft clf3" >
                                                        <div className="testimonial-new">
                                                            <div className="tt-vox">
                                                                <img alt="" className="client_bk img-fluid" src="assets/img/pwc-logo.jpeg"/>
                                                            </div>
                                                            <div className="tt-vox1">
                                                                <img alt="" className="client_bk img-fluid" src="assets/img/doublequotes.png"/>
                                                            </div>
                                                            <p className="cs13">Integration experts, if I had to pick a name, it would be Cyntra Labs. </p>
                                                            <div className="media-body">
                                                                <div className="overview clf4" >
                                                                    <div className="name"><b className="cs14">Ben Zelinsky</b></div>
                                                                    <div className="details cs15">Partner, PwC</div>
                                                                </div>										
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 animate__animated animate__fadeInMiddle clf5" >
                                                        <div className="testimonial-new">
                                                            <div className="tt-vox clf6" >
                                                                <img alt="" className="client_bk img-fluid clf7"  src="assets/img/clients/shiseido.png"/>
                                                            </div>
                                                            <div className="tt-vox1">
                                                                <img alt="" className="client_bk img-fluid" src="assets/img/doublequotes.png"/>
                                                            </div>
                                                            <p className="cs13">Parterning with Cyntra was the best decision, they are not only 
                                                                industry experts in the SAP S4, SAP PI/PO, but played a key role in
                                                                integrating critical business processes and systems in our landscape.</p>
                                                            <div className="media-body">
                                                                <div className="overview clf8" >
                                                                    <div className="name"><b className="cs14">Krishna Kashyap</b></div>
                                                                    <div className="details cs15">Vice President, Shiseido</div>
                                                                </div>										
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4 animate__animated animate__fadeInRight clf9" >
                                                        <div className="testimonial-new">
                                                            <div className="tt-vox">
                                                                <img alt="" className="client_bk img-fluid" src="assets/img/clients/wendys.png"/>
                                                            </div>
                                                            <div className="tt-vox1">
                                                                <img alt="" className="client_bk img-fluid" src="assets/img/doublequotes.png"/>
                                                            </div>
                                                            <p className="cs13">With Cyntra we have derived much of what we see and speak about for our future.</p>
                                                            <div className="media-body">
                                                                <div className="overview clf4" >
                                                                    <div className="name"><b className="cs14">Chander Srivastava</b></div>
                                                                    <div className="details cs15">CTO, Wendy`s India</div>											
                                                                </div>										
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>			
                                            </div>
                                            <div className="carousel-item">
                                                <div className="row clf2" >
                                                    <div className="col-sm-4 animate__animated animate__fadeInLeft clf3" >
                                                        <div className="testimonial-new">
                                                            <div className="tt-vox">
                                                                <img alt="" className="client_bk img-fluid" src="assets/img/clients/bk.png"/>
                                                            </div>
                                                            <div className="tt-vox1">
                                                                <img alt="" className="client_bk img-fluid" src="assets/img/doublequotes.png"/>
                                                            </div>
                                                            <p className="cs13">The synthesis of our unstructured data through Cyntra's 
                                                            Liv Reporting was very compelling. With real-time insights decision making has been responsive.</p>
                                                            <div className="media-body">
                                                            <div className="overview clf4" >
                                                                <div className="name"><b className="cs14">Kiran Komatla</b></div>
                                                                <div className="details cs15">Sr VP IT India, Burger King</div>
                                                            </div>										
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 animate__animated animate__fadeInMiddle clf3" >
                                                    <div className="testimonial-new">
                                                        <div className="tt-vox">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/cat-technologies-logo.jpg"/>
                                                        </div>
                                                        <div className="tt-vox1">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/doublequotes.png"/>
                                                        </div>
                                                        <p className="cs13">Working with Cyntra is speedy, simple and secure.</p>
                                                        <div className="media-body">
                                                            <div className="overview clf4" >
                                                                <div className="name"><b className="cs14">Don Mackenzie</b></div>
                                                                <div className="details cs15">Senior Vice President, CAT Technologies</div>
                                                            </div>										
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 animate__animated animate__fadeInRight clf3" >
                                                    <div className="testimonial-new">
                                                        <div className="tt-vox">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/cat-technologies-logo.jpg"/>
                                                        </div>
                                                        <div className="tt-vox1">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/doublequotes.png"/>
                                                        </div>
                                                        <p className="cs13">Partnering and teaming with Cyntra for industry solutions has been a win 
                                                            win always. With their network of industry experts and what they bring to 
                                                            the table is exciting. </p>
                                                        <div className="media-body">
                                                            <div className="overview clf4" >
                                                                <div className="name"><b className="cs14">Aubrey Evans</b></div>
                                                                <div className="details cs15">Partner, CAT Technologies</div>
                                                            </div>										
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>			
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div id="myCarousel1" className="carousel slide hide700Slider" data-ride="carousel">
                                    <ol className="carousel-indicators">
                                        <li data-target="#myCarousel1" data-slide-to="0" className="active"></li>
                                        <li data-target="#myCarousel1" data-slide-to="1"></li>
                                        <li data-target="#myCarousel1" data-slide-to="2"></li>
                                        <li data-target="#myCarousel1" data-slide-to="3"></li>
                                        <li data-target="#myCarousel1" data-slide-to="4"></li>
                                        <li data-target="#myCarousel1" data-slide-to="5"></li>
                                    </ol>   
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="row clf2" >
                                                <div className="col-sm-4 animate__animated animate__fadeInLeft clf3" >
                                                    <div className="testimonial-new">
                                                        <div className="tt-vox">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/pwc-logo.jpeg"/>
                                                        </div>
                                                        <div className="tt-vox1">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/doublequotes.png"/>
                                                        </div>
                                                        <p className="cs13">Integration experts, if I had to pick a name, it would be Cyntra Labs. </p>
                                                        <div className="media-body">
                                                            <div className="overview clf4" >
                                                                <div className="name"><b className="cs14">Ben Zelinsky</b></div>
                                                                <div className="details cs15">Partner, PwC</div>
                                                            </div>										
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>			
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row clf2" >
                                                <div className="col-sm-4 animate__animated animate__fadeInMiddle clf3clf3clf3clf3clf3clf3" >
                                                    <div className="testimonial-new">
                                                        <div className="tt-vox clf6">
                                                            <img alt="" className="client_bk img-fluid clf12"  src="assets/img/clients/shiseido.png"/>
                                                        </div>
                                                        <div className="tt-vox1">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/doublequotes.png"/>
                                                        </div>
                                                        <p className="cs13">Parterning with Cyntra was the best decision, they are not only 
                                                            industry experts in the SAP S4, SAP PI/PO, but played a key role in
                                                            integrating critical business processes and systems in our landscape.</p>
                                                        <div className="media-body">
                                                            <div className="overview clf4" >
                                                                <div className="name"><b className="cs14">Krishna Kashyap</b></div>
                                                                <div className="details cs15">Vice President, Shiseido</div>
                                                            </div>										
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>			
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row clf2" >
                                                <div className="col-sm-4 animate__animated animate__fadeInRight clf3" >
                                                    <div className="testimonial-new">
                                                        <div className="tt-vox">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/clients/wendys.png"/>
                                                        </div>
                                                        <div className="tt-vox1">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/doublequotes.png"/>
                                                        </div>
                                                        <p className="cs13">With Cyntra we have derived much of what we see and speak about for our future.</p>
                                                        <div className="media-body">
                                                            <div className="overview clf4" >
                                                                <div className="name"><b className="cs14">Chander Srivastava</b></div>
                                                                <div className="details cs15">CTO, Wendy`s India</div>											
                                                            </div>										
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>			
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row clf2" >
                                                <div className="col-sm-4 animate__animated animate__fadeInLeft clf3" >
                                                    <div className="testimonial-new">
                                                        <div className="tt-vox">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/clients/bk.png"/>
                                                        </div>
                                                        <div className="tt-vox1">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/doublequotes.png"/>
                                                        </div>
                                                        <p className="cs13">The synthesis of our unstructured data through Cyntra's 
                                                        Liv Reporting was very compelling. With real-time insights decision making has been responsive.</p>
                                                        <div className="media-body">
                                                            <div className="overview clf4" >
                                                                <div className="name"><b className="cs14">Kiran Komatla</b></div>
                                                                <div className="details cs15">CEO India, Burger King</div>
                                                            </div>										
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>			
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row clf2" >
                                                <div className="col-sm-4 animate__animated animate__fadeInMiddle clf3" >
                                                    <div className="testimonial-new">
                                                        <div className="tt-vox">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/cat-technologies-logo.jpg"/>
                                                        </div>
                                                        <div className="tt-vox1">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/doublequotes.png"/>
                                                        </div>
                                                        <p className="cs13">Working with Cyntra is speedy, simple and secure.</p>
                                                        <div className="media-body">
                                                            <div className="overview clf4" >
                                                                <div className="name"><b className="cs14">Don Mackenzie</b></div>
                                                                <div className="details cs15">Senior Vice President, CAT Technologies</div>
                                                            </div>										
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>			
                                        </div>
                                        <div className="carousel-item">
                                            <div className="row clf2" >
                                                <div className="col-sm-4 animate__animated animate__fadeInRight clf3" >
                                                    <div className="testimonial-new">
                                                        <div className="tt-vox">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/cat-technologies-logo.jpg"/>
                                                        </div>
                                                        <div className="tt-vox1">
                                                            <img alt="" className="client_bk img-fluid" src="assets/img/doublequotes.png"/>
                                                        </div>
                                                        <p className="cs13">Partnering and teaming with Cyntra for industry solutions has been a win 
                                                            win always. With their network of industry experts and what they bring to 
                                                            the table is exciting. </p>
                                                        <div className="media-body">
                                                            <div className="overview clf4" >
                                                                <div className="name"><b className="cs14">Aubrey Evans</b></div>
                                                                <div className="details cs15">Partner, CAT Technologies</div>
                                                            </div>										
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>			
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                : <></> }
                {(isScheduleDiscoveryCall || checkHash === "#cta")?
                    <section id="cta" className={(checkHash === "#cta")?"cta animate__animated animate__zoomIn scrolltosec":"cta animate__animated animate__zoomIn"}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9 text-lg-left" style={{marginTop:"47px"}}>
                                    <h3 className="intl">Interested in Learning MORE?</h3>
                                        <a className="cta-btn align-middle" href="#contact">Schedule a Discovery Call</a>
                                </div>
                            </div>
                        </div>
                    </section>
                : <></> }
                {(isPartners || checkHash === "#Partners")?          
                    <section id="Partners" className={(checkHash === "#Partners")?"pricing  scrolltosec":"pricing "}>
                        <div className="container" >
                            <div className="section-title animate__animated animate__fadeInDown">
                                <h2 className="text-center">PARTNERS</h2>
                                
                            </div>
                            <div className="row  animate__animated animate__zoomIn">
                                <div className="col-lg-2 col-md-6 partnersb">
                                        <div className="box">
                                        <img alt="" className="client_bk img-fluid p-t-b shisedeo_logodiv1" src="assets/img/clients/movado.png"/> 
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 mt-4 mt-md-0 partnersb">
                                    <div className="box qvc_logodiv1">
                                        <img alt="" className="client_bk img-fluid" src="assets/img/clients/qvc.png"/> 
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 mt-4 mt-lg-0 partnersb">
                                    <div className="box">
                                        <img alt="" className="client_bk img-fluid p-t-b shisedeo_logodiv1" src="assets/img/clients/shiseido.png"/>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 partnersb">
                                    <div className="box">
                                        <img alt="" className="client_bk img-fluid" src="assets/img/clients/wendys.png"/> 
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 mt-4 mt-md-0 partnersb">
                                    <div className="box shisedeo_logodiv1">
                                        <img alt="" className="client_bk img-fluid" src="assets/img/clients/bk.png"/> 
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 mt-4 mt-lg-0 shisedeo_logodiv1 partnersb">
                                    <div className="box shisedeo_logodiv1">
                                        <img alt="" className="client_bk img-fluid" src="assets/img/clients/macd.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                : <></> }
                {(isFooter || checkHash === "#contact")?
                        <section id="contact" className={(checkHash === "#contact")?"contact clf10 clf13 scrolltosec":"contact clf10 clf13"} >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="footerlogo">
                                            <img alt="" className="footer_logo img-fluid" src="assets/img/Logo.png"/>
                                        </div>
                                    </div> 
                                </div>   
                                <div className="row mt-5">
                                    <div className="col-lg-4  animate__animated animate__fadeInLeft">
                                        <h4>GLOBAL LOCATIONS</h4>
                                        <div className="info">
                                            <div className="address">
                                                <p><img alt="" src="assets/img/USAflag.png"/></p>
                                                <p>Harmoncove Tower<br /> Suite 912, Secaucus-07094,<br /> New Jersey</p>
                                                <p>
                                                <a href="mailto: info@cyntralabs.com">info@cyntralabs.com</a></p>
                                            </div>
                                            <div className="address">
                                                <p><img alt="" src="assets/img/indiaFlag.png"/></p>  
                                                <p>B-12, First Floor,<br /> Sector-1 Noida-201301,<br /> UP</p>
                                                <p> <a href="mailto: info@cyntralabs.com">info@cyntralabs.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4  animate__animated animate__fadeInUp">
                                        <h4>QUICK LINKS</h4>
                                        <div className="footerlink">
                                            <ul className="quicklink">
                                                <li><NavLink exact className="fnt_sz" activeClassName="active" to={"integration"} title="Intigration"> + Integration</NavLink></li>
                                                <li><NavLink exact className="fnt_sz" activeClassName="active" to={"retail"} title="Retail"> + Retail</NavLink></li>
                                                <li><a className="fnt_sz" href="#productsnservices" title="Product & Services"> + Product & Services</a></li>
                                                <li><a target="_blank" className="fnt_sz" href="cyntra_retail_pdf.pdf" title="Download Brochure"> + Download Brochure</a></li>
                                                <li><NavLink exact className="fnt_sz" activeClassName="active" to={"about"} target="" title="About Us"> + About Us</NavLink></li>
                                                <li><NavLink exact className="fnt_sz" activeClassName="active" to={"privacy-policy"} target="" title="Privacy Policy"> + Privacy Policy</NavLink></li>
                                                <li><NavLink exact className="fnt_sz" activeClassName="active" to={"Product"} target="" title="product"> + Our Products</NavLink></li>
                                                {/* <li><a target="_blank" className="fnt_sz" href="https://www.indiaewasterecycler.com/ewastecrm/?link_id=5c8c959f712bb" title="Privacy Policy" > + E-Waste</a></li> */}
                                            </ul>
                                        </div>    
                                    </div>
                                    <div className="col-lg-4 mt-5 mt-lg-0  animate__animated animate__fadeInRight">
                                        <h4>REACH US TODAY</h4>
                                        <form id="contact-form"  role="form" ref={formRef} className="">
                                            <div className="form-group">
                                                <input type="text" name="name" autoComplete="off" className="form-control footerform_input" id="name" placeholder="Name" data-rule="minlen:2" data-msg="This field is mandatory."/>
                                                <div className="validate"></div>
                                            </div>
                                            <div className="form-group">
                                                <input type="text" name="designation" autoComplete="off" className="form-control footerform_input" id="designation" placeholder="Designation" data-rule="minlen:2" data-msg="This field is mandatory." />
                                                <div className="validate"></div>
                                            </div>
                                            <div className="form-group">
                                                <input type="email" className="form-control footerform_input" autoComplete="off" name="email" id="email" placeholder="Email" data-rule="email" data-msg="This field is mandatory." />
                                                <div className="validate"></div>
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="footerform_input form-control" autoComplete="off" name="contact" id="contact" onInput={(event)=> event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')} maxLength="10" placeholder="Contact No." data-rule="minlen:10" data-msg="This field is mandatory." />
                                                <div className="validate"></div>
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="footerform_input form-control" autoComplete="off" name="industry" id="Industry" placeholder="Industry" data-rule="minlen:2" data-msg="This field is mandatory." />
                                                <div className="validate"></div>
                                            </div>
                                            <div className="form-group">
                                                <input type="text" className="footerform_input form-control" autoComplete="off" name="companyname" id="CompanyName" placeholder="Company Name" data-rule="minlen:2" data-msg="This field is mandatory." />
                                                <div className="validate"></div>
                                            </div>
                                            <div className="form-group">
                                                <select name="solution" defaultValue="" className="footerform_input form-control">
                                                    <option value="" disabled hidden>Solution</option>
                                                    <option className="clf11" value="Integration">Integration</option>
                                                    <option className="clf11" value="Retail">Retail</option>
                                                </select>
                                                <div className="validate"></div>
                                            </div>
                                            <div className="form-group">
                                                <textarea className="form-control " name="message" rows="5" data-rule="" data-msg="This field is mandatory." placeholder="Message"></textarea>
                                                <div className="validate"></div>
                                            </div>
                                            {/* <ReCAPTCHA
                                                ref={recaptchaRef}
                                                size="normal" 
                                                className="g-recaptcha"
                                                sitekey="6LeuB8gZAAAAAHPB7fkNyq4bAdxFXp3RZ2fWKaaQ"
                                            /> */}
                                          
                                            {(message)?
                                                <div className="form-group" style={{ margin: '7px 0px', color: '#00a2fe' }}>
                                                    <div className="loading">{message}</div>
                                                </div>
                                            : ""}
                                            <div className="text-left" id="error1">
                                                <button onClick={() => onSubmitWithReCAPTCHA()} type="button" className="btn12">Let's Connect</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                : <></> }
            
                <footer id="footer">
                    <div className="container">
                        {/* <div className="social-links">
                            <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                            <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                            <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                            <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                            <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                        </div> */}
                        <div className="copyright">
                            &copy; COPYRIGHT <strong><span>CyntraLabs</span></strong>. All Rights Reserved
                        </div>
                    </div>
                </footer>
                <a href="#" className="back-to-top"><i className="ri-arrow-up-line"></i></a>
            </>
        )
        
}
export default Footer