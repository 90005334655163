import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class BlogClb3 extends Component {
    render() {
        return (
                <>
                <main id="main">
                <section className="blog757">
                    </section>
                    <section className="leastblog_div9">
                        <div className="leatest_bloghead32">
                            <div className="container">
                                <h2 className="about_head1">Ghost Kitchens</h2>
                                <p className="heading_para656">The New Normal</p>
                                <p className="heading_para1">
                                    by Isha Lall | May 15, 2020
                                </p>
                            </div>
                        </div>
                    </section>
                    <section class="ourteam_section1 section545">
                        <div class="row ourteam_div1">
                            <p class="blog_head65">
                                Over the past few years, online food delivery was anyway on an upswing. But, this global pandemic, COVID-19 has turned out to be a trend setter for Ghost Kitchens. 
                            </p>
                            <p>
                                Ghost Kitchen has caused a drift in the food industry, as the dine-in option now takes a backseat, the fundamental role of Ghost kitchens aka “Dark kitchens” is preparing and providing a ‘delivery only service’ to the customers. 
                            </p>
                            <div class="col-sm-8 pl-0">
                                <h3>A Multifarious Phenomenon </h3>

                                <p class="blog_para4">Ghost kitchens are <b>not restricted to a specific business model,</b> they can have many forms such as an outsourced kitchen to even as simple as a takeaway service.
                                </p>

                                <p class="blog_para4">Ghost kitchens also are very <b>flexible</b> in adapting according to the volatility of the market and the nature of consumer demands.
                                </p>

                                <h3>New direction to the market </h3>
                                <p class="blog_para4">The pandemic is responsible for <span class="blog_span32">setting back</span> the food industry by <span class="blog_span86">75-90%</span> worldwide.  Given the number of restaurants that have to shut the ‘dine-in’ option down, the survival kit is takeout and delivery.</p>
                                <p class="blog_para4">Though a relatively new model, it has not stopped Ghost kitchens in becoming a trend and creating a prominent presence in the current F&amp;B retail market environment.</p>
                            </div>
                            <div class="col-sm-4">
                            <img src="./assets/img/gost-kitchen.jpg" class="blog_img32" title="Connected Retail"/>
                            </div>
                            <h3>No ‘Dine –in’ challenge for restaurants</h3>
                            <p class="blog_para4">People are avoiding dine-in, and it has caused:</p>
                            <ul class="blog_para4 blog_li76">
                                <li>Heavy drop in footfall</li>
                                <li>Sustainability issues</li>
                                <li>Low inflows</li>
                            </ul>

                            <h3>Advantages of going Ghost</h3>
                            <p class="blog_para4"><span class="blog_para65">-Lower costs:</span> The framework expedites process and removes dine-in expenses such as rent, salaries, extra raw material</p>
                            <p class="blog_para4"><span class="blog_para65">-Reduces wastage:</span> Maximum raw materials in any restaurant are perishable. </p>
                            <p class="blog_para4"><span class="blog_para65">-Creates a “Host Kitchen” environment.</span> Existing restaurants can rent out kitchen space and even labor to third-party brands for delivery, making optimal use of their restaurant space. </p>
                            <p class="blog_para4"><span class="blog_para65">-Streamlines and simplifies retail chains.</span></p>
                            <img src="./assets/img/retail10.png" class="blog_icon4"/>
                            <img src="./assets/img/retail12.png" class="blog_icon4"/>
                            <img src="./assets/img/retail13.png" class="blog_icon4"/>
                            <img src="./assets/img/retail11.png" class="blog_icon4"/>


                            <h3 style={{width:'100%', marginTop:'15px'}}>Things to keep in mind! </h3>
                            <p class="blog_para4">-Ensuring packaging consistency and quality</p>
                            <p class="blog_para4">-Deciding authority and answerability </p>
                            <p class="blog_para4">-Ensuring delivery executive’s service standards</p>

                            <h3>APPS TO THE RESCUE!</h3>
                            <p class="blog_para4">With <b>online ordering apps</b> it is even simpler to execute the process of getting food from the <b>kitchen to the customer!</b></p>
                            <p class="blog_para4">Just, <b>Add to cart → Make the payment</b></p>
                            <img src="./assets/img/cart87.png" class="blog_icon4"/>
                            <img src="./assets/img/currency6.png" class="blog_icon4"/>
                            <p class="blog_para4">Also, customers get a wider variety on one platform and your business expands its delivery footprint.</p>

                            <h3>Ghost is the Future</h3>
                            <p class="blog_para4">From being deemed as a threat to restaurant industries to being the next trend, ghost kitchens have gained a lot of traction.   </p>
                            <p class="blog_para4">USA alone is expected to see a 25% rise in sales each year for the next 5 years via 300 facilities amounting to $300 million in yearly sales. </p>
                            <p class="blog_para4">The customers are shifting towards higher accessibility complemented with maximum ease and that is the sector Ghost kitchens are sculpted to cater.</p>

                    </div>
                </section>

                </main>
            </>
    )
}
}