import React, { Component } from 'react';

export default class Product extends Component {
    render() {
        return (
            <>
            <main id="main">


                    <section class="aboutheader_main3">
                        <div class="container">
                            <h2 class="about_head1">Cyntra Hardware  </h2>
                            <p class="heading_para1">Delivering Innovation and Excellence beyond Expectation </p>
                        </div>
                    </section>

                    <section class="inner-page">
                        <div class="container">
                            <div class="row">

                                <div class="col-sm-3 pos-15">
                                    <img class="img-pos-model" src="assets/img/Hardware/POS-15.6inch-7inch.jpg"/>
                                    <h4 class="m-t15">POS – 15.6inch</h4>
                                </div>
                            
                                <div class="col-sm-3 pos-8">
                                    <img class="img-pos-model" src="assets/img/Hardware/POS-8inch.jpg"/>
                                    <h4 class="m-t15">POS – 8inch</h4>
                                </div>

                                <div class="col-sm-3 pos-8-new">
                                    <img class="img-pos-model" src="assets/img/Hardware/POS-8inch-new.jpg"/>
                                    <h4 class="m-t15">POS – 7inch</h4>
                                </div>

                                <div class="col-sm-3 pos-15-Dual">
                                    <img class="img-pos-model" src="assets/img/Hardware/POS-15-inch-Dual-Display.jpg"/>
                                    <h4 class="m-t15">POS – 15 inch </h4>
                                </div>

                                <div class="col-sm-3 pos-15Inch">
                                    <img class="img-pos-model" src="assets/img/Hardware/POS-15-inch.jpg"/>
                                    <h4 class="m-t15">POS – 15 inch</h4>
                                </div>

                                <div class="col-sm-3 pos-10">
                                    <img class="img-pos-model" src="assets/img/Hardware/POS-10inch.jpg"/>
                                    <h4 class="m-t15">POS – 10inch</h4>
                                </div>

                                <div class="col-sm-3 Poyint">
                                    <img class="img-pos-model" src="assets/img/Hardware/Poyint.jpg"/>
                                    <h4 class="m-t15">POYNT</h4>
                                </div>

                                <div class="col-sm-3 kiosk">
                                    <img class="img-pos-model" src="assets/img/Hardware/kiosk.jpg"/>
                                    <h4 class="m-t15">KIOSK</h4>
                                </div>

                                <div class="col-sm-3 Epson">
                                    <img class="img-pos-model" src="assets/img/Hardware/Epson.jpg"/>
                                    <h4 class="m-t15">Epson Printer</h4>
                                </div>

                                <div class="col-sm-3 Star">
                                    <img class="img-pos-model" src="assets/img/Hardware/Star.jpg"/>
                                    <h4 class="m-t15">Star Printer</h4>
                                </div>

                                <div class="col-sm-3 Kitchen">
                                    <img class="img-pos-model" src="assets/img/Hardware/Kitchen.jpg"/>
                                    <h4 class="m-t15">Kitchen Printer</h4>
                                </div>

                                <div class="col-sm-3 Cashdrawer">
                                    <img class="img-pos-model" src="assets/img/Hardware/Cashdrawer.jpg"/>
                                    <h4 class="m-t15">Cash Drawer</h4>
                                </div>

                            </div>
                        </div>
                    </section>
                    </main>

            </>
        )
    }
}