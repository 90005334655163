import React, { Component } from 'react';
import {BrowserRouter, Route, Switch } from 'react-router-dom'

import ScrollToTop from '../Layout/ScrollToTop'
import AppRoute from '../Layout/AppRoute'
import DefaultLayout from '../Layout/Default'

import Home from '../Pages/Home'
import Integration from '../Pages/Integration'
import Retail from '../Pages/Retail'
import Demo from '../Pages/Demo'
import Kiosks from '../Pages/Kiosks'
import ContactUs from '../Pages/ContactUs'
import Product from '../Pages/Product'
import About from '../Pages/About'
import PrivacyPolicy from '../Pages/PrivacyPolicy'
import PageNotFound from '../Pages/PageNotFound'
import Servicenow from '../Pages/Servicenow';
import SAP from '../Pages/SAP';
import BlankLayout from '../Layout/BlankLayout';

import Blog from '../Pages/blog/index'
import BlogClb1 from '../Pages/blog/blogClb1'
import BlogClb2 from '../Pages/blog/blogClb2'
import BlogClb3 from '../Pages/blog/blogClb3'
import BlogClb4 from '../Pages/blog/blogClb4'
import BlogClb5 from '../Pages/blog/blogClb5'
import Home3 from '../Pages/NewDemo';
import Helmet from 'react-helmet';
import Breez from '../Pages/Breez';
import Swyft from '../Pages/Swyft';
import Salesforce from '../Pages/Salesforce';
import CustomLayout from '../Layout/CustomLayout';
import KioskRedirect from '../Pages/KioskRedirect';

import Fashion from '../Pages/Industries/Fashion';
import Entertainment  from '../Pages/Industries/Entertainment'
import Fnb from '../Pages/Industries/Fnb';
import Fnm  from '../Pages/Industries/Fitness'
import Healthcare from '../Pages/Industries/Healthcare';
import Hospitality  from '../Pages/Industries/Hospitality'
import Banking from '../Pages/Industries/Banking';

export default function Routes(props) {
    return (                         
        <ScrollToTop>


            <Switch>
                <AppRoute {...props} show={{isFooter:true, isPartners: true, isScheduleDiscoveryCall: true, isTestimonials: true }} path="/" exact component={Home} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: true, isScheduleDiscoveryCall: true, isTestimonials: true }} path="/index" exact component={Home} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: true, isScheduleDiscoveryCall: true, isTestimonials: true }} path="/home" exact component={Home} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/servicenow" exact component={Servicenow} layout={CustomLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/sap-ariba" exact component={SAP} layout={CustomLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: true, isScheduleDiscoveryCall: true, isTestimonials: true }} path="/salesforce" exact component={Salesforce} layout={CustomLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: true, isScheduleDiscoveryCall: true, isTestimonials: true }} path="/integration" exact component={Integration} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: true, isScheduleDiscoveryCall: true, isTestimonials: true }} path="/retail" exact component={Retail} layout={DefaultLayout} />
                {/* <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/demo" exact component={Demo} layout={DefaultLayout} /> */}
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/breez" exact component={Breez} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/demo" exact component={Home3} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/swyft" exact component={Swyft} layout={DefaultLayout} />

                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/about" exact component={About} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:false, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/kiosks" exact component={KioskRedirect} layout={BlankLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: true, isScheduleDiscoveryCall: true, isTestimonials: true }} path="/contact-us" exact component={ContactUs} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/Product" exact component={Product} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/privacy-policy" component={PrivacyPolicy} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:false, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/blogs" component={Blog} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:false, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/blog-contactless-ordering-payments" component={BlogClb1} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:false, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/blog-connect-with-right-analytics" component={BlogClb2} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/blog-ghost-kitchens" component={BlogClb3} layout={DefaultLayout} />
                {/* <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/blog-connect-with-right-analytics"  component={BlogClb6} layout={DefaultLayout} /> */}
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/blog-future-of-retail" component={BlogClb5} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/blog-breeze" component={BlogClb4} layout={DefaultLayout} />
                
                
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/food" exact component={Fnb} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/entertainment" exact component={Entertainment} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/fashion"  component={Fashion} layout={DefaultLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="/banking"  component={Banking} layout={DefaultLayout} />

                <AppRoute {...props} show={{isFooter:true, isPartners: true, isScheduleDiscoveryCall: true, isTestimonials: true }} path="/hospitality"  component={Hospitality} layout={CustomLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: true, isScheduleDiscoveryCall: true, isTestimonials: true }} path="/healthcare"  component={Healthcare} layout={CustomLayout} />
                <AppRoute {...props} show={{isFooter:true, isPartners: true, isScheduleDiscoveryCall: true, isTestimonials: true }} path="/fitness"  component={Fnm} layout={CustomLayout} />
                
                <AppRoute {...props} show={{isFooter:false, isPartners: false, isScheduleDiscoveryCall: false, isTestimonials: false }} path="*" component={PageNotFound} layout={DefaultLayout} />
                

            </Switch>
        </ScrollToTop>
    )
}