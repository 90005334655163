import React from "react";
import {
  Card,
  Grid,
  List,
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormGroup,
} from "@material-ui/core";

import { Input, Button } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Footer from "../Include/Footer";
import service_now from "./assets/service_now.png";
import service_now1 from "./assets/service_now1.png";
import service_now2 from "./assets/service_now2.png";
import service_now3 from "./assets/service_now3.png";
import service_now4 from "./assets/SN_icon (2).png";
import service_now5 from "./assets/SN_icon (6).png";
import service_now6 from "./assets/SN_icon (4).png";
import service_now7 from "./assets/SN_icon (5).png";
import service_now8 from "./assets/SN_icon (9).png";
import service_now9 from "./assets/SN_icon (8).png";
import service_now10 from "./assets/SN_icon (3).png";
import service_now11 from "./assets/SN_icon (7).png";
import service_now12 from "./assets/SN_icon (1).png";
import service_now13 from "./assets/SalesForce Page/SF_icon (4).png";
import service_now_logo from "./assets/serviceNow_Icon.png";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "2.25rem",
    paddingBottom: "2.25rem",
  },
  inputbtn: {
    borderRadius: "0.5rem",
    backgroundColor: "#F0F4F3",
    height: "3rem",
    padding: "0.3rem",
    paddingLeft: "1rem",
  },
  listtxt: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  cardHeight: {
    height: "13rem",
  },
}));

export default function Servicenow() {
  const classes = useStyles();
  const myStyle = {
    backgroundImage:
      "url('https://cyntra-images.s3.ap-south-1.amazonaws.com/email-templete-img/MicrosoftTeams-image+(28).png')",
    // height: "100vh",
    // fontSize: "50px",
    backgroundSize: "100%",
    // backgroundHeight: "60%",
    // backgroundRepeat: "no-repeat",
  };
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1500px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1500px)" });

  return (
    <div>
      <>
        <Grid container justifyContent="center" xs={12} sm={12} md={12} lg={12}>
          {/* {isDesktopOrLaptop && (
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundImage: `url(${service_now})`,
                backgroundSize: "100%",
                backgroundColor: "#011844",
                backgroundRepeat: "no-repeat",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

              <Grid item xs={10} sm={10} md={10} lg={10}>
                <Grid
                  container
                  justifyContent="left"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    style={{ marginTop: "13rem", paddingBottom: "3rem" }}
                  >
                    <Typography
                      align="left"
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "40px",
                        lineHeight: "2.8rem",
                        paddingBottom: "1.5rem",
                      }}
                    >
                      Digitally Transform and Automate Retail Operations with
                      ServiceNow
                    </Typography>{" "}
                    <Typography
                      align="left"
                      style={{
                        color: "white",
                        fontSize: "15px",
                      }}
                    >
                      Our ServiceNow implementation revolutionizes retail
                      operations by streamlining workflows, centralizing data,
                      and automating routine tasks. Through ServiceNow, we help
                      customers optimize their operations, reduce overheads, and
                      enhance customer experiences. <br /> <br />
                      This helps them focus on growth and innovation. With
                      real-time insights and a unified system, retailers can
                      make data-driven decisions, respond swiftly to market
                      changes, and stay ahead in the competitive retail
                      environment.
                    </Typography>{" "}
                    <Grid
                      item
                      align="left"
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={{ padding: "2rem" }}
                    >
                      <Button
                        href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                        style={{
                          color: "white",
                          backgroundColor: "#3F9AF3",
                          fontWeight: "bold",
                          borderRadius: "10rem",
                          padding: "0.7rem",
                        }}
                      >
                        Schedule a call
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>




              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>


            </Grid>
          )} */}

         
            <Grid
              container
              justifyContent="center"
              style={{
                backgroundColor: "#011844",
                paddingTop:"10rem"
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

              <Grid item xs={10} sm={10} md={10} lg={10}>
                <Grid container xs={12} sm={12} md={12} lg={12}>
              
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Grid
                      container
                      justifyContent="left"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginTop: "1rem", paddingBottom: "3rem" }}
                      >
                        <Typography
                          align="left"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "40px",
                            lineHeight: "2.8rem",
                            paddingBottom: "1.5rem",
                          }}
                        >
                          Digitally Transform and Automate Retail Operations
                          with ServiceNow
                        </Typography>{" "}
                        <Typography
                          align="left"
                          style={{
                            color: "white",
                            fontSize: "15px",
                          }}
                        >
                          Our ServiceNow implementation revolutionizes retail
                          operations by streamlining workflows, centralizing
                          data, and automating routine tasks. Through
                          ServiceNow, we help customers optimize their
                          operations, reduce overheads, and enhance customer
                          experiences.
                          <br /> <br /> This helps them focus on growth and
                          innovation. With real-time insights and a unified
                          system, retailers can make data-driven decisions,
                          respond swiftly to market changes, and stay ahead in
                          the competitive retail environment.
                        </Typography>{" "}
                        <Grid
                          item
                          align="left"
                          xs={10}
                          sm={10}
                          md={6}
                          lg={6}
                          style={{ paddingTop: "2rem" }}
                        >
                          <Button
                            href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                            style={{
                              color: "white",
                              backgroundColor: "#3F9AF3",
                              fontWeight: "bold",
                              borderRadius: "10rem",
                              padding: "0.7rem",
                            }}
                          >
                            Schedule a call
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} align="right">
                    <img
                      src={service_now_logo}
                      style={{ width: "90%", marginTop:"3rem",marginBottom:"2rem"}}
                      align="right"
                    ></img>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            </Grid>
     

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingTop: "2.5rem" }}
          >
            <Grid item xs={1} sm={1} md={12} lg={12}></Grid>

            <Grid item justifyContent="center" xs={10} sm={10} md={12} lg={12}>
              <Grid
                container
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ paddingTop: "2.5rem" }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    style={{
                      paddingBottom: "3rem",
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "2.5rem",
                    }}
                  >
                    A Host of Benefits to Supercharge Your Retail Activities
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ paddingBottom: "1rem" }}
              >
                <Grid
                  item
                  justifyContent="center"
                  style={{ padding: "1rem" }}
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    {" "}
                    <img src={service_now4} align="left"></img>
                    <br />
                    <br />
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1.3rem",
                      }}
                    >
                      Unified Platform
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.9rem",
                      }}
                    >
                      ServiceNow offers a singular, integrated platform,
                      streamlining retail operations and ensuring seamless
                      interdepartmental communication.
                    </Typography>{" "}
                  </>
                </Grid>
                <Grid
                  item
                  style={{ padding: "1rem" }}
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={service_now5} align="left"></img>
                    <br />
                    <br />
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1.3rem",
                      }}
                    >
                      Omnichannel Support{" "}
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.9rem",
                      }}
                    >
                      ServiceNow facilitates a true omnichannel retail
                      experience, integrating in-store, online, and mobile
                      shopping avenues seamlessly.
                    </Typography>{" "}
                  </>
                </Grid>
                <Grid
                  item
                  style={{ padding: "1rem" }}
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={service_now6} align="left"></img>
                    <br />
                    <br />
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1.3rem",
                      }}
                    >
                      Automated Workflows{" "}
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.9rem",
                      }}
                    >
                      Simplify complex retail processes with ServiceNow's
                      automated workflows, driving efficiency from procurement
                      to point-of-sale.
                    </Typography>{" "}
                  </>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ paddingBottom: "4rem" }}
              >
                <Grid
                  item
                  style={{ padding: "1rem" }}
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={service_now7} align="left"></img>
                    <br />
                    <br />
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1.3rem",
                      }}
                    >
                      AI-Driven Insights{" "}
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.9rem",
                      }}
                    >
                      Harness the power of ServiceNow's AI capabilities to
                      predict market trends, optimize inventory, and enhance
                      customer experiences.
                    </Typography>{" "}
                  </>
                </Grid>
                <Grid
                  item
                  style={{ padding: "1rem" }}
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={service_now8} align="left"></img>
                    <br />
                    <br />
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1.3rem",
                      }}
                    >
                      Real-Time Analytics{" "}
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.9rem",
                      }}
                    >
                      Make data-driven decisions with ServiceNow's real-time
                      analytics, offering insights into sales, customer
                      behavior, and inventory management.
                    </Typography>{" "}
                  </>
                </Grid>
                <Grid
                  item
                  style={{ padding: "1rem" }}
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                >
                  <>
                    <img src={service_now9} align="left"></img>
                    <br />
                    <br />
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "1.3rem",
                      }}
                    >
                      Cybersecurity Assurance{" "}
                    </Typography>
                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "2rem",
                        color: "black",
                        fontSize: "0.9rem",
                      }}
                    >
                      With ServiceNow, safeguard your retail data with top-tier
                      cybersecurity measures, ensuring customer trust and
                      regulatory compliance.
                    </Typography>{" "}
                  </>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={12} lg={12}></Grid>

            {/* #NEXT */}
            <Grid
              container
              justifyContent="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "3rem", backgroundColor: "#EBF5FF" }}
            >
              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

              <Grid item xs={10} sm={10} md={10} lg={10}>
                <Grid
                  container
                  justifyContent="left"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography
                      align="left"
                      style={{
                        paddingTop: "2rem",
                        paddingBottom: "2rem",
                        color: "#3F9AF3",
                        fontWeight: "bold",
                        fontSize: "2rem",
                      }}
                    >
                      CUSTOMIZABLE MODULES
                    </Typography>

                    <Typography
                      align="left"
                      style={{
                        paddingBottom: "5rem",
                        color: "black",
                        fontSize: "1rem",
                      }}
                    >
                      ServiceNow offers flexible modules that can be easily
                      adjusted to meet the varied needs of the retail industry.
                      Adjust these modules to match your retail requirements,
                      creating a solution that fits your business aims. By
                      customizing these parts, businesses can make their systems
                      user-friendly, simplify tasks, and improve overall
                      efficiency. <br />
                      Be it managing stock, gathering customer opinions, or
                      analyzing sales, each section can be set to address
                      specific retail challenges. With Cyntra's help, make the
                      most of these adjustments to support business growth and
                      new ideas.{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <img
                      style={{ padding: "4rem", width: "100%" }}
                      src={service_now3}
                    ></img>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={12} lg={12}></Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="left"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid item xs={12} sm={12} md={7} lg={6}>
                  <img
                    style={{ padding: "4rem", width: "100%" }}
                    src={service_now1}
                  ></img>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={6}>
                  <Typography
                    align="left"
                    style={{
                      paddingTop: "3.5rem",
                      paddingBottom: "2rem",
                      color: "#3F9AF3",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    CLOUD INTEGRATION
                  </Typography>

                  <Typography
                    align="left"
                    style={{
                      paddingBottom: "3.5rem",
                      color: "black",
                      fontSize: "1rem",
                    }}
                  >
                    ServiceNow's robust cloud infrastructure will radically
                    transform your retail operations. Its cloud capabilities not
                    only ensure scalability but also provide anytime-anywhere
                    access. High fault tolerance makes it possible for your
                    systems to remain operational, even during peak demands or
                    unexpected disruptions. <br />
                    With real-time data synchronization and backup, you're
                    guaranteed consistent and up-to-date information across all
                    touchpoints. You can easily integrate ServiceNow with other
                    cloud services for smooth data exchange. Using ServiceNow's
                    cloud via Cyntra streamlines operations, cuts costs, and
                    boosts retail agility.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingBottom: "2rem", backgroundColor: "#EBF5FF" }}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="left"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    align="left"
                    style={{
                      paddingTop: "3.5rem",
                      paddingBottom: "2rem",
                      color: "#3F9AF3",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    CUSTOMER SERVICE EXCELLENCE
                  </Typography>

                  <Typography
                    align="left"
                    style={{
                      paddingBottom: "3.5rem",
                      color: "black",
                      fontSize: "1rem",
                    }}
                  >
                    Elevate your customer support service using ServiceNow's
                    sophisticated CRM tools. These tools are designed to handle
                    queries efficiently, track customer interactions, and
                    provide insights into common issues. By ensuring swift
                    resolutions, you not only address immediate concerns but
                    also build a foundation for lasting customer loyalty. <br />
                    Additionally, the CRM's automation capabilities streamline
                    repetitive tasks, allowing your team to focus on complex
                    issues, enhancing overall service quality and ensuring a
                    seamless customer experience.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <img
                    style={{ padding: "4rem", width: "100%" }}
                    src={service_now2}
                  ></img>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>

          <Grid
            container
            justifyContent="left"
            style={{
              backgroundColor: "#3F9AF3",
              paddingTop: "2.5rem",
              paddingBottom: "3rem",
            }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            <Grid item xs={10} sm={10} md={7} lg={7}>
              <Typography
                align="left"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "2rem",
                }}
              >
                Accelerate Your Business Growth{" "}
              </Typography>

              <Typography
                align="left"
                style={{
                  color: "white",
                  fontSize: "1rem",
                }}
              >
                Our seamless ServiceNow implementation perfectly meets the
                unique needs of businesses of all sizes.
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={3}
              lg={3}
              style={{ paddingTop: "1rem" }}
            >
              <Button
                href="https://outlook.office365.com/owa/calendar/Demo@cyntralabs.com/bookings/s/Pufs2O2u9EWs1Wvc-44VRg2"
                style={{
                  color: "#3F9AF3",
                  backgroundColor: "white",
                  borderRadius: "10rem",
                  fontWeight: "bold",
                  padding: "0.5rem",
                }}
              >
                Schedule a call
              </Button>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>

          <Grid
            container
            justifyContent="center"
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Grid
                container
                justifyContent="left"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    align="center"
                    style={{
                      paddingTop: "4rem",
                      paddingBottom: "2rem",
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "2rem",
                    }}
                  >
                    Why Cyntra for ServiceNow Implementation?{" "}
                  </Typography>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ paddingBottom: "4rem" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    style={{ padding: "1rem" }}
                  >
                    <>
                      <img src={service_now10} align="left"></img>
                      <br />
                      <br />
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        Cost-Efficient{" "}
                      </Typography>
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontSize: "0.8rem",
                        }}
                      >
                        Leveraging Cyntra's expertise ensures a faster,
                        error-free implementation, reducing overheads and
                        ensuring a quicker return on investment.
                      </Typography>{" "}
                    </>
                  </Grid>
                  <Grid
                    item
                    justifyContent="center"
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    style={{ padding: "1rem" }}
                  >
                    <>
                      <img src={service_now11} align="left"></img>
                      <br />
                      <br />
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        Expertise & Experience{" "}
                      </Typography>
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontSize: "0.8rem",
                        }}
                      >
                        Cyntra boasts a team of certified ServiceNow
                        professionals who bring years of hands-on experience in
                        diverse industries.{" "}
                      </Typography>{" "}
                    </>
                  </Grid>
                  <Grid
                    item
                    justifyContent="center"
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    style={{ padding: "1rem" }}
                  >
                    <>
                      <img src={service_now13} align="left"></img>
                      <br />
                      <br />
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        Seamless Integration{" "}
                      </Typography>
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontSize: "0.8rem",
                        }}
                      >
                        With Cyntra, you can integrate ServiceNow effortlessly
                        with your existing systems, ensuring smooth data flow
                        and enhanced functionality.
                      </Typography>{" "}
                    </>
                  </Grid>
                  <Grid
                    item
                    justifyContent="center"
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    style={{ padding: "1rem" }}
                  >
                    <>
                      <img src={service_now12} align="left"></img>
                      <br />
                      <br />
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "1rem",
                        }}
                      >
                        Continuous Support{" "}
                      </Typography>
                      <Typography
                        align="left"
                        style={{
                          paddingBottom: "2rem",
                          color: "black",
                          fontSize: "0.8rem",
                        }}
                      >
                        Our commitment doesn't end post-implementation. We offer
                        ongoing support, training, and updates to ensure your
                        ServiceNow platform remains cutting-edge.
                      </Typography>{" "}
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>
        </Grid>
      </>
    </div>
  );
}
